"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.regexp.constructor.js");

require("core-js/modules/es.regexp.dot-all.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.sticky.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.replace.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* 
 该输入框不可使用科学技术法计算
 value：输入框值
 placeholder：占位符
 maxlength：最大长度
 decimals：小数位，为 0 或 不穿则表示无小数
 */
var _default = {
  props: ["value", "max", "maxlength", "decimals", "placeholder", "disabled", "autocomplete", "prefix", "frontSuffix", "suffix"],
  data: function data() {
    return {};
  },
  methods: {
    onFocus: function onFocus() {
      this.$refs.input.focus();
    },
    decimalLimit: function decimalLimit(val) {
      // RegExp
      var regDicimal = new RegExp("^(\\-)*(\\d+)\\.(\\d{" + this.decimals + "}).*$");
      val = val.replace(/。/g, ".");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = this.decimals ? val.replace(/[^\d.]/g, "") : val.replace(/[^\d]/g, ""); // //如果第一位是0，第二位不是点，就用数字把点替换掉
      // var len1 = val.substr(0, 1);
      // var len2 = val.substr(1, 1);
      // if (val.length > 1 && len1 == 0 && len2 != ".") {
      //   val = parseFloat(val);
      // }

      val = val.replace(regDicimal, "$1$2.$3");
      this.$emit("input", val);
    }
  }
};
exports.default = _default;