"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _aboutus8v = _interopRequireDefault(require("./aboutus-8v.vue"));

var _stationSetting = require("@/assets/js/stationSetting");

//
//
//
//
//
//
//
//
var _default = {
  components: {
    Aboutus8v: _aboutus8v.default
  },
  data: function data() {
    return {
      VUE_APP_ID: _stationSetting.VUE_APP_ID,
      is8v: false
    };
  },
  created: function created() {
    if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.is8v = true;
    }
  }
};
exports.default = _default;