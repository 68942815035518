"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.json.stringify.js");

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

var _message = require("@/components/dialog/message");

var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["data", "assetsList"],
  created: function created() {},
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    language: "user/language"
  })),
  methods: {
    routeSplicing: _routeSplicing.default,
    // 充币
    rechargeClick: function rechargeClick() {
      if (this.data.itemDetail.in_status === 0) {
        var coinName = this.data.itemDetail.name.toUpperCase(); // showMessage({ message: coinName+'暂不支持充币，'+coinName+'的资产管理请在“兑换中心”中操作' });

        (0, _message.showMessage)({
          message: coinName + "暂不支持充币"
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(this.data));
        localStorage.setItem("currentCoin", JSON.stringify(this.data));
        this.$router.push({
          path: this.routeSplicing(this.language, "ucenter/assets/recharge/" + this.data.itemDetail.name)
        });
      }
    },
    // 提币
    withdrawalClick: function withdrawalClick() {
      if (this.data.itemDetail.out_status === 0) {
        var coinName = this.data.itemDetail.name.toUpperCase(); // showMessage({ message: coinName+'暂不支持提币，'+coinName+'的资产管理请在“兑换中心”中操作' });

        (0, _message.showMessage)({
          message: coinName + "暂不支持提币"
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(this.data));
        localStorage.setItem("currentCoin", JSON.stringify(this.data));
        this.$router.push({
          path: this.routeSplicing(this.language, "ucenter/assets/withdrawal/" + this.data.itemDetail.name)
        });
      }
    },
    getClassName: function getClassName(val) {
      if (val == 0) {
        return "ban";
      }

      return "";
    }
  }
};
exports.default = _default;