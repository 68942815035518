"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

var _stationSetting = require("@/assets/js/stationSetting");

//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: {
    text: {
      type: String,
      default: "暂无数据"
    }
  },
  data: function data() {
    return {
      noData: ""
    };
  },
  created: function created() {
    if (_stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.noData = require("../../assets/img/home/bijiu-no-data.png");
    } else if (_stationSetting.VUE_UNION_STATION == "EverTrust") {
      this.noData = require("../../assets/img/home/bijiu-no-data.png");
    } else if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.noData = require("../../assets/img/home/mask-no-data.png");
    } else if (_stationSetting.VUE_UNION_STATION == "100Ex") {
      this.noData = require("../../assets/img/home/no-data-100.png");
    } else if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.noData = require("../../assets/img/home/no-data-8v.png");
    } else if (_stationSetting.VUE_UNION_STATION == "UEEx") {
      this.noData = require("../../assets/img/home/no-data-UEEx.png");
    } else if (_stationSetting.VUE_UNION_STATION == "Ahex") {
      this.noData = require("../../assets/img/home/no-data-Ahex.png");
    } else if (_stationSetting.VUE_UNION_STATION == "5A") {
      this.noData = require("../../assets/img/home/no-data-5A.png");
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.noData = require("../../assets/img/home/no-data-bitda.png");
    } else if (_stationSetting.VUE_UNION_STATION == "S6") {
      this.noData = require("../../assets/img/home/no-data-S6.png");
    } else if (_stationSetting.VUE_UNION_STATION == "easy") {
      this.noData = require("../../assets/img/home/no-data-easy.png");
    } else if (_stationSetting.VUE_UNION_STATION == "spex") {
      this.noData = require("../../assets/img/home/no-data-spex.png");
    } else if (_stationSetting.VUE_UNION_STATION == "8coin") {
      this.noData = require("../../assets/img/home/no-data-8coin.png");
    } else {
      this.noData = require("../../assets/img/home/no_data.png");
    }
  }
};
exports.default = _default;