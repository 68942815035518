"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.array.slice.js");

// global.js 文件
var _default = {
  //去除小数位后多余0
  handleCutZero: function handleCutZero(num) {
    //拷贝一份 返回去掉零的新串
    if (!num) return 0;
    var newstr = num; //循环变量 小数部分长度
    // console.log(num.indexOf('.') - 1)

    var leng = num.length - num.indexOf(".") - 1; //判断是否有效数

    if (num.indexOf(".") > -1) {
      //循环小数部分
      for (var i = leng; i > 0; i--) {
        //如果newstr末尾有0
        if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
          var k = newstr.lastIndexOf("0"); //如果小数点后只有一个0 去掉小数点

          if (newstr.charAt(k - 1) == ".") {
            return newstr.substring(0, k - 1);
          } else {
            //否则 去掉一个0
            newstr = newstr.substring(0, k);
          }
        } else {
          //如果末尾没有0
          return newstr;
        }
      }
    }

    return num;
  },
  money_format: function money_format(money) {
    if (money !== undefined) {
      var arr = money.toString().split(".");
      return (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + (arr[1] ? "." + arr[1] : "");
    } else {
      return money;
    }
  },

  /* 保留小数精度 (单纯截取，不向上向下取整) */
  accuracy: function accuracy(num, digit) {
    num = num.toString();

    if (num.indexOf(".") === -1) {
      if (digit !== 0) {
        num = num + ".";
        num = loopAddZero(num, digit);
      }
    } else {
      var digitLength = num.split(".")[1].length;

      if (digitLength < digit) {
        num = loopAddZero(num, digit - digitLength);
      } else {
        if (digit === 0) {
          num = num.slice(0, num.indexOf(".") + digit);
        } else {
          num = num.slice(0, num.indexOf(".") + digit + 1);
        }
      }
    }

    return num;
  },
  //对手机号 邮箱 用户名 进行脱敏
  userDecryption: function userDecryption(account, type) {
    if (!account) {
      return;
    }

    var accountTemp = account.toString();
    if (!accountTemp) return "";
    var accountNew = "";

    if (type === "name") {
      accountNew = accountTemp.substring(0, 2) + "****" + accountTemp.substring(accountTemp.length - 2); //aa****bb
    }

    if (type === "email") {
      var str = accountTemp.split("@");
      accountNew = // str[0].substr(0, str[0].length - 2) + "*****" + str[1].substr(2); // 73856000*****.com
      str[0].substr(0, 2) + "****" + str[1].substr(0); // 73856000*****.com
    }

    if (type === "mobile") {
      if (accountTemp.length == 11) {
        accountNew = accountTemp.replace(/(\d{3})\d*(\d{4})/, "$1****$2"); // 130****5689
      } else {
        accountNew = accountTemp.substring(0, 2) + "****" + accountTemp.substring(accountTemp.length - 2); // 22****33
      }
    }

    return accountNew;
  }
}; // 补零

exports.default = _default;

var loopAddZero = function loopAddZero(num, frequency) {
  var zero = "";

  for (var i = 0; i < frequency; i++) {
    zero = zero + "0";
  }

  num = num + zero;
  return num;
};