import { apiGetUserInfo, apiGetLoginOut } from "@/model/user/index";
import { isJson } from "@/assets/js/validate";
import Cookies from "js-cookie";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";

const getLocalStorage = key => {
  const value = localStorage.getItem(key);
  if (isJson(value)) {
    return JSON.parse(value);
  } else {
    return false;
  }
};
const getSessionStorage = key => {
  const value = sessionStorage.getItem(key);
  if (isJson(value)) {
    return JSON.parse(value);
  } else {
    return null;
  }
};
const language = localStorage.getItem("language");
const { currency } = getLocalStorage("currency");
const { environment } = getLocalStorage("environment");
const darkMode = localStorage.getItem("darkMode");
const sessionUserInfo = getSessionStorage("userInfo");
const state = {
  userInfo: sessionUserInfo,
  language:
    language ||
    (["MaskEx", "8V", "S6", "B9", "5A", "bitda", "TopCredit"].includes(
      VUE_UNION_STATION
    )
      ? "en"
      : "zh_CN"),
  darkMode: darkMode || 1,
  currency:
    currency || (["TopCredit"].includes(VUE_UNION_STATION) ? "usd" : "cny"),
  environment: environment,
  v8show: false,
};
const mutations = {
  setUserInfo(state, { data }) {
    state.userInfo = data;
    if (data != null) {
      sessionStorage.setItem("userInfo", JSON.stringify(data));
    }
  },
  setv8show(state, { data }) {
    state.v8show = data;
  },
  changeLanguage(state, language) {
    state.language = language;
    localStorage.setItem("language", language);
  },
  changeDarkMode(state, mode) {
    state.darkMode = mode;
    localStorage.setItem("darkMode", mode);
  },
  changeCurrency(state, currency) {
    state.currency = currency;
    localStorage.setItem("currency", currency);
  },
  changeEnvironment(state, environment) {
    state.environment = environment;
    localStorage.setItem("environment", environment);
  },
};
const actions = {
  getUserInfo({ commit }, preload) {
    return new Promise(async (resolve, reject) => {
      const { status, data } = await apiGetUserInfo(preload);
      if (status == 200) {
        commit("setUserInfo", { data: data });
        resolve(data);
      } else {
        Cookies.remove("topcredit_exchange_t");
        reject(data);
      }
    });
  },

  changeLanguage: ({ commit }, language) => {
    commit("changeLanguage", language);
  },
  changeDarkMode: ({ commit }, mode) => {
    commit("changeDarkMode", mode);
  },
  changeCurrency: ({ commit }, currency) => {
    commit("changeCurrency", currency);
  },
  changeEnvironment: ({ commit }, environment) => {
    commit("changeEnvironment", environment);
  },

  // 退出，清除用户信息
  async logout({ commit }) {
    // Account.logOut();
    // 清除本地存储，localStorage,sessionStorage
    // 清除state用户信息
    Cookies.remove("topcredit_exchange_t");
    sessionStorage.clear();
    localStorage.removeItem("userData");
    commit("setUserInfo", { data: null });
    window.location = "/";
  },
  v8show({ commit }) {
    commit("setv8show", { data: true });
  },
  v8closed({ commit }) {
    commit("setv8show", { data: false });
  },
};
const getters = {
  language: state => state.language,
  darkMode: state => state.darkMode,
  userInfo: state => state.userInfo,
  v8show: state => state.v8show,
  currency: state => state.currency,
  environment: state => state.environment,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
