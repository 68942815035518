import Vue from "vue";
import ElementUI from "element-ui";
import store from "@/store";
import VueI18n from "vue-i18n";
// import en from "./en.json";
// import zh from "./zh.json";
// import ja from "./ja.json";
// import ko from "./ko.json";
// import hk from "./hk.json";
import elen from "element-ui/lib/locale/lang/en";
import elja from "element-ui/lib/locale/lang/ja";
import elko from "element-ui/lib/locale/lang/ko";
import elzh_HK from "element-ui/lib/locale/lang/zh-TW";
import elzh_CN from "element-ui/lib/locale/lang/zh-CN";
import locale from "element-ui/lib/locale";
import { getLangPackage } from "@/assets/js/lang";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";

const elLanguage = {
  elen: elen,
  elja: elja,
  elko: elko,
  elzh_HK: elzh_HK,
  elzh_CN: elzh_CN,
};

Vue.use(VueI18n);

let messages = {};

if (["MaskEx", "8V", "S6", "B9", "TopCredit"].includes(VUE_UNION_STATION)) {
  const { en } = getLangPackage(["en"]);
  messages = {
    en: {
      ...en,
    },
  };
  // store.dispatch("user/changeLanguage", "en");
  // localStorage.getItem("language", "en");
}
if (["bitda", "5A"].includes(VUE_UNION_STATION)) {
  const { en, zh_HK } = getLangPackage(["en", "zh_HK"]);
  messages = {
    en: {
      ...en,
    },
    zh_HK: {
      ...zh_HK,
    },
  };
  // store.dispatch("user/changeLanguage", "en");
  // localStorage.getItem("language", "en");
}
// const { zh_CN, zh_HK, en, ko, ja } = getLangPackage([
//   "zh_CN",
//   "zh_HK",
//   "en",
//   "ko",
//   "ja",
// ]);

// const messages = {
//   en: {
//     ...en,
//     // ...elEn,
//   },
//   zh_CN: {
//     ...zh_CN,
//     // ...elZh,
//   },
//   ja: {
//     ...ja,
//   },
//   ko: {
//     ...ko,
//   },
//   zh_HK: {
//     ...zh_HK,
//   },
// };

const en = localStorage.getItem("lang_en");
const zh_CN = localStorage.getItem("lang_zh_CN");
const ja = localStorage.getItem("lang_ja");
const ko = localStorage.getItem("lang_ko");
const zh_HK = localStorage.getItem("lang_zh_HK");

const vi = localStorage.getItem("lang_vi");
const fr = localStorage.getItem("lang_fr");
const es = localStorage.getItem("lang_es");
const ru = localStorage.getItem("lang_ru");
const ar = localStorage.getItem("lang_ar");
const de = localStorage.getItem("lang_de");
const pt = localStorage.getItem("lang_pt");
const hi = localStorage.getItem("lang_hi");
const tl = localStorage.getItem("lang_tl");
const th = localStorage.getItem("lang_th");
const tr = localStorage.getItem("lang_tr");
const it = localStorage.getItem("lang_it");
const id = localStorage.getItem("lang_id");

if (en) {
  messages.en = { ...JSON.parse(en).en, ...elen };
}
if (zh_CN) {
  messages.zh_CN = { ...JSON.parse(zh_CN).zh_CN, ...elzh_CN };
} else {
  messages.zh_CN = { ...elzh_CN };
}
if (ja) {
  messages.ja = { ...JSON.parse(ja).ja, ...elja };
}
if (ko) {
  messages.ko = { ...JSON.parse(ko).ko, ...elko };
}
if (zh_HK) {
  messages.zh_HK = { ...JSON.parse(zh_HK).zh_HK, ...elzh_HK };
}

if (vi) {
  messages.vi = { ...JSON.parse(vi).vi, ...elen };
}
if (fr) {
  messages.fr = { ...JSON.parse(fr).fr, ...elen };
}
if (es) {
  messages.es = { ...JSON.parse(es).es, ...elen };
}
if (ru) {
  messages.ru = { ...JSON.parse(ru).ru, ...elen };
}
if (ar) {
  messages.ar = { ...JSON.parse(ar).ar, ...elen };
}
if (de) {
  messages.de = { ...JSON.parse(de).de, ...elen };
}
if (pt) {
  messages.pt = { ...JSON.parse(pt).pt, ...elen };
}
if (hi) {
  messages.hi = { ...JSON.parse(hi).hi, ...elen };
}
if (tl) {
  messages.tl = { ...JSON.parse(tl).tl, ...elen };
}
if (th) {
  messages.th = { ...JSON.parse(th).th, ...elen };
}
if (tr) {
  messages.tr = { ...JSON.parse(tr).tr, ...elen };
}
if (it) {
  messages.it = { ...JSON.parse(it).it, ...elen };
}
if (id) {
  messages.id = { ...JSON.parse(id).id, ...elen };
}
export function getLanguage() {
  let tempLang = "en";
  let tempLangV2 = localStorage.getItem("language");
  // ? localStorage.getItem("language")
  // : ["MaskEx", "8V"].includes(VUE_UNION_STATION)
  // ? "en"
  // : "zh_CN";

  if (tempLangV2) {
    tempLang = tempLangV2;
    store.dispatch("user/changeLanguage", tempLang);
  } else {
    if (["MaskEx", "8V"].includes(VUE_UNION_STATION)) {
      tempLang = "en";
      store.dispatch("user/changeLanguage", tempLang);
    } else if (
      VUE_UNION_STATION == "S6" ||
      VUE_UNION_STATION == "B9" ||
      VUE_UNION_STATION == "5A" ||
      VUE_UNION_STATION == "bitda"
    ) {
      var jsSrc = (
        navigator.language || navigator.browserLanguage
      ).toLowerCase();
      if (jsSrc == "zh-hk" || jsSrc == "zh-tw") {
        tempLang = "zh_HK";
        store.dispatch("user/changeLanguage", tempLang);
      } else if (jsSrc.indexOf("zh") >= 0) {
        // 假如浏览器语言是中文
        tempLang = "zh_CN";
        store.dispatch("user/changeLanguage", tempLang);
      } else {
        // 假如浏览器语言是英文
        tempLang = "en";
        store.dispatch("user/changeLanguage", "en");
      }
    } else {
      tempLang = "zh_CN";
    }
  }

  return tempLang;
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
