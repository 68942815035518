"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _stationSetting = require("@/assets/js/stationSetting");

var _i18n = require("@/assets/js/i18n");

var _kycSettingInner = _interopRequireDefault(require("@/pages/ucenter/kyc-setting-inner.vue"));

var _securitySettingInnerBv = _interopRequireDefault(require("@/pages/ucenter/security-setting-inner-bv.vue"));

//
//
//
//
//
//
//
var _default = {
  mixins: [],
  data: function data() {
    return {
      is8V: false,
      defaultPage: false
    };
  },
  components: {
    SecuritySettingInner: _kycSettingInner.default,
    SecuritySettingInnerBv: _securitySettingInnerBv.default
  },
  computed: {},
  created: function created() {
    if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy: function beforeDestroy() {},
  destroyed: function destroyed() {},
  mounted: function mounted() {},
  methods: {
    translateTitle: _i18n.translateTitle
  }
};
exports.default = _default;