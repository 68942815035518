"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGoogleLogin = apiGoogleLogin;
exports.apiLogin = apiLogin;
exports.apiSafelyLost = apiSafelyLost;
exports.universalAccountV2Login = universalAccountV2Login;
exports.universalAccountV2LoginGeet = universalAccountV2LoginGeet;
exports.universalLogin = universalLogin;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_APP } = process.env;
function apiLogin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Account/appUniversalLogin"),
    data: data
  });
}

function apiGoogleLogin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/LoginVerify/googleVerifyLogin"),
    data: data
  });
}

function universalLogin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Account/universalLogin"),
    data: data
  });
} //登录不需要谷歌码


function universalAccountV2Login() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/AccountV2/universalLogin"),
    data: data
  });
} //登录不需要谷歌码


function universalAccountV2LoginGeet() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/Login/universal"),
    data: data
  });
} //用户安全遗失提交工单


function apiSafelyLost() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/LoginVerify/safelyLost"),
    data: data
  });
}