<template>
  <div></div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { apiGetStaticTableInfo } from "@/model/settings";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  data() {
    return {
      seoList: [
        {
          id: "English",
          value: "en",
          valueFull: "en_US",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Buy, Trade and Hold Hundreds of Cryptocurrencies - Topcredit International",
              meta_name: {
                description:
                  "Topcredit International is a one-stop digital asset management platform for investing in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) and other cryptocurrencies",
                keywords:
                  "Topcredit bank,Topcredit international,Topcredit,metaverse,virtual currency,cryptocurrency,crypto asset,blockchain,crypto,digital currency,crypto derivatives,crypto derivatives exchange,exchange,digital currency trading platform,cryptocurrency exchange,coinan,binance,coinbase,exchange,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit International | Buy, Trade and Hold Hundreds of Cryptocurrencies - Topcredit International",
                "twitter:description":
                  "Topcredit International is a one-stop digital asset management platform for investing in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) and other cryptocurrencies",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Buy, Trade and Hold Hundreds of Cryptocurrencies - Topcredit International",
                "og:title":
                  "Topcredit International | Buy, Trade and Hold Hundreds of Cryptocurrencies - Topcredit International",
                "og:description":
                  "Topcredit International is a one-stop digital asset management platform for investing in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) and other cryptocurrencies",
                "og:image:alt":
                  "Topcredit International | Buy, Trade and Hold Hundreds of Cryptocurrencies - Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit Metaverse | Metaverse Crypto Market Tracker",
              meta_name: {
                description:
                  "Enter the Metaverse Zone | Metaverse Crypto Market Tracker | Stay Up to Date With the Latest Metaverse Digital Currency News and Prices",
                keywords:
                  "Topcredit Exchange, Metaverse Page, Metaverse Market, Metaverse Exchange",
                "twitter:title":
                  "Topcredit Metaverse Zone : Metaverse Crypto Tracker",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Topcredit Metaverse Zone : Metaverse Crypto Tracker",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            finances: {
              title: "Topcredit Earn | Earn Crypto with High APY% Staking",
              meta_name: {
                description:
                  "Stake BTC ETH DOGE USDT & Many More Digital Currencies | Up to 22.5% Annual Yield | Staking DeFi and Cloud Mining Available",
                keywords:
                  "Topcredit Earn, Crypto Staking, Metaverse Crypto Exchange, Digital Currency Staking",
                "twitter:title":
                  "Topcredit Earn : High APY% Staking on Topcredit Exchange",
                "twitter:description":
                  "Stake USDT Bitcoin and ETH on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Topcredit Earn : High APY% Staking on Topcredit Exchange",
                "og:description":
                  "Stake USDT Bitcoin and ETH on the crypto exchange built for the Metaverse",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | About Us",
              meta_name: {
                description:
                  "Ever since Bitcoin was mined for the very first time, crypto has been changing the world - and Topcredit is leading the race. Dedicated to defining the metaverse, our ever-growing selection of digital tokens and financial products must complete a rigorous review process so our users can invest with peace of mind",
                keywords:
                  "Topcredit Exchange, about us page, Metaverse Cryptocurrency Exchange, Digital Currency Exchange",
                "twitter:title": "Topcredit Exchange : About Us",
                "twitter:description":
                  "Topcredit is leading the race to defining the metaverse, we offer an ever-growing selection of digital tokens and financial products so our users can invest with peace of mind",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title": "Topcredit Exchange : About Us",
                "og:description":
                  "Topcredit is leading the race to defining the metaverse, we offer an ever-growing selection of digital tokens and financial products so our users can invest with peace of mind",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | Service Agreement",
              meta_name: {
                description: "Terms and Conditions",
                keywords:
                  "Topcredit Exchange, service agreement, terms and conditions, Digital Currency Exchange",
                "twitter:title": "Topcredit Exchange : Service Agreement",
                "twitter:description": "Terms and Conditions",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title": "Topcredit Exchange : Service Agreement",
                "og:description": "Terms and Conditions",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            register: {
              title: "Topcredit Exchange | Sign Up",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & over 200 more Digital Currencies | Spot - Margin 300X - OTC - Derivatives - Staking | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "Topcredit Exchange, register, Sign up, enter email address",
                "twitter:title": "Topcredit Exchange : Sign Up",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title": "Topcredit Exchange : Sign Up",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            login: {
              title: "Topcredit Exchange | Log In",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & over 200 more Digital Currencies | Spot - Margin 300X - OTC - Derivatives - Staking | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "Topcredit Exchange, Log in, enter email, enter password",
                "twitter:title": "Topcredit Exchange : Log In",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title": "Topcredit Exchange : Log In",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | Reset Log In Password",
              meta_name: {
                description:
                  "After Resetting the Log In Password, Withdrawals are Prohibited for 24 Hours",
                keywords:
                  "Topcredit Exchange, Reset Password, Forgot Password, Digital Currency Exchange",
                "twitter:title": "Topcredit Exchange : Reset Log In Password",
                "twitter:description":
                  "After Resetting the Log In Password, Withdrawals are Prohibited for 24 Hours",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title": "Topcredit Exchange : Reset Log In Password",
                "og:description":
                  "After Resetting the Log In Password, Withdrawals are Prohibited for 24 Hours",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | Privacy Policy",
              meta_name: {
                description: "Topcredit Exchange Privacy Policy",
                keywords:
                  "Topcredit Exchange, Privacy policy, information security, Digital Currency Exchange",
                "twitter:title": "Topcredit Exchange : Privacy Policy",
                "twitter:description":
                  "Information Security and Privacy Notice",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title": "Topcredit Exchange : Privacy Policy",
                "og:description": "Information Security and Privacy Notice",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            //coinFullName coinName（大写） 需替换
            "contract-exchange": {
              title:
                "Trade coinFullName Futures on Topcredit Exchange | coinName/USDT Perpetual Futures",
              meta_name: {
                description:
                  "Trade coinName USDT Futures up to 200X Margin | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "Topcredit Exchange, coinFullName USDT Futures, Crypto Futures, coinName Perpetual Futures",
                "twitter:title":
                  "Trade coinFullName Futures on Topcredit Exchange : coinName/USDT Perpetual Futures",
                "twitter:description":
                  "Trade coinName USDT Futures up to 200X Margin : Join the Crypto Exchange with a Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Trade coinFullName Futures on Topcredit Exchange : coinName/USDT Perpetual Futures",
                "og:description":
                  "Trade coinName USDT Futures up to 200X Margin : Join the Crypto Exchange with a Metaverse Zone",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            //coinFullName coinName（大写） 需替换
            delivery: {
              title:
                "Trade coinFullName Futures on Topcredit Exchange | coinName Margined Futures",
              meta_name: {
                description:
                  "Trade coinName Margined Futures up to 100X Margin | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "Topcredit Exchange, coinName  USDT Futures, Crypto Futures,  coinFullName Perpetual Futures",
                "twitter:title":
                  "Trade coinFullName Futures on Topcredit Exchange : coinName Margined Futures",
                "twitter:description":
                  "Trade coinName Margined Futures up to 100X Margin | Join the Crypto Exchange with a Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Trade coinFullName Futures on Topcredit Exchange : coinName Margined Futures",
                "og:description":
                  "Trade coinName Margined Futures up to 100X Margin | Join the Crypto Exchange with a Metaverse Zone",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
            //coinFullName coinName （大写） 需替换
            exchange: {
              title:
                "Trade coinFullName on Topcredit Exchange | coinName Spot Market Chart",
              meta_name: {
                description:
                  "Buy coinName In Seconds | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "Topcredit Exchange, coinFullName spot, coinName chart, digital currency price",
                "twitter:title":
                  "Trade coinFullName on Topcredit Exchange | coinName Spot Market Chart",
                "twitter:description":
                  "Buy coinName In Seconds | Join the Crypto Exchange with a Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Trade coinFullName on Topcredit Exchange | coinName Spot Market Chart",
                "og:description":
                  "Buy coinName In Seconds | Join the Crypto Exchange with a Metaverse Zone",
                "og:image:alt":
                  "Topcredit Exchange : Gateway to The Crypto Metaverse",
              },
            },
          },
        },
        {
          name: "日本語",
          value: "ja",
          valueFull: "ja_JP",
          seoMsg: {
            index: {
              title:
                "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              meta_name: {
                description:
                  "フュージョン・インターナショナルは、ビットコイン（BTC）、イーサリアム（ETH）、USDT、柴犬（SHIB）、その他の暗号通貨に投資するためのワンストップ デジタル資産管理プラットフォームです。",
                keywords:
                  "送金銀行,送金インターナショナル,トップクレジット,メタバース,仮想通貨,暗号通貨,暗号資産,ブロックチェーン,クリプト,デジタル通貨,暗号デリバティブ,暗号デリバティブ交換,交換,デジタル通貨取引プラットフォーム,暗号通貨交換,コイアン、バイナンス、コインベース、交換、バイビット． Kraken、KuCoin、OKX、Gate.io、Bitget、Pionex、Bingx、MEXC",
                "twitter:title":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "twitter:description":
                  "フュージョン・インターナショナルは、ビットコイン（BTC）、イーサリアム（ETH）、USDT、柴犬（SHIB）、その他の暗号通貨に投資するためのワンストップ デジタル資産管理プラットフォームです。",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:description":
                  "フュージョン・インターナショナルは、ビットコイン（BTC）、イーサリアム（ETH）、USDT、柴犬（SHIB）、その他の暗号通貨に投資するためのワンストップ デジタル資産管理プラットフォームです。",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            metaverse: {
              title:
                "Topcredit Metaverse | メタバース暗号資産マーケット・トラッカー",
              meta_name: {
                description:
                  "メタバースゾーンへようこそ | メタバース暗号資産マーケット・トラッカー | メタバースデジタル通貨の最新ニュースと価格を常に把握しよう",
                keywords:
                  "Topcredit Exchange, メタバースページ, メタバース市場, メタバース交換所",
                "twitter:title":
                  "Topcredit Metaverse ゾーン：メタバース暗号資産トラッカー",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title":
                  "Topcredit Metaverse ゾーン：メタバース暗号資産トラッカー",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | 高年間収益率のステーキングで暗号資産を稼ごう",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他多くのデジタル通貨を保有しよう | 最大22.5%の年間利回り | ステーキングDeFiとクラウドマイニングが利用可能",
                keywords:
                  "Topcredit Earn, 暗号資産ステーキング, メタバース暗号資産取引所,  デジタル通貨ステーキング",
                "twitter:title":
                  "Topcredit Earn：Topcredit Exchangeでの高年間収益率のステーキング",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所でUSDT BitcoinやETHを保有しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title":
                  "Topcredit Earn：Topcredit Exchangeでの高年間収益率のステーキング",
                "og:description":
                  "メタバース用に構築された暗号資産交換所でUSDT BitcoinやETHを保有しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | 会社概要",
              meta_name: {
                description:
                  "Topcreditはメタバースを定義する競争をリードしています。当社は、お客様が安心して投資できるように、増え続けるデジタルトークンと金融商品の選択肢を提供します",
                keywords:
                  "Topcredit Exchange, 会社概要ページ, メタバース暗号通貨取引所, デジタル通貨交換所",
                "twitter:title": "Topcredit Exchange : 会社概要",
                "twitter:description":
                  "Topcreditはメタバースを定義する競争をリードしています。当社は、お客様が安心して投資できるように、増え続けるデジタルトークンと金融商品の選択肢を提供します",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title": "Topcredit Exchange : 会社概要",
                "og:description":
                  "Topcreditはメタバースを定義する競争をリードしています。当社は、お客様が安心して投資できるように、増え続けるデジタルトークンと金融商品の選択肢を提供します",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | サービス契約書",
              meta_name: {
                description: "利用規約",
                keywords:
                  "Topcredit Exchange, サービス契約書, 利用規約, デジタル通貨交換所",
                "twitter:title": "Topcredit Exchange : サービス契約書",
                "twitter:description": "利用規約",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title": "Topcredit Exchange : サービス契約書",
                "og:description": "利用規約",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | サインアップ",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他200種類以上のデジタル通貨の取引が可能 | スポット - 証拠金 300倍 - 店頭市場 - デリバティブ - ステーキング | メタバースゾーンで暗号資産交換所に参加しよう",
                keywords:
                  "Topcredit Exchange, 登録, サインアップ, Eメールアドレスの入力",
                "twitter:title": "Topcredit Exchange : サインアップ",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title": "Topcredit Exchange : サインアップ",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | ログイン",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他200種類以上のデジタル通貨の取引が可能 | スポット - 証拠金 300倍 - 店頭市場 - デリバティブ - ステーキング | メタバースゾーンで暗号資産交換所に参加しよう",
                keywords:
                  "Topcredit Exchange, ログイン, Eメールを入力, パスワードを入力",
                "twitter:title": "Topcredit Exchange : ログイン",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title": "Topcredit Exchange : ログイン",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | ログインパスワードの再設定",
              meta_name: {
                description:
                  "ログインパスワードの再設定後、24時間は引き出し不可となります",
                keywords:
                  "Topcredit Exchange, パスワードの再設定, パスワードを忘れた場合, デジタル通貨交換所",
                "twitter:title":
                  "Topcredit Exchange : ログインパスワードの再設定",
                "twitter:description":
                  "ログインパスワードの再設定後、24時間は引き出し不可となります",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title": "Topcredit Exchange : ログインパスワードの再設定",
                "og:description":
                  "ログインパスワードの再設定後、24時間は引き出し不可となります",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | プライバリーポリシー",
              meta_name: {
                description: "Topcredit Exchange プライバリーポリシー",
                keywords:
                  "Topcredit Exchange, プライバリーポリシー 情報セキュリティ, デジタル通貨交換所",
                "twitter:title": "Topcredit Exchange : プライバリーポリシー",
                "twitter:description":
                  "情報セキュリティと個人情報保護に関するお知らせ",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title": "Topcredit Exchange : プライバリーポリシー",
                "og:description":
                  "情報セキュリティと個人情報保護に関するお知らせ",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Topcredit ExchangeでcoinFullNameン先物を取引しよう | coinName/USDT パーペチュアル先物取引",
              meta_name: {
                description:
                  "coinName USDT先物を最大200倍の証拠金で取引しよう | メタバースゾーンのある暗号資産取引所に参加しよう",
                keywords:
                  "Topcredit Exchange, coinFullNameンUSDT先物, 暗号先物, coinNameパーペチュアル先物",
                "twitter:title":
                  "Topcredit ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT パーペチュアル先物取引",
                "twitter:description":
                  "coinName USDT先物を最大200倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title":
                  "Topcredit ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT パーペチュアル先物取引",
                "og:description":
                  "coinName USDT先物を最大200倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            delivery: {
              title:
                "Topcredit ExchangeでcoinFullNameン先物を取引しよう | coinName/USDT証拠金先物",
              meta_name: {
                description:
                  "coinName証拠金先物を最大100倍の証拠金で取引しよう | メタバースゾーンのある暗号資産取引所に参加しよう",
                keywords:
                  "Topcredit Exchange, coinName USDT先物, coinFullNameン先物, パーペチュアル先物",
                "twitter:title":
                  "Topcredit ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT証拠金先物",
                "twitter:description":
                  "coinName証拠金先物を最大100倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title":
                  "Topcredit ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT証拠金先物",
                "og:description":
                  "coinName証拠金先物を最大100倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
            exchange: {
              title:
                "Topcredit ExchangeでcoinFullNameンを取引しよう | coinName スポット市場チャート",
              meta_name: {
                description:
                  "coinNameをすぐ買おう | メタバースゾーンのある暗号資産取引所に参加しよう",
                keywords:
                  "Topcredit Exchange, coinFullNameンスポット物, coinNameチャート, デジタル通貨相場",
                "twitter:title":
                  "Topcredit ExchangeでcoinFullNameンを取引しよう : coinName スポット市場チャート",
                "twitter:description":
                  "coinNameをすぐ買おう : メタバースゾーンのある暗号資産取引所に参加しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
                "og:title":
                  "Topcredit ExchangeでcoinFullNameンを取引しよう : coinName スポット市場チャート",
                "og:description":
                  "coinNameをすぐ買おう : メタバースゾーンのある暗号資産取引所に参加しよう",
                "og:image:alt":
                  "フュージョンバンク・インターナショナル｜数百種類の暗号通貨を購入、取引、保有できる - Topcredit International",
              },
            },
          },
        },
        {
          name: "한국인",
          value: "ko",
          valueFull: "ko_KR",
          seoMsg: {
            index: {
              title:
                "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              meta_name: {
                description:
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
                keywords:
                  "송금 은행, 송금 국제, 탑 크레딧, 메타 버스, 가상 화폐, 암호 화폐, 암호 화폐, 암호화 자산, 블록 체인, 암호화, 디지털 통화, 암호화 파생 상품, 암호화 파생 상품 교환, 교환, 디지털 통화 거래 플랫폼, 암호 화폐 거래소, 코인 난, 바이 낸스, 코인베이스, 교환, 바이 비트. 크라켄, 쿠코인, 오케이엑스, 게이트아이오, 비트겟, 파이오넥스, 빙엑스, 멕스씨",
                "twitter:title":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "twitter:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit Metaverse | 메타버스 암호화폐 마켓 트랙커",
              meta_name: {
                description:
                  "메타버스 존에 입장하세요 | 최신 메타버스 디지털 통화 뉴스 및 가격을 확인해보세요",
                keywords:
                  "Topcredit Exchange, 메타버스 페이지, 메타버스 마켓, 메타버스 거래소",
                "twitter:title":
                  "Topcredit 메타버스 존 : 메타버스 암호화폐 트랙커",
                "twitter:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit 메타버스 존 : 메타버스 암호화폐 트랙커",
                "og:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            finances: {
              title: "Topcredit Earn | 높은 APY% 스테이킹으로 암호화폐 벌기",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 더 다양한 디지털 화폐를 스테이킹하세요 | 최대 22.5% 연간 수익 | DeFi 스테이킹 및 클라우드 마이닝이 가능합니다",
                keywords:
                  "Topcredit Earn, 암호화폐 스테이킹, 메타버스 암호화폐 거래소, 디지털 화폐 스테이킹",
                "twitter:title":
                  "Topcredit Earn : Topcredit Exchange에서 높은 APY% 스테이킹",
                "twitter:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 USDT 비트코인 및 ETH를 스테이킹하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title":
                  "Topcredit Earn : Topcredit Exchange에서 높은 APY% 스테이킹",
                "og:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 USDT 비트코인 및 ETH를 스테이킹하세요",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | 회사 소개",
              meta_name: {
                description:
                  "Topcredit는 메타버스를 정의하는 경쟁을 주도하고 있으며, 사용자가 안심하고 투자할 수 있도록 계속 성장하는 디지털 토큰 및 금융 제품을 제공합니다",
                keywords:
                  "Topcredit Exchange, 회사 소개 페이지, 메타버스 암호화폐 거래소, 디지털 화폐 거래소",
                "twitter:title": "Topcredit Exchange : 회사 소개",
                "twitter:description":
                  "Topcredit는 메타버스를 정의하는 경쟁을 주도하고 있으며, 사용자가 안심하고 투자할 수 있도록 계속 성장하는 디지털 토큰 및 금융 제품을 제공합니다",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit Exchange : 회사 소개",
                "og:description":
                  "Topcredit는 메타버스를 정의하는 경쟁을 주도하고 있으며, 사용자가 안심하고 투자할 수 있도록 계속 성장하는 디지털 토큰 및 금융 제품을 제공합니다",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | 서비스 약관",
              meta_name: {
                description: "사용 약관",
                keywords:
                  "Topcredit Exchange, 서비스 약관, 사용 약관, 디지털 화폐 거래소",
                "twitter:title": "Topcredit Exchange : 서비스 약관",
                "twitter:description": "사용 약관",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit Exchange : 서비스 약관",
                "og:description": "사용 약관",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | 가입하기",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 200가지 이상의 디지털 통화 거래 | 스팟 - 마진 300X - OTC - 파생상품 - 스테이킹 | 메타버스 존이 있는 암호화폐 거래소에 가입하세요",
                keywords:
                  "Topcredit Exchange, 등록하고, 가입하고, 이메일 주소를 입력하세요",
                "twitter:title": "Topcredit Exchange : 가입하기",
                "twitter:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit Exchange : 가입하기",
                "og:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | 로그인",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 200가지 이상의 디지털 통화 거래 | 스팟 - 마진 300X - OTC - 파생상품 - 스테이킹 | 메타버스 존이 있는 암호화폐 거래소에 가입하세요",
                keywords:
                  "Topcredit Exchange, 로그인, 이메일 입력하기, 비밀번호 입력하기",
                "twitter:title": "Topcredit Exchange : 로그인",
                "twitter:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit Exchange : 로그인",
                "og:description":
                  "퓨전 인터내셔널은 비트코인(BTC), 이더리움(ETH), 테더(USDT), 시바(SHIB) 및 기타 암호화폐에 투자할 수 있는 원스톱 디지털 자산 관리 플랫폼입니다.",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | 로그인 비밀번호 리셋하기",
              meta_name: {
                description:
                  "로그인 암호를 리셋한 후 24시간 동안 인출이 금지됩니다",
                keywords:
                  "Topcredit Exchange, 비밀번호 리셋하기, 비밀번호 잊어버림, 디지털 화폐 거래소",
                "twitter:title":
                  "Topcredit Exchange : 로그인 비밀번호 리셋하기",
                "twitter:description":
                  "로그인 암호를 리셋한 후 24시간 동안 인출이 금지됩니다",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit Exchange : 로그인 비밀번호 리셋하기",
                "og:description":
                  "로그인 암호를 리셋한 후 24시간 동안 인출이 금지됩니다",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | 개인정보 처리방침",
              meta_name: {
                description: "Topcredit Exchange 개인정보 처리방침",
                keywords:
                  "Topcredit Exchange, 개인정보 처리방침, 정보 보안, 디지털 화폐 거래소",
                "twitter:title": "Topcredit Exchange : 개인정보 처리방침",
                "twitter:description": "정보 보안 및 개인정보 처리안내",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title": "Topcredit Exchange : 개인정보 처리방침",
                "og:description": "정보 보안 및 개인정보 처리안내",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Topcredit Exchange에서 coinFullName 선물 거래하기 | coinName/USDT 영구 선물",
              meta_name: {
                description:
                  "최대 200배 마진까지 coinName USDT 선물 거래 | 메타버스 존으로 암호화폐 거래소 가입하기",
                keywords:
                  "Topcredit Exchange, coinFullName USDT 선물, 암호화폐 선물, coinName 영구 선물",
                "twitter:title":
                  "Topcredit Exchange에서 coinFullName 선물 거래하기 ： coinName/USDT 영구 선물",
                "twitter:description":
                  "최대 200배 마진까지 coinName USDT 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title":
                  "Topcredit Exchange에서 coinFullName 선물 거래하기 ： coinName/USDT 영구 선물",
                "og:description":
                  "최대 200배 마진까지 coinName USDT 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            delivery: {
              title: "에서 coinFullName 선물 거래하기 | coinName 마진 선물",
              meta_name: {
                description:
                  "최대 100배 마진까지 coinName 마진 선물 거래 | 메타버스 존으로 암호화폐 거래소 가입하기",
                keywords:
                  "Topcredit Exchange, coinName USDT 선물, coinFullName 선물, 영구 선물",
                "twitter:title":
                  "에서 coinFullName 선물 거래하기 ： coinName 마진 선물",
                "twitter:description":
                  "최대 100배 마진까지 coinName 마진 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title":
                  "에서 coinFullName 선물 거래하기 ： coinName 마진 선물",
                "og:description":
                  "최대 100배 마진까지 coinName 마진 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
            exchange: {
              title: "에서 coinFullName 거래하기 | coinName 스팟 마켓 차트",
              meta_name: {
                description:
                  "몇 초 만에 coinName 구매 | 메타버스 존으로 암호화 거래소에 가입하세요",
                keywords:
                  "Topcredit Exchange, coinFullName 스팟, coinName 차트, 디지털 화폐 가격",
                "twitter:title":
                  "에서 coinFullName 거래하기 : coinName 스팟 마켓 차트",
                "twitter:description":
                  "몇 초 만에 coinName 구매 : 메타버스 존으로 암호화 거래소에 가입하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
                "og:title":
                  "에서 coinFullName 거래하기 : coinName 스팟 마켓 차트",
                "og:description":
                  "몇 초 만에 coinName 구매 : 메타버스 존으로 암호화 거래소에 가입하세요",
                "og:image:alt":
                  "퓨전 뱅크 인터내셔널 | 수백 개의 암호 화폐 구매, 거래 및 보유-Topcredit International",
              },
            },
          },
        },

        {
          name: "Français",
          value: "fr",
          valueFull: "fr_FR",
          seoMsg: {
            index: {
              title:
                "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              meta_name: {
                description:
                  "Négociez les BTC, ETH, DOGE, USDT et plus de 200 monnaies numériques | Comptant - Marge 300X - OTC - Produits dérivés - Staking | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Topcredit bank,Topcredit international,topcredit,metaverse,monnaie virtuelle,cryptocurrency,crypto asset,blockchain,crypto,digital currency,crypto derivatives,crypto derivatives exchange,exchange,digital currency trading platform,cryptocurrency exchange,coinan,binance,coinbase,exchange,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "twitter:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            metaverse: {
              title:
                "Métavers Topcredit | Outil de suivi du marché crypto du métavers",
              meta_name: {
                description:
                  "Entrez dans la zone du métavers | Outil de suivi du marché crypto du métavers | Restez au courant des actualités et des prix des monnaies numériques du métavers",
                keywords:
                  "Bourse Topcredit, Page du métavers, marché du métavers, bourse du métavers",
                "twitter:title":
                  "Zone du métavers Topcredit :  Outil de suivi du métavers crypto",
                "twitter:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Zone du métavers Topcredit :  Outil de suivi du métavers crypto",
                "og:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            finances: {
              title:
                "Gains Topcredit | Gains crypto avec du staking à pourcentage élevé de rendement annuel APY%",
              meta_name: {
                description:
                  "Stakez des BTC, ETH, DOGE, USDT et beaucoup plus de monnaies numériques | Jusqu’à 22,5 % de rendement annuel | Staking DeFi et cloud mining disponible",
                keywords:
                  "Gains Topcredit, Staking crypto, bourse crypto du métavers, staking de monnaies numériques",
                "twitter:title":
                  "Gains Topcredit :  Pourcentage élevé de staking à rendement annuel APY% sur la bourse Topcredit",
                "twitter:description":
                  "Stakez des USDT, Bitcoins et ETH sur la bourse crypto construite pour le métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Gains Topcredit :  Pourcentage élevé de staking à rendement annuel APY% sur la bourse Topcredit",
                "og:description":
                  "Stakez des USDT, Bitcoins et ETH sur la bourse crypto construite pour le métavers",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            aboutus: {
              title: "Bourse Topcredit | À propos",
              meta_name: {
                description:
                  "Topcredit mène la course pour définir le métavers. Nous offrons une sélection toujours croissante de jetons numériques et de produits financiers afin que nos utilisateurs puissent investir en toute tranquillité",
                keywords:
                  "Bourse Topcredit, Page À propos, bourse crypto du métavers, Bourse de monnaies numériques",
                "twitter:title": "Bourse Topcredit : À propos",
                "twitter:description":
                  "Topcredit mène la course pour définir le métavers. Nous offrons une sélection toujours croissante de jetons numériques et de produits financiers afin que nos utilisateurs puissent investir en toute tranquillité",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title": "Bourse Topcredit : À propos",
                "og:description":
                  "Topcredit mène la course pour définir le métavers. Nous offrons une sélection toujours croissante de jetons numériques et de produits financiers afin que nos utilisateurs puissent investir en toute tranquillité",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Bourse Topcredit | Accord de service",
              meta_name: {
                description: "Terms and Conditions",
                keywords:
                  "Bourse Topcredit, accord de service, conditions générales, Bourse de monnaies numériques",
                "twitter:title": "Bourse Topcredit : Accord de service",
                "twitter:description": "Conditions générales",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title": "Bourse Topcredit : Accord de service",
                "og:description": "Conditions générales",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            register: {
              title: "Bourse Topcredit | Inscription",
              meta_name: {
                description:
                  "Négociez les BTC, ETH, DOGE, USDT et plus de 200 monnaies numériques | Comptant - Marge 300X - OTC - Produits dérivés - Staking | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse Topcredit, inscription, s’inscrire, saisir l’adresse e-mail",
                "twitter:title": "Bourse Topcredit : Inscription",
                "twitter:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title": "Bourse Topcredit : Inscription",
                "og:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            login: {
              title: "Bourse Topcredit | Connexion",
              meta_name: {
                description:
                  "Négociez les BTC, ETH, DOGE, USDT et plus de 200 monnaies numériques | Comptant - Marge 300X - OTC - Produits dérivés - Staking | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse Topcredit, Connexion, saisir l’adresse e-mail, saisir le mot de passe",
                "twitter:title": "Bourse Topcredit : Connexion",
                "twitter:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title": "Bourse Topcredit : Connexion",
                "og:description":
                  "Topcredit International est une plateforme de gestion d'actifs numériques à guichet unique pour investir dans Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) et d'autres cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            forgetPwd: {
              title:
                "Bourse Topcredit | Réinitialiser le mot de passe de connexion",
              meta_name: {
                description:
                  "Après la réinitialisation du mot de passe de connexion, les retraits sont interdits pendant 24 heures",
                keywords:
                  "Bourse Topcredit, Réinitialiser le mot de passe, mot de passe oublié, Bourse de monnaies numériques",
                "twitter:title":
                  "Bourse Topcredit : Réinitialiser le mot de passe de connexion",
                "twitter:description":
                  "Après la réinitialisation du mot de passe de connexion, les retraits sont interdits pendant 24 heures",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Bourse Topcredit : Réinitialiser le mot de passe de connexion",
                "og:description":
                  "Après la réinitialisation du mot de passe de connexion, les retraits sont interdits pendant 24 heures",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Bourse Topcredit | Politique de confidentialité",
              meta_name: {
                description: "Bourse Topcredit Politique de confidentialité",
                keywords:
                  "Bourse Topcredit, Politique de confidentialité, sécurité de l’information, Bourse de monnaies numériques",
                "twitter:title":
                  "Bourse Topcredit : Politique de confidentialité",
                "twitter:description":
                  "Sécurité de l’information et avis de confidentialité",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title": "Bourse Topcredit : Politique de confidentialité",
                "og:description":
                  "Sécurité de l’information et avis de confidentialité",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Négociez des marchés à terme de coinFullName sur une bourse Topcredit | Marchés à terme perpétuels de coinName/USDT\n      ",
              meta_name: {
                description:
                  "Négociez des marchés à terme de coinName USDT avec jusqu’à 200X de marge | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse Topcredit, Marchés à terme de coinFullName USDT, marchés à terme crypto, marchés à terme perpétuels de coinName",
                "twitter:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse Topcredit : Marchés à terme perpétuels de coinName/USDT",
                "twitter:description":
                  "Négociez des marchés à terme de coinName USDT avec jusqu’à 200X de marge : Rejoignez la bourse crypto avec une zone du métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse Topcredit : Marchés à terme perpétuels de coinName/USDT",
                "og:description":
                  "Négociez des marchés à terme de coinName USDT avec jusqu’à 200X de marge : Rejoignez la bourse crypto avec une zone du métavers",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            delivery: {
              title:
                "Négociez des marchés à terme de coinFullName sur une bourse Topcredit | Marchés à terme de coinName à marge",
              meta_name: {
                description:
                  "Négociez des marchés à terme de coinName avec jusqu’à 100X de marge | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse Topcredit, Marchés à terme de coinName USDT, marchés à terme de coinFullName, marchés à terme perpétuels",
                "twitter:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse Topcredit : Marchés à terme de coinName à marge",
                "twitter:description":
                  "Négociez des marchés à terme de coinName avec jusqu’à 100X de marge : Rejoignez la bourse crypto avec une zone du métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse Topcredit : Marchés à terme de coinName à marge",
                "og:description":
                  "Négociez des marchés à terme de coinName avec jusqu’à 100X de marge : Rejoignez la bourse crypto avec une zone du métavers",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
            exchange: {
              title:
                "Négociez des coinFullName sur une bourse Topcredit | Graphique des marchés au comptant de coinName",
              meta_name: {
                description:
                  "Achetez des coinName en quelques secondes | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse Topcredit, coinFullName au comptant, graphique de coinName, prix des monnaies numériques",
                "twitter:title":
                  "Négociez des coinFullName sur une bourse Topcredit : Graphique des marchés au comptant de coinName",
                "twitter:description":
                  "Achetez des coinName en quelques secondes : Rejoignez la bourse crypto avec une zone du métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
                "og:title":
                  "Négociez des coinFullName sur une bourse Topcredit : Graphique des marchés au comptant de coinName",
                "og:description":
                  "Achetez des coinName en quelques secondes : Rejoignez la bourse crypto avec une zone du métavers",
                "og:image:alt":
                  "Topcredit International - Acheter, échanger et détenir des centaines de crypto-monnaies - Topcredit International",
              },
            },
          },
        },
        {
          name: "Español",
          value: "es",
          valueFull: "es_ES",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              meta_name: {
                description:
                  "Opera con BTC, ETH, DOGE, USDT y otras 200 divisas digitales | Al contado - Margen 300X - Comercio OTC - Derivados - Staking | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Topcredit Bank, Topcredit International, Topcredit, Metaverse, moneda virtual, criptomoneda, activos encriptados, cadena de bloques, criptomonedas, moneda digital, derivados encriptados, intercambio de derivados encriptados, intercambio, plataforma de comercio de moneda digital, encriptación Cambio de moneda, Binance, Binance, Coinbase, Exchange , bybit, Kraken, KuCoin, OKX, Gate.io, Bitget, Pionex, Bingx, MEXC",
                "twitter:title":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "twitter:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            metaverse: {
              title:
                "Metaverso Topcredit | Rastreador del mercado de criptomonedas del Metaverso",
              meta_name: {
                description:
                  "Entra en el Metaverso | Mantente al día de las últimas noticias y conoce los precios de las divisas digitales del Metaverso",
                keywords:
                  "Bolsa de Topcredit, Página del Metaverso, Mercado del Metaverso, Bolsa del Metaverso",
                "twitter:title":
                  "Zona Metaverso V8 : Rastreador de criptomonedas del Metaverso",
                "twitter:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Zona Metaverso V8 : Rastreador de criptomonedas del Metaverso",
                "og:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | Gana criptomonedas por hacer staking con un porcentaje de rendimiento anual (APY) elevado",
              meta_name: {
                description:
                  "Staking de BTC, ETH, DOGE, USDT y muchas más divisas digitales | Rendimiento anual de hasta el 22,5 % | Staking DeFi y Cloud Mining disponible",
                keywords:
                  "Topcredit Earn, Staking de criptomonedas, Bolsa de criptomonedas del Metaverso, Staking de divisas digitales",
                "twitter:title":
                  "Topcredit Earn : Haz staking y obtén un rendimiento APY elevado en el Bolsa de Topcredit",
                "twitter:description":
                  "Haz staking de USDT, Bitcoin y ETH en el Bolsa de criptomonedas diseñado para el Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Topcredit Earn : Haz staking y obtén un rendimiento APY elevado en el Bolsa de Topcredit",
                "og:description":
                  "Haz staking de USDT, Bitcoin y ETH en el Bolsa de criptomonedas diseñado para el Metaverso",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            aboutus: {
              title: "Bolsa de Topcredit | Sobre nosotros",
              meta_name: {
                description:
                  "Topcredit va a la cabeza de la carrera para definir el Metaverso, por lo que ofrecemos una selección cada vez mayor de tokens digitales y productos financieros para que nuestros usuarios inviertan con tranquilidad",
                keywords:
                  "Bolsa de Topcredit, página Sobre nosotros, Bolsa de criptomonedas del Metaverso, Bolsa de divisas digitales",
                "twitter:title": "Bolsa de Topcredit : Sobre nosotros",
                "twitter:description":
                  "Topcredit va a la cabeza de la carrera para definir el Metaverso, por lo que ofrecemos una selección cada vez mayor de tokens digitales y productos financieros para que nuestros usuarios inviertan con tranquilidad",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title": "Bolsa de Topcredit : Sobre nosotros",
                "og:description":
                  "Topcredit va a la cabeza de la carrera para definir el Metaverso, por lo que ofrecemos una selección cada vez mayor de tokens digitales y productos financieros para que nuestros usuarios inviertan con tranquilidad",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            "service-agreement": {
              title: "Bolsa de Topcredit | Acuerdo de servicio",
              meta_name: {
                description: "Términos y condiciones",
                keywords:
                  "Bolsa de Topcredit, acuerdo de servicio, terminos y condiciones, Bolsa de divisas digitales",
                "twitter:title": "Bolsa de Topcredit : Acuerdo de servicio",
                "twitter:description": "Términos y condiciones",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title": "Bolsa de Topcredit : Acuerdo de servicio",
                "og:description": "Términos y condiciones",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            register: {
              title: "Bolsa de Topcredit | Regístrate",
              meta_name: {
                description:
                  "Opera con BTC, ETH, DOGE, USDT y otras 200 divisas digitales | Al contado - Margen 300X - Comercio OTC - Derivados - Staking | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de Topcredit, registro, regístrate, introduce tu correo electrónico",
                "twitter:title": "Bolsa de Topcredit : Regístrate",
                "twitter:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title": "Bolsa de Topcredit : Regístrate",
                "og:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            login: {
              title: "Bolsa de Topcredit | Iniciar sesión",
              meta_name: {
                description:
                  "Opera con BTC, ETH, DOGE, USDT y otras 200 divisas digitales | Al contado - Margen 300X - Comercio OTC - Derivados - Staking | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de Topcredit, Iniciar sesión, introducir correo electrónico, introducir contraseña",
                "twitter:title": "Bolsa de Topcredit : Iniciar sesión",
                "twitter:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title": "Bolsa de Topcredit : Iniciar sesión",
                "og:description":
                  "Topcredit International es una plataforma integral de gestión de activos digitales para invertir en Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) y otras criptomonedas.",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            forgetPwd: {
              title:
                "Bolsa de Topcredit | Restablecer contraseña de inicio de sesión",
              meta_name: {
                description:
                  "Después de restablecer la contraseña de inicio de sesión, no se pueden realizar retiradas durante 24 horas",
                keywords:
                  "Bolsa de Topcredit, Restablecer contraseña, Contraseña olvidada, Bolsa de divisas digitales",
                "twitter:title":
                  "Bolsa de Topcredit : Restablecer contraseña de inicio de sesión",
                "twitter:description":
                  "Después de restablecer la contraseña de inicio de sesión, no se pueden realizar retiradas durante 24 horas",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Bolsa de Topcredit : Restablecer contraseña de inicio de sesión",
                "og:description":
                  "Después de restablecer la contraseña de inicio de sesión, no se pueden realizar retiradas durante 24 horas",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Bolsa de Topcredit | Política de privacidad",
              meta_name: {
                description: "Bolsa de Topcredit Política de privacidad",
                keywords:
                  "Bolsa de Topcredit, Política de privacidad, Información sobre seguridad, Bolsa de divisas digitales",
                "twitter:title": "Bolsa de Topcredit : Política de privacidad",
                "twitter:description":
                  "Confidencialidad y seguridad de la información",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title": "Bolsa de Topcredit : Política de privacidad",
                "og:description":
                  "Confidencialidad y seguridad de la información",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Opera con futuros de coinFullName en el Bolsa de Topcredit | Futuros perpetuos coinName/USDT",
              meta_name: {
                description:
                  "Opera con futuros de coinName USDT con un margen de hasta 200X | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de Topcredit, Futuros coinFullName USDT, Futuros de criptomonedas, Futuros perpetuos coinName",
                "twitter:title":
                  "Opera con futuros de coinFullName en el Bolsa de Topcredit : Futuros perpetuos coinName/USDT",
                "twitter:description":
                  "Opera con futuros de coinName USDT con un margen de hasta 200X : Únete al Bolsa de criptomonedas con una zona Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Opera con futuros de coinFullName en el Bolsa de Topcredit : Futuros perpetuos coinName/USDT",
                "og:description":
                  "Opera con futuros de coinName USDT con un margen de hasta 200X : Únete al Bolsa de criptomonedas con una zona Metaverso",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            delivery: {
              title:
                "Opera con futuros de coinFullName en el Bolsa de Topcredit | Futuros con margen en coinName",
              meta_name: {
                description:
                  "Opera con futuros de coinName con un margen de hasta 100X | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de Topcredit, Futuros coinName USDT, Futuros coinFullName, Futuros perpetuos",
                "twitter:title":
                  "Opera con futuros de coinFullName en el Bolsa de Topcredit : Futuros con margen en coinName",
                "twitter:description":
                  "Opera con futuros de coinName con un margen de hasta 100X : Únete al Bolsa de criptomonedas con una zona Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Opera con futuros de coinFullName en el Bolsa de Topcredit : Futuros con margen en coinName",
                "og:description":
                  "Opera con futuros de coinName con un margen de hasta 100X : Únete al Bolsa de criptomonedas con una zona Metaverso",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
            exchange: {
              title:
                "Compra y vende coinFullName en el Bolsa de Topcredit | Gráfico del mercado al contado de coinName",
              meta_name: {
                description:
                  "Compra coinName en segundos | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de Topcredit, Al contado de coinFullName, gráfico de coinName, precios divisas digitales",
                "twitter:title":
                  "Compra y vende coinFullName en el Bolsa de Topcredit : Gráfico del mercado al contado de coinName",
                "twitter:description":
                  "Compra coinName en segundos : Únete al Bolsa de criptomonedas con una zona Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
                "og:title":
                  "Compra y vende coinFullName en el Bolsa de Topcredit : Gráfico del mercado al contado de coinName",
                "og:description":
                  "Compra coinName en segundos : Únete al Bolsa de criptomonedas con una zona Metaverso",
                "og:image:alt":
                  "Topcredit International | Compre, intercambie y mantenga cientos de criptomonedas-Topcredit International",
              },
            },
          },
        },
        {
          name: "Pусский",
          value: "ru",
          valueFull: "ru_RU",
          seoMsg: {
            index: {
              title:
                "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              meta_name: {
                description:
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
                keywords:
                  "Topcredit bank,Topcredit international,topcredit,metaverse,virtual currency,cryptocurrency,crypto asset,blockchain,crypto,digital currency,crypto derivatives,crypto derivatives exchange,exchange,digital currency trading platform,cryptocurrency exchange,coinan,binance,coinbase,exchange,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "twitter:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            metaverse: {
              title:
                "Topcredit Метаверс | Отслеживание криптовалютного рынка Метаверс",
              meta_name: {
                description:
                  "Перейти в раздел Метаверс - будьте в курсе последних метаверсовских криптовалютных новостей и цен",
                keywords:
                  "Topcredit Exchange, Метаверс страница, Метаверс рынок, Метаверс биржа",
                "twitter:title":
                  "8Topcredit Метаверс Зона : Метаверс Крипто Трекер",
                "twitter:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Метаверс Зона : Метаверс Крипто Трекер",
                "og:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | Зарабатывайте высокий доход APY% с помощью управления криптовалютными деньгами",
              meta_name: {
                description:
                  "Залог BTC ETH DOGE USDT и других криптовалют | До 22,5% годовых | Доступны залог DeFi и облачный майнинг",
                keywords:
                  "Topcredit Earn, Криптовалютный залог, криптовалютная биржа Метаверс, залог цифровой валюты",
                "twitter:title":
                  "Topcredit Earn : Высокие APY% ставки на Topcredit Бирже",
                "twitter:description":
                  "Залог USDT Биткоин и ETH в криптовалютной бирже для Метаверса",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title":
                  "Topcredit Earn : Высокие APY% ставки на Topcredit Бирже",
                "og:description":
                  "Залог USDT Биткоин и ETH в криптовалютной бирже для Метаверса",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | О нас",
              meta_name: {
                description:
                  "Topcredit возглавляет гонку по определению метавселенной, поскольку мы предлагаем растущий выбор криптовалют и финансовых продуктов, которые позволяют нашим пользователям инвестировать со спокойной душой",
                keywords:
                  "Topcredit Exchange, О нас страница, Криптовалютная биржа Метаверс, Криптовалютные биржи",
                "twitter:title": "Topcredit Exchange : О нас",
                "twitter:description":
                  "Topcredit возглавляет гонку по определению метавселенной, поскольку мы предлагаем растущий выбор криптовалют и финансовых продуктов, которые позволяют нашим пользователям инвестировать со спокойной душой",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Exchange : О нас",
                "og:description":
                  "Topcredit возглавляет гонку по определению метавселенной, поскольку мы предлагаем растущий выбор криптовалют и финансовых продуктов, которые позволяют нашим пользователям инвестировать со спокойной душой",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | Сервисное соглашение",
              meta_name: {
                description: "Положения и условия",
                keywords:
                  "Topcredit Exchange, Сервисное соглашение, Положения и условия, Криптовалютные биржи",
                "twitter:title": "Topcredit Exchange : Сервисное соглашение",
                "twitter:description": "Положения и условия",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Exchange : Сервисное соглашение",
                "og:description": "Положения и условия",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | Регистрация",
              meta_name: {
                description:
                  "Торговля BTC ETH DOGE USDT и более 200 другими цифровыми криптовалютами | Спот - 300x кредитное плечо - внебиржевая торговля - деривативы - залоги | Присоединяйтесь к секции Metaverse на криптовалютной бирже",
                keywords:
                  "Topcredit Exchange, регистрация, Регистрация, ввести электронный адрес",
                "twitter:title": "Topcredit Exchange : Регистрация",
                "twitter:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Exchange : Регистрация",
                "og:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | Логин",
              meta_name: {
                description:
                  "Торговля BTC ETH DOGE USDT и более 200 другими цифровыми криптовалютами | Спот - 300x кредитное плечо - внебиржевая торговля - деривативы - залоги | Присоединяйтесь к секции Metaverse на криптовалютной бирже",
                keywords:
                  "Topcredit Exchange, Логин, введите электронную почту, введите пароль",
                "twitter:title": "Topcredit Exchange : Логин",
                "twitter:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Exchange : Логин",
                "og:description":
                  "Topcredit International - это универсальная платформа управления цифровыми активами для инвестирования в Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) и другие криптовалюты.",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | Сброс пароля для входа",
              meta_name: {
                description:
                  "В течение 24 часов после сброса пароля для входа не снимаются средства",
                keywords:
                  "Topcredit Exchange, Сброс пароля, забыли пароль, Криптовалютные биржи",
                "twitter:title": "Topcredit Exchange : Сброс пароля для входа",
                "twitter:description":
                  "В течение 24 часов после сброса пароля для входа не снимаются средства",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Exchange : Сброс пароля для входа",
                "og:description":
                  "В течение 24 часов после сброса пароля для входа не снимаются средства",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | Политика конфиденциальности",
              meta_name: {
                description: "Topcredit Exchange Политика конфиденциальности",
                keywords:
                  "Topcredit Exchange, Политика конфиденциальности, информационная безопасность, Криптовалютные биржи",
                "twitter:title":
                  "Topcredit Exchange : Политика конфиденциальности",
                "twitter:description":
                  "Положение об информационной безопасности и конфиденциальности",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title": "Topcredit Exchange : Политика конфиденциальности",
                "og:description":
                  "Положение об информационной безопасности и конфиденциальности",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Торговля фьючерсами на Topcredit coinFullName | бессрочными контрактами coinName/USDT",
              meta_name: {
                description:
                  "Торговля фьючерсами coinName USDT с кредитным плечом до 200x | Вступить на криптовалютную биржу через зона Метаверс",
                keywords:
                  "Topcredit Exchange, Фьючерсы на coinFullName USDT, фьючерсы на криптовалюту, бессрочные фьючерсы на coinName",
                "twitter:title":
                  "Торговля фьючерсами на Topcredit coinFullName : бессрочными контрактами coinName/USDT",
                "twitter:description":
                  "Торговля фьючерсами coinName USDT с кредитным плечом до 200x : Вступить на криптовалютную биржу через зона Метаверс",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title":
                  "Торговля фьючерсами на Topcredit coinFullName : бессрочными контрактами coinName/USDT",
                "og:description":
                  "Торговля фьючерсами coinName USDT с кредитным плечом до 200x : Вступить на криптовалютную биржу через зона Метаверс",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            delivery: {
              title:
                "Торговля фьючерсами на coinFullName на бирже Topcredit | coinName Фьючерсы с кредитным плечом",
              meta_name: {
                description:
                  "Торговля фьючерсами coinName с кредитным плечом до 100x | Присоединяйтесь к криптовалютной бирже Метаверсес",
                keywords:
                  "Topcredit Exchange, Фьючерсы coinName USDT, фьючерсы на coinFullName, бессрочные фьючерсы",
                "twitter:title":
                  "Торговля фьючерсами на coinFullName на бирже Topcredit : coinName Фьючерсы с кредитным плечом",
                "twitter:description":
                  "Торговля фьючерсами coinName с кредитным плечом до 100x : Присоединяйтесь к криптовалютной бирже Метаверсес",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title":
                  "Торговля фьючерсами на coinFullName на бирже Topcredit : coinName Фьючерсы с кредитным плечом",
                "og:description":
                  "Торговля фьючерсами coinName с кредитным плечом до 100x : Присоединяйтесь к криптовалютной бирже Метаверсес",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
            exchange: {
              title:
                "Торговля coinFullName на бирже Topcredit | coinName спотовый рыночный график",
              meta_name: {
                description:
                  "Купить coinName за секунды | Присоединяйтесь к криптовалютной бирже с зоной Metaverse",
                keywords:
                  "Topcredit Exchange, coinFullName спот, график coinName, цена цифровой валюты",
                "twitter:title":
                  "Торговля coinFullName на бирже Topcredit : coinName спотовый рыночный график",
                "twitter:description":
                  "Купить coinName за секунды : Присоединяйтесь к криптовалютной бирже с зоной Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
                "og:title":
                  "Торговля coinFullName на бирже Topcredit : coinName спотовый рыночный график",
                "og:description":
                  "Купить coinName за секунды : Присоединяйтесь к криптовалютной бирже с зоной Metaverse",
                "og:image:alt":
                  "Topcredit Bank International | покупает, торгует и держит сотни криптовалют - Topcredit International",
              },
            },
          },
        },
        {
          name: "Deutsch",
          value: "de",
          valueFull: "de_DE",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              meta_name: {
                description:
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
                keywords:
                  "Topcredit bank,Topcredit international,topcredit,metaverse,virtuelle währung,kryptowährung,krypto asset,blockchain,krypto,digitale währung,krypto derivate,krypto derivate börse,börse,digitale währung handelsplattform,kryptowährung börse,coinan,binance,coinbase,börse,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "twitter:description":
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:description":
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit Metaverse | Metaverse Kryptobörse Tracker",
              meta_name: {
                description:
                  "Betreten Sie die Metaverse Zone | Metaverse Crypto Market Tracker | Bleiben Sie auf dem Laufenden mit den neuesten Nachrichten und Preisen für digitale Währungen von Metaverse",
                keywords:
                  "Topcredit Börse, Metaverse Seite, Metaverse Markt, Metaverse Börse",
                "twitter:title":
                  "Topcredit Metaverse Zone : Metaverse Krypto Tracker",
                "twitter:description":
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title":
                  "Topcredit Metaverse Zone : Metaverse Krypto Tracker",
                "og:description":
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | Verdienen Sie Krypto mit hohem APY%-Einsatz",
              meta_name: {
                description:
                  "Setzten Sie auf BTC ETH DOGE USDT & viele weitere digitale Währungen | bis zu 22,5% Jahresausblick | Staking DeFi und Cloud Mining verfügbar",
                keywords:
                  "Topcredit Earn, Krypto-Einsatz, Metaverse Krypto Börse, Digital Currency Staking",
                "twitter:title":
                  "Topcredit Earn : Höhe APY% Einsatz auf Topcredit Exchange",
                "twitter:description":
                  "Stake USDT Bitcoin und ETH auf der Krypto-Börse für das Metaverse gebaut",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title":
                  "Topcredit Earn : Höhe APY% Einsatz auf Topcredit Exchange",
                "og:description":
                  "Stake USDT Bitcoin und ETH auf der Krypto-Börse für das Metaverse gebaut",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Börse | Impressum",
              meta_name: {
                description:
                  "Topcredit ist führend im Rennen um die Definition des Metaversums. Wir bieten eine ständig wachsende Auswahl an digitalen Token und Finanzprodukten, damit unsere Nutzer beruhigt investieren können",
                keywords:
                  "Topcredit Börse, Impressum Seite, Metaverse Kryptowährungsbörse, Exchange digitaler Währungen",
                "twitter:title": "Topcredit Börse : Impressum",
                "twitter:description":
                  "Topcredit ist führend im Rennen um die Definition des Metaversums. Wir bieten eine ständig wachsende Auswahl an digitalen Token und Finanzprodukten, damit unsere Nutzer beruhigt investieren können",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title": "Topcredit Börse : Impressum",
                "og:description":
                  "Topcredit ist führend im Rennen um die Definition des Metaversums. Wir bieten eine ständig wachsende Auswahl an digitalen Token und Finanzprodukten, damit unsere Nutzer beruhigt investieren können",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Börse | Dienstleistungsvertrag",
              meta_name: {
                description: "Allgemeine Geschäftsbedingungen",
                keywords:
                  "Topcredit Börse, Dienstleistungsvertrag, Allgemeine Geschäftsbedingungen, Exchange digitaler Währungen",
                "twitter:title": "Topcredit Börse : Dienstleistungsvertrag",
                "twitter:description": "Allgemeine Geschäftsbedingungen",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title": "Topcredit Börse : Dienstleistungsvertrag",
                "og:description": "Allgemeine Geschäftsbedingungen",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            register: {
              title: "Topcredit Börse | Registrieren",
              meta_name: {
                description:
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
                keywords:
                  "Topcredit Börse, sich registrieren, Anmeldung, E-Mail Adresse eingeben",
                "twitter:title": "Topcredit Börse : Registrieren",
                "twitter:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title": "Topcredit Börse : Registrieren",
                "og:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            login: {
              title: "Topcredit Börse | Einloggen",
              meta_name: {
                description:
                  "Topcredit International ist eine One-Stop-Plattform für die Verwaltung digitaler Vermögenswerte für Investitionen in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) und andere Kryptowährungen.",
                keywords:
                  "Topcredit Börse, Einloggen, E-Mail eingeben, Passwort eingeben",
                "twitter:title": "Topcredit Börse : Einloggen",
                "twitter:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title": "Topcredit Börse : Einloggen",
                "og:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Börse | Passwort zurücksetzen",
              meta_name: {
                description:
                  "Nach Zurücksetzung des Passworts sind Auszahlungen für 24 Stunden untersagt",
                keywords:
                  "Topcredit Börse, Passwort zurücksetzen, Passwort vergessen, Exchange digitaler Währungen",
                "twitter:title": "Topcredit Börse : Passwort zurücksetzen",
                "twitter:description":
                  "Nach Zurücksetzung des Passworts sind Auszahlungen für 24 Stunden untersagt",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title": "Topcredit Börse : Passwort zurücksetzen",
                "og:description":
                  "Nach Zurücksetzung des Passworts sind Auszahlungen für 24 Stunden untersagt",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Börse | Datenschutzrichtlinie",
              meta_name: {
                description: "Topcredit Börse Datenschutzrichtlinie",
                keywords:
                  "Topcredit Börse, Datenschutzrichtlinie, Informations-Sicherheit, Exchange digitaler Währungen",
                "twitter:title": "Topcredit Börse : Datenschutzrichtlinie",
                "twitter:description": "Informationssicherheit und Datenschutz",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title": "Topcredit Börse : Datenschutzrichtlinie",
                "og:description": "Informationssicherheit und Datenschutz",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Trade coinFullName Futures auf Topcredit Exchange | coinName/USDT Perpetual Futures",
              meta_name: {
                description:
                  "Trading coinName USDT Futures bis zu 200X Margin | Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                keywords:
                  "Topcredit Börse, coinFullName USDT-Futures, Krypto-Futures, coinName-Perpetual-Futures",
                "twitter:title":
                  "Trade coinFullName Futures auf Topcredit Exchange : coinName/USDT Perpetual Futures",
                "twitter:description":
                  "Trading coinName USDT Futures bis zu 200X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title":
                  "Trade coinFullName Futures auf Topcredit Exchange : coinName/USDT Perpetual Futures",
                "og:description":
                  "Trading coinName USDT Futures bis zu 200X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            delivery: {
              title:
                "Trading coinFullName-Futures auf Topcredit Exchange | coinName Margined Futures",
              meta_name: {
                description:
                  "Trading coinName Margined Futures bis zu 100X Margin | Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                keywords:
                  "Topcredit Börse, coinName USDT-Futures, coinFullName-Futures, Perpetual-Futures",
                "twitter:title":
                  "Trading coinFullName-Futures auf Topcredit Exchange : coinName Margined Futures",
                "twitter:description":
                  "Trading coinName Margined Futures bis zu 100X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title":
                  "Trading coinFullName-Futures auf Topcredit Exchange : coinName Margined Futures",
                "og:description":
                  "Trading coinName Margined Futures bis zu 100X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
            exchange: {
              title:
                " coinFullName an der Topcredit Börse handeln | coinName Spot Market Chart",
              meta_name: {
                description:
                  "Kaufen Sie coinName in Sekunden | Treten Sie der Krypto-Börse mit einer Metaverse-Zone bei",
                keywords:
                  "Topcredit Börse, coinFullName Spot, coinName Chart, Digitaler Währungspreis",
                "twitter:title":
                  "coinFullName an der Topcredit Börse handeln : coinName Spot Market Chart",
                "twitter:description":
                  "Kaufen Sie coinName in Sekunden : Treten Sie der Krypto-Börse mit einer Metaverse-Zone bei",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
                "og:title":
                  "coinFullName an der Topcredit Börse handeln : coinName Spot Market Chart",
                "og:description":
                  "Kaufen Sie coinName in Sekunden : Treten Sie der Krypto-Börse mit einer Metaverse-Zone bei",
                "og:image:alt":
                  "Topcredit International | Hunderte von Kryptowährungen kaufen, handeln und halten - Topcredit International",
              },
            },
          },
        },
        {
          name: "Português",
          value: "pt",
          valueFull: "pt_BR",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              meta_name: {
                description:
                  "Transacione BTC ETH DOGE USDT e mais de 200 outras moedas digitais | Spot - Margem 300X - OTC - Derivados - Staking | Junte-se ao mercado das criptomoedas com uma zona Metaverse",
                keywords:
                  "Topcredit bank,Topcredit international,topcredit,metaverse,virtual currency,cryptocurrency,crypto asset,blockchain,crypto,digital currency,crypto derivatives,crypto derivatives exchange,exchange,digital currency trading platform,cryptocurrency exchange,coinan,binance,coinbase,exchange,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "twitter:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            metaverse: {
              title:
                "Topcredit Metaverse | Tracker do mercado de criptomoeda Metaverse",
              meta_name: {
                description:
                  "Entre na Zona Metaverse | Tracker do mercado de criptomoeda Metaverse | Mantenha-se atualizado com as mais recentes notícias e preços da moeda digital Metaverse",
                keywords:
                  "Topcredit Exchange, Página da Metaverse, mercado da Metaverse, Exchange da Metaverse",
                "twitter:title":
                  "Topcredit Zona Metaverse: tracker de criptomoeda Metaverse",
                "twitter:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Topcredit Zona Metaverse: tracker de criptomoeda Metaverse",
                "og:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | Ganhe Criptomoeda com Staking de Alta APY%",
              meta_name: {
                description:
                  "Realize o Staking de BTC ETH DOGE USDT e muitas mais moedas digitais | Até 22,5% de Rendimento Anual | Disponibilidade de DeFi de Staking e Mineração na Cloud",
                keywords:
                  "Topcredit Earn, Staking de criptomoeda, Exchange de criptomoeda Metaverse, staking de moeda digital",
                "twitter:title":
                  "Topcredit Earn: Staking de alta APY% em exchange Topcredit",
                "twitter:description":
                  "Realize staking de bitcoin USDT e ETH na exchange de criptomoeda criada para a Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Topcredit Earn: Staking de alta APY% em exchange Topcredit",
                "og:description":
                  "Realize staking de bitcoin USDT e ETH na exchange de criptomoeda criada para a Metaverse",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | Sobre nós",
              meta_name: {
                description:
                  "Topcredit lidera a corrida na definição da metaverse, oferecendo uma seleção crescente de tokens digitais e produtos financeiros , de modo a que os nossos utilizadores possam investir com tranquilidade",
                keywords:
                  "Topcredit Exchange, página sobre nós, Exchange de criptomoeda Metaverse, Exchange de moeda digital",
                "twitter:title": "Topcredit Exchange : Sobre nós",
                "twitter:description":
                  "Topcredit lidera a corrida na definição da metaverse, oferecendo uma seleção crescente de tokens digitais e produtos financeiros , de modo a que os nossos utilizadores possam investir com tranquilidade",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title": "Topcredit Exchange : Sobre nós",
                "og:description":
                  "Topcredit lidera a corrida na definição da metaverse, oferecendo uma seleção crescente de tokens digitais e produtos financeiros , de modo a que os nossos utilizadores possam investir com tranquilidade",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | Contrato de serviço",
              meta_name: {
                description: "Termos e condições",
                keywords:
                  "Topcredit Exchange, Contrato de serviço, Termos e condições, Exchange de moeda digital",
                "twitter:title": "Topcredit Exchange : Contrato de serviço",
                "twitter:description": "Termos e condições",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title": "Topcredit Exchange : Contrato de serviço",
                "og:description": "Termos e condições",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | Registar",
              meta_name: {
                description:
                  "Transacione BTC ETH DOGE USDT e mais de 200 outras moedas digitais | Spot - Margem 300X - OTC - Derivados - Staking | Junte-se ao mercado das criptomoedas com uma zona Metaverse",
                keywords:
                  "Topcredit Exchange, Registar, inserir endereço de e-mail",
                "twitter:title": "Topcredit Exchange : Registar",
                "twitter:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title": "Topcredit Exchange : Registar",
                "og:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | Iniciar sessão",
              meta_name: {
                description:
                  "Transacione BTC ETH DOGE USDT e mais de 200 outras moedas digitais | Spot - Margem 300X - OTC - Derivados - Staking | Junte-se ao mercado das criptomoedas com uma zona Metaverse",
                keywords:
                  "Topcredit Exchange, Iniciar sessão, inserir e-mail, inserir palavra-passe",
                "twitter:title": "Topcredit Exchange : Iniciar sessão",
                "twitter:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title": "Topcredit Exchange : Iniciar sessão",
                "og:description":
                  " A Topcredit International é uma plataforma única de gestão de activos digitais para investir em Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e outras criptomoedas.",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            forgetPwd: {
              title:
                "Topcredit Exchange | Repor palavra-passe de início de sessão",
              meta_name: {
                description:
                  "Após a reposição da palavra-passe de início de sessão, os levantamentos estão proibidos durante 24 horas",
                keywords:
                  "Topcredit Exchange, Repor palavra-passe, esqueci-me da palavra-passe, Exchange de moeda digital",
                "twitter:title":
                  "Topcredit Exchange : Repor palavra-passe de início de sessão",
                "twitter:description":
                  "Após a reposição da palavra-passe de início de sessão, os levantamentos estão proibidos durante 24 horas",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Topcredit Exchange : Repor palavra-passe de início de sessão",
                "og:description":
                  "Após a reposição da palavra-passe de início de sessão, os levantamentos estão proibidos durante 24 horas",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | Política de privacidade",
              meta_name: {
                description: "Topcredit Exchange Política de privacidade",
                keywords:
                  "Topcredit Exchange, Política de privacidade, segurança das informações, Exchange de moeda digital",
                "twitter:title": "Topcredit Exchange : Política de privacidade",
                "twitter:description":
                  "Aviso de segurança e privacidade da Informação",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title": "Topcredit Exchange : Política de privacidade",
                "og:description":
                  "Aviso de segurança e privacidade da Informação",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Transacione futuros em coinFullName na Exchange Topcredit | Futuros Perpétuos em coinName/USDT",
              meta_name: {
                description:
                  "Transacione Futuros coinName USDT com uma Margem de até 200X | Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                keywords:
                  "Topcredit Exchange, Futuros USDT de coinFullName, futuros de criptomoeda, futuros perpétuos de coinName",
                "twitter:title":
                  "Transacione futuros em coinFullName na Exchange Topcredit : Futuros Perpétuos em coinName/USDT",
                "twitter:description":
                  "Transacione Futuros coinName USDT com uma Margem de até 200X : Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Transacione futuros em coinFullName na Exchange Topcredit : Futuros Perpétuos em coinName/USDT",
                "og:description":
                  "Transacione Futuros coinName USDT com uma Margem de até 200X : Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            delivery: {
              title:
                "Transacione futuros em coinFullName na Exchange Topcredit | Futuros marginados em coinName",
              meta_name: {
                description:
                  "Transacione futuros marginados em coinName com uma margem de até 100X | Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                keywords:
                  "Topcredit Exchange, Futuros USDT de coinName, futuros de coinFullName, futuros Perpétuos",
                "twitter:title":
                  "Transacione futuros em coinFullName na Exchange Topcredit : Futuros marginados em coinName",
                "twitter:description":
                  "Transacione futuros marginados em coinName com uma margem de até 100X | Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Transacione futuros em coinFullName na Exchange Topcredit : Futuros marginados em coinName",
                "og:description":
                  "Transacione futuros marginados em coinName com uma margem de até 100X : Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
            exchange: {
              title:
                "Transacione coinFullName na Exchange Topcredit | Gráfico do mercado à vista de coinName",
              meta_name: {
                description:
                  "Compre coinName em segundos | Junte-se ao Exchange de Criptomoeda com uma zona metaverse",
                keywords:
                  "Topcredit Exchange, Mercado à vista de coinFullName, gráfico de coinName, preço da moeda digital",
                "twitter:title":
                  "Transacione coinFullName na Exchange Topcredit : Gráfico do mercado à vista de coinName",
                "twitter:description":
                  "Compre coinName em segundos : Junte-se ao Exchange de Criptomoeda com uma zona metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
                "og:title":
                  "Transacione coinFullName na Exchange Topcredit : Gráfico do mercado à vista de coinName",
                "og:description":
                  "Compre coinName em segundos : Junte-se ao Exchange de Criptomoeda com uma zona metaverse",
                "og:image:alt":
                  "Topcredit International | Comprar, negociar e manter centenas de criptomoedas - Topcredit International",
              },
            },
          },
        },
        {
          name: "हिंदी",
          value: "hi",
          valueFull: "hi_IN",
          seoMsg: {
            index: {
              title:
                "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं 200 से अधिक डिजिटल मुद्रा से ट्रेड करें | स्पॉट - मार्जिन 300X - OTC - डेरीवेटिव्स- स्टेकिंग | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "Topcredit , Topcredit International, Topcredit, Metaverse, वर्चुअल करेंसी, क्रिप्टोकरेंसी, एन्क्रिप्टेड एसेट्स, ब्लॉकचेन, क्रिप्टो, डिजिटल करेंसी, एन्क्रिप्टेड डेरिवेटिव, एन्क्रिप्टेड डेरिवेटिव एक्सचेंज, एक्सचेंज, डिजिटल करेंसी ट्रेडिंग प्लेटफॉर्म, एन्क्रिप्शन करेंसी एक्सचेंज, बायनेन्स, बाइनेंस, कॉइनबेस, एक्सचेंज , bybit, Kraken, KuCoin, OKX, Gate.io, Bitget, Pionex, Bingx, MEXC",
                "twitter:title":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "twitter:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit मेटावर्स | मेटावर्स क्रिप्टो बाजार ट्रैकर",
              meta_name: {
                description:
                  "मेटावर्स ज़ोन दर्ज करें | नवीनतम मेटावर्स डिजिटल मुद्रा समाचार और कीमतों से अद्यतन बने रहें",
                keywords:
                  "Topcredit एक्सचेंज, मेटावर्स पेज, मेटावर्स बाजार, मेटावर्स एक्सचेंज",
                "twitter:title":
                  "Topcredit मेटावर्स ज़ोन : मेटावर्स क्रिप्टो ट्रैकर",
                "twitter:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title":
                  "Topcredit मेटावर्स ज़ोन : मेटावर्स क्रिप्टो ट्रैकर",
                "og:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit कमाएं | उच्च APY% स्टेकिंग के साथ क्रिप्टो कमाएं",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं बहुत सी डिजिटल मुद्रा में स्टेक लगाएं | 22.5% तक वार्षिक प्राप्ति | DeFi स्टेकिंग और क्लाउड माइनिंग उपलब्ध",
                keywords:
                  "Topcredit कमाएं, क्रिप्टो स्टेकिंग, मेटावर्स क्रिप्टो एक्सचेंज, डिजिटल मुद्रा स्टेकिंग",
                "twitter:title":
                  "Topcredit कमाएं : Topcredit एक्सचेंज पर उच्च APY%",
                "twitter:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर स्टेक USDT बिटकॉइन तथा ETH",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit कमाएं : Topcredit एक्सचेंज पर उच्च APY%",
                "og:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर स्टेक USDT बिटकॉइन तथा ETH",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit एक्सचेंज | हमारे बारे में",
              meta_name: {
                description:
                  "Topcredit मेटावर्स को परिभाषित करने की दौड़ में अग्रणी है, हम डिजिटल टोकन और वित्तीय उत्पादों के बढ़ते चयन की पेशकश करते हैं ताकि हमारे उपयोगकर्ता शांतिपूर्वक निवेश कर सकें",
                keywords:
                  "Topcredit एक्सचेंज, हमारे बारे में का पेज, मेटावर्स क्रिप्टोकरेंसी एक्सचेंज, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "Topcredit एक्सचेंज : हमारे बारे में",
                "twitter:description":
                  "Topcredit मेटावर्स को परिभाषित करने की दौड़ में अग्रणी है, हम डिजिटल टोकन और वित्तीय उत्पादों के बढ़ते चयन की पेशकश करते हैं ताकि हमारे उपयोगकर्ता शांतिपूर्वक निवेश कर सकें",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit एक्सचेंज : हमारे बारे में",
                "og:description":
                  "Topcredit मेटावर्स को परिभाषित करने की दौड़ में अग्रणी है, हम डिजिटल टोकन और वित्तीय उत्पादों के बढ़ते चयन की पेशकश करते हैं ताकि हमारे उपयोगकर्ता शांतिपूर्वक निवेश कर सकें",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit एक्सचेंज | सेवा अनुबंध",
              meta_name: {
                description: "नियम और शर्तें",
                keywords:
                  "Topcredit एक्सचेंज, सेवा अनुबंध, नियम और शर्तें, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "Topcredit एक्सचेंज : सेवा अनुबंध",
                "twitter:description": "नियम और शर्तें",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit एक्सचेंज : सेवा अनुबंध",
                "og:description": "नियम और शर्तें",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            register: {
              title: "Topcredit एक्सचेंज | साइन अप करें",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं 200 से अधिक डिजिटल मुद्रा से ट्रेड करें | स्पॉट - मार्जिन 300X - OTC - डेरीवेटिव्स- स्टेकिंग | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "Topcredit एक्सचेंज, रजिस्टर करें, साइन अप करें, ईमेल पता दर्ज करें",
                "twitter:title": "Topcredit एक्सचेंज : साइन अप करें",
                "twitter:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit एक्सचेंज : साइन अप करें",
                "og:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            login: {
              title: "Topcredit एक्सचेंज | लॉग इन करें",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं 200 से अधिक डिजिटल मुद्रा से ट्रेड करें | स्पॉट - मार्जिन 300X - OTC - डेरीवेटिव्स- स्टेकिंग | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "Topcredit एक्सचेंज, Log in, enter email, enter password",
                "twitter:title": "Topcredit एक्सचेंज : लॉग इन करें",
                "twitter:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit एक्सचेंज : लॉग इन करें",
                "og:description":
                  " एफएक्स इंटरनेशनल बिटकॉइन (बीटीसी), एथेरियम (ईटीएच), यूएसडीटी, शिबा इनू (एसएचआईबी) और अन्य क्रिप्टोक्यूरैंक्स में निवेश करने के लिए एक-स्टॉप डिजिटल एसेट मैनेजमेंट प्लेटफॉर्म है।",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit एक्सचेंज | लॉग इन पासवर्ड रीसेट करें",
              meta_name: {
                description:
                  "लॉग इन पासवर्ड रीसेट करने के बाद, निकासियाँ 24 घंटों के लिए निषिद्ध होती हैं",
                keywords:
                  "Topcredit एक्सचेंज, Reset Password, Forgot Password, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title":
                  "Topcredit एक्सचेंज : लॉग इन पासवर्ड रीसेट करें",
                "twitter:description":
                  "लॉग इन पासवर्ड रीसेट करने के बाद, निकासियाँ 24 घंटों के लिए निषिद्ध होती हैं",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit एक्सचेंज : लॉग इन पासवर्ड रीसेट करें",
                "og:description":
                  "लॉग इन पासवर्ड रीसेट करने के बाद, निकासियाँ 24 घंटों के लिए निषिद्ध होती हैं",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit एक्सचेंज | निजता नीति",
              meta_name: {
                description: "Topcredit एक्सचेंज निजता नीति",
                keywords:
                  "Topcredit एक्सचेंज, निजता नीति, जानकारी सुरक्षा, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "Topcredit एक्सचेंज : निजता नीति",
                "twitter:description": "जानकारी सुरक्षा और निजता सूचना",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title": "Topcredit एक्सचेंज : निजता नीति",
                "og:description": "जानकारी सुरक्षा और निजता सूचना",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Topcredit एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें | coinName/USDT पर्पीचुअल फ्यूचर्स",
              meta_name: {
                description:
                  "200X मार्जिन तक coinName USDT फ्यूचर्स ट्रेड करें | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "Topcredit एक्सचेंज, coinFullName USDT फ्यूचर्स, क्रिप्टो फ्यूचर्स, coinName पर्पीचुअल फ्यूचर्स",
                "twitter:title":
                  "Topcredit एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName/USDT पर्पीचुअल फ्यूचर्स",
                "twitter:description":
                  "200X मार्जिन तक coinName USDT फ्यूचर्स ट्रेड करें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title":
                  "Topcredit एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName/USDT पर्पीचुअल फ्यूचर्स",
                "og:description":
                  "200X मार्जिन तक coinName USDT फ्यूचर्स ट्रेड करें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            delivery: {
              title:
                "Topcredit एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें | coinName मार्जिन्ड फ्यूचर्स",
              meta_name: {
                description:
                  "100X मार्जिन तक coinName मार्जिन्ड फ्यूचर्स ट्रेड करें |मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "Topcredit एक्सचेंज,coinName USDT फ्यूचर्स, coinFullName फ्यूचर्स, पर्पीचुअल फ्यूचर्स",
                "twitter:title":
                  "Topcredit एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName मार्जिन्ड फ्यूचर्स",
                "twitter:description":
                  "100X मार्जिन तक coinName मार्जिन्ड फ्यूचर्स ट्रेड करें |मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title":
                  "Topcredit एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName मार्जिन्ड फ्यूचर्स",
                "og:description":
                  "100X मार्जिन तक coinName मार्जिन्ड फ्यूचर्स ट्रेड करें |मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
            exchange: {
              title:
                "Topcredit एक्सचेंज पर coinFullName ट्रेड करें | coinName स्पॉट बाजार चार्ट",
              meta_name: {
                description:
                  "सेकंड में coinName खरीदें | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "Topcredit एक्सचेंज,coinFullName स्पॉट, coinName चार्ट, डिजिटल मुद्रा कीमत",
                "twitter:title":
                  "Topcredit एक्सचेंज पर coinFullName ट्रेड करें : coinName स्पॉट बाजार चार्ट",
                "twitter:description":
                  "सेकंड में coinName खरीदें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
              },
              meta_property: {
                "twitter:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
                "og:title":
                  "Topcredit एक्सचेंज पर coinFullName ट्रेड करें : coinName स्पॉट बाजार चार्ट",
                "og:description":
                  "सेकंड में coinName खरीदें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                "og:image:alt":
                  "टॉपक्रेडिट इंटरनेशनल | सैकड़ों क्रिप्टोकरेंसी-टॉपक्रेडिट इंटरनेशनल खरीदें, ट्रेड करें और होल्ड करें- Topcredit International",
              },
            },
          },
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          valueFull: "vi_VN",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              meta_name: {
                description:
                  "Giao dịch BTC ETH DOGE USDT và hơn 200 loại Tiền kỹ thuật số khác | Giao ngay - Ký quỹ 300X - OTC - Công cụ phái sinh - Staking | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Topcredit Bank, Topcredit International, Topcredit, Metaverse, tiền ảo, tiền điện tử, tài sản được mã hóa, chuỗi khối, Tiền điện tử, tiền kỹ thuật số, phái sinh được mã hóa, trao đổi phái sinh được mã hóa, trao đổi, sàn giao dịch tiền kỹ thuật số, Trao đổi tiền mã hóa, Binance, Binance, Coinbase, Sàn giao dịch , bybit, Kraken, KuCoin, OKX, Gate.io, Bitget, Pionex, Bingx, MEXC",
                "twitter:title":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "twitter:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            metaverse: {
              title:
                "Topcredit Metaverse | Công cụ theo dõi Thị trường Tiền điện tử Metaverse",
              meta_name: {
                description:
                  "Vào Metaverse Zone | Luôn cập nhật Tin tức và Giá tiền kỹ thuật số Metaverse mới nhất",
                keywords:
                  "Sàn giao dịch Topcredit, Trang Metaverse, Thị trường Metaverse, Sàn giao dịch Metaverse",
                "twitter:title":
                  "Metaverse Zone Topcredit : Công cụ theo dõi Tiền điện tử Metaverse",
                "twitter:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Metaverse Zone Topcredit : Công cụ theo dõi Tiền điện tử Metaverse",
                "og:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            finances: {
              title: "Kiếm Topcredit | Kiếm Tiền điện tử với Staking APY% cao",
              meta_name: {
                description:
                  "Stake BTC ETH DOGE USDT & Nhiều loại Tiền kỹ thuật số khác | Lên tới 22,5% Lợi tức hàng năm | Staking DeFi và Khai thác trên Nền tảng đám mây có sẵn",
                keywords:
                  "Topcredit Earn, Staking Tiền điện tử, Sàn giao dịch Tiền điện tử Metaverse, Staking Tiền kỹ thuật số",
                "twitter:title":
                  "Kiếm Topcredit : Kiếm Tiền điện tử với Staking APY% cao",
                "twitter:description":
                  "Stake USDT Bitcoin và ETH trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Kiếm Topcredit : Kiếm Tiền điện tử với Staking APY% cao",
                "og:description":
                  "Stake USDT Bitcoin và ETH trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            aboutus: {
              title: "Sàn giao dịch Topcredit | Về chúng tôi",
              meta_name: {
                description:
                  "Topcredit đang dẫn đầu cuộc đua xác định Metaverse, chúng tôi cung cấp nhiều lựa chọn mã token kỹ thuật số và sản phẩm tài chính để người dùng của chúng tôi có thể yên tâm đầu tư",
                keywords:
                  "Sàn giao dịch Topcredit, trang giới thiệu về chúng tôi, Sàn giao dịch Tiền điện tử Metaverse, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title": "Sàn giao dịch Topcredit : Về chúng tôi",
                "twitter:description":
                  "Topcredit đang dẫn đầu cuộc đua xác định Metaverse, chúng tôi cung cấp nhiều lựa chọn mã token kỹ thuật số và sản phẩm tài chính để người dùng của chúng tôi có thể yên tâm đầu tư",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title": "Sàn giao dịch Topcredit : Về chúng tôi",
                "og:description":
                  "Topcredit đang dẫn đầu cuộc đua xác định Metaverse, chúng tôi cung cấp nhiều lựa chọn mã token kỹ thuật số và sản phẩm tài chính để người dùng của chúng tôi có thể yên tâm đầu tư",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            "service-agreement": {
              title: "Sàn giao dịch Topcredit | Thỏa thuận Dịch vụ",
              meta_name: {
                description: "Các điều khoản và Điều kiện",
                keywords:
                  "Sàn giao dịch Topcredit, Thỏa thuận Dịch vụ, Các điều khoản và Điều kiện, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title": "Sàn giao dịch Topcredit : Thỏa thuận Dịch vụ",
                "twitter:description": "Các điều khoản và Điều kiện",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title": "Sàn giao dịch Topcredit : Thỏa thuận Dịch vụ",
                "og:description": "Các điều khoản và Điều kiện",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            register: {
              title: "Sàn giao dịch Topcredit | Đăng ký",
              meta_name: {
                description:
                  "Giao dịch BTC ETH DOGE USDT và hơn 200 loại Tiền kỹ thuật số khác | Giao ngay - Ký quỹ 300X - OTC - Công cụ phái sinh - Staking | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch Topcredit, đăng ký, Đăng ký, nhập địa chỉ email",
                "twitter:title": "Sàn giao dịch Topcredit : Đăng ký",
                "twitter:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title": "Sàn giao dịch Topcredit : Đăng ký",
                "og:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            login: {
              title: "Sàn giao dịch Topcredit | Đăng nhập",
              meta_name: {
                description:
                  "Giao dịch BTC ETH DOGE USDT và hơn 200 loại Tiền kỹ thuật số khác | Giao ngay - Ký quỹ 300X - OTC - Công cụ phái sinh - Staking | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch Topcredit, Đăng nhập, nhập email, nhập mật khẩu",
                "twitter:title": "Sàn giao dịch Topcredit : Đăng nhập",
                "twitter:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title": "Sàn giao dịch Topcredit : Đăng nhập",
                "og:description":
                  "Topcredit International là nền tảng quản lý tài sản kỹ thuật số một cửa để đầu tư vào Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) và các loại tiền điện tử khác.",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            forgetPwd: {
              title: "Sàn giao dịch Topcredit | Đặt Lại Mật Khẩu Đăng Nhập",
              meta_name: {
                description:
                  "Sau khi Đặt lại Mật khẩu Đăng nhập, Rút tiền bị Cấm trong 24 giờ",
                keywords:
                  "Sàn giao dịch Topcredit, Đặt lại Mật khẩu, Quên mật khẩu, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title":
                  "Sàn giao dịch Topcredit : Đặt Lại Mật Khẩu Đăng Nhập",
                "twitter:description":
                  "Sau khi Đặt lại Mật khẩu Đăng nhập, Rút tiền bị Cấm trong 24 giờ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Sàn giao dịch Topcredit : Đặt Lại Mật Khẩu Đăng Nhập",
                "og:description":
                  "Sau khi Đặt lại Mật khẩu Đăng nhập, Rút tiền bị Cấm trong 24 giờ",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Sàn giao dịch Topcredit | Chính sách Quyền riêng tư",
              meta_name: {
                description:
                  "Sàn giao dịch Topcredit Chính sách Quyền riêng tư",
                keywords:
                  "Sàn giao dịch Topcredit, Chính sách Quyền riêng tư, bảo mật thông tin, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title":
                  "Sàn giao dịch Topcredit : Chính sách Quyền riêng tư",
                "twitter:description": "Thông báo Bảo mật và Quyền riêng tư",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Sàn giao dịch Topcredit : Chính sách Quyền riêng tư",
                "og:description": "Thông báo Bảo mật và Quyền riêng tư",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch Topcredit | Hợp đồng tương lai vĩnh viễn coinName/USDT\n\t",
              meta_name: {
                description:
                  "Giao dịch Hợp đồng tương lai coinName USDT với Số tiền ký quỹ lên tới 200 lần | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch Topcredit, Hợp đồng tương lai coinFullName USDT, Hợp đồng tương lai Tiền điện tử, Hợp đồng tương lai vĩnh viễn coinName",
                "twitter:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch Topcredit : Hợp đồng tương lai vĩnh viễn coinName/USDT",
                "twitter:description":
                  "Giao dịch Hợp đồng tương lai coinName USDT với Số tiền ký quỹ lên tới 200 lần : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch Topcredit : Hợp đồng tương lai vĩnh viễn coinName/USDT",
                "og:description":
                  "Giao dịch Hợp đồng tương lai coinName USDT với Số tiền ký quỹ lên tới 200 lần : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            delivery: {
              title:
                "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch Topcredit | Hợp đồng tương lai Ký quỹ coinName",
              meta_name: {
                description:
                  "Giao dịch Hợp đồng tương lai Ký quỹ coinName lên tới 100 lần Ký quỹ | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch Topcredit, Hợp đồng tương lai coinName USDT, Hợp đồng tương lai coinFullName, Hợp đồng vĩnh viễn",
                "twitter:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch Topcredit | Hợp đồng tương lai Ký quỹ coinName",
                "twitter:description":
                  "Giao dịch Hợp đồng tương lai Ký quỹ coinName lên tới 100 lần Ký quỹ | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch Topcredit | Hợp đồng tương lai Ký quỹ coinName",
                "og:description":
                  "Giao dịch Hợp đồng tương lai Ký quỹ coinName lên tới 100 lần Ký quỹ | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
            exchange: {
              title:
                "Giao dịch coinFullName trên Sàn giao dịch Topcredit | Biểu đồ Thị trường Giao ngay coinName",
              meta_name: {
                description:
                  "Mua coinName Trong Vài Giây | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch Topcredit, coinFullName giao ngay, biểu đồ xu hướng coinName, giá tiền kỹ thuật số",
                "twitter:title":
                  "Giao dịch coinFullName trên Sàn giao dịch Topcredit : Biểu đồ Thị trường Giao ngay coinName",
                "twitter:description":
                  "Mua coinName Trong Vài Giây : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
                "og:title":
                  "Giao dịch coinFullName trên Sàn giao dịch Topcredit : Biểu đồ Thị trường Giao ngay coinName",
                "og:description":
                  "Mua coinName Trong Vài Giây : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                "og:image:alt":
                  "Topcredit International | Mua, giao dịch và nắm giữ hàng trăm loại tiền điện tử-Topcredit International",
              },
            },
          },
        },
        {
          name: "Pilipino",
          value: "tl",
          valueFull: "tl_PH",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              meta_name: {
                description:
                  "Mangalakal ng BTC ETH DOGE USDT at mahigit pang 200 na Digital Currency | Spot - Margin 300X - OTC - Derivatives - Pag-stake | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "Topcredit Bank, Topcredit International, Topcredit, Metaverse, virtual currency, cryptocurrency, naka-encrypt na asset, blockchain, Crypto, digital currency, encrypted derivatives, encrypted derivatives exchange, exchange, digital currency trading platform, encryption Currency Exchange, Binance, Binance, Coinbase, Exchange , bybit, Kraken, KuCoin, OKX, Gate.io, Bitget, Pionex, Bingx, MEXC",
                "twitter:title":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "twitter:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            metaverse: {
              title:
                "Topcredit Metaverse | Tracker ng Merkado sa Crypto ng Metaverse",
              meta_name: {
                description:
                  "Pumasok sa Metaverse Zone | Maging Up to Date sa Pinakabagong Balita at mga Presyo sa Digital Currency na Metaverse",
                keywords:
                  "Topcredit Exchange, Pahina ng Metaverse, Merkado ng Metaverse, Palitan ng Metaverse",
                "twitter:title":
                  "Topcredit Metaverse Zone : Tracker ng Merkado sa Crypto ng Metaverse",
                "twitter:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Topcredit Metaverse Zone : Tracker ng Merkado sa Crypto ng Metaverse",
                "og:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | Kumita ng Crypto sa Mataas na APY% na Pag-stake",
              meta_name: {
                description:
                  "Mag-stake ng BTC ETH DOGE USDT at Marami pang Higiti na Digital Currency | Hanggang sa 22.5% Taunang Kita | Pag-stake ng DeFi at Available na Cloud Mining",
                keywords:
                  "Topcredit Earn, Crypto Staking, Metaverse Crypto Exchange, Digital Currrency Staking",
                "twitter:title":
                  "Topcredit Earn : Mataas na APY% na Pag-stake sa Topcredit Exchange",
                "twitter:description":
                  "Mag-stake ng USDT Bitcoin at ETH sa palitan ng crypto na binuo para sa Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Topcredit Earn : Mataas na APY% na Pag-stake sa Topcredit Exchange",
                "og:description":
                  "Mag-stake ng USDT Bitcoin at ETH sa palitan ng crypto na binuo para sa Metaverse",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | Tungkol sa Amin",
              meta_name: {
                description:
                  "Nangunguna ang Topcredit sa karera ng pagtukoy sa metaverse, nag-aalok kami ng patuloy na lumalagong seleksyon ng mga digital na token at mga produktong pampinansyal upang makapag-invest nang may kapayapaan ng isip ang aming mga gumagamit",
                keywords:
                  "Topcredit Exchange, pahina ng tungkol sa amin, Palitan ng Metaverse Cryptocurrency, Palitan ng Digital Currency",
                "twitter:title": "Topcredit Exchange : Tungkol sa Amin",
                "twitter:description":
                  "Nangunguna ang Topcredit sa karera ng pagtukoy sa metaverse, nag-aalok kami ng patuloy na lumalagong seleksyon ng mga digital na token at mga produktong pampinansyal upang makapag-invest nang may kapayapaan ng isip ang aming mga gumagamit",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title": "Topcredit Exchange : Tungkol sa Amin",
                "og:description":
                  "Nangunguna ang Topcredit sa karera ng pagtukoy sa metaverse, nag-aalok kami ng patuloy na lumalagong seleksyon ng mga digital na token at mga produktong pampinansyal upang makapag-invest nang may kapayapaan ng isip ang aming mga gumagamit",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | Kasunduan sa Paggamit",
              meta_name: {
                description: "Mga Tuntunin at Kondisyon",
                keywords:
                  "Topcredit Exchange, kasunduan sa serbisyo, mga tuntunin at kondisyon, Palitan ng Digital Currency",
                "twitter:title": "Topcredit Exchange : Kasunduan sa Paggamit",
                "twitter:description": "Mga Tuntunin at Kondisyon",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title": "Topcredit Exchange : Kasunduan sa Paggamit",
                "og:description": "Mga Tuntunin at Kondisyon",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | Mag-sign Up",
              meta_name: {
                description:
                  "Mangalakal ng BTC ETH DOGE USDT at mahigit pang 200 na Digital Currency | Spot - Margin 300X - OTC - Derivatives - Pag-stake | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "Topcredit Exchange, magrehistro, Mag-sign up, ilagay ang email address",
                "twitter:title": "Topcredit Exchange : Mag-sign Up",
                "twitter:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title": "Topcredit Exchange : Mag-sign Up",
                "og:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | Mag-log In",
              meta_name: {
                description:
                  "Mangalakal ng BTC ETH DOGE USDT at mahigit pang 200 na Digital Currency | Spot - Margin 300X - OTC - Derivatives - Pag-stake | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "Topcredit Exchange, Log in, ilagay ang email, ilagay ang password",
                "twitter:title": "Topcredit Exchange : Mag-log In",
                "twitter:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title": "Topcredit Exchange : Mag-log In",
                "og:description":
                  "Topcredit International ay isang one-stop na digital asset management platform para sa pamumuhunan sa Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) at iba pang cryptocurrencies.",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | I-reset ang Password ng Pag-log In",
              meta_name: {
                description:
                  "Matapos I-reset ang Password ng Pag-log In, Hindi Pinahihintulutan ang Pag-withdraw ng 24 Oras",
                keywords:
                  "Topcredit Exchange, Reset Password, Forgot Password, Palitan ng Digital Currency",
                "twitter:title":
                  "Topcredit Exchange : I-reset ang Password ng Pag-log In",
                "twitter:description":
                  "Matapos I-reset ang Password ng Pag-log In, Hindi Pinahihintulutan ang Pag-withdraw ng 24 Oras",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Topcredit Exchange : I-reset ang Password ng Pag-log In",
                "og:description":
                  "Matapos I-reset ang Password ng Pag-log In, Hindi Pinahihintulutan ang Pag-withdraw ng 24 Oras",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | Patakaran sa Pagkapribado",
              meta_name: {
                description: "Topcredit Exchange Patakaran sa Pagkapribado",
                keywords:
                  "Topcredit Exchange, Patakaran sa Pagkapribado, kaligtasan ng impormasyon, Palitan ng Digital Currency",
                "twitter:title":
                  "Topcredit Exchange : Patakaran sa Pagkapribado",
                "twitter:description":
                  "Kaligtasan ng Impormasyon at Abiso ng Pagkapribado",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title": "Topcredit Exchange : Patakaran sa Pagkapribado",
                "og:description":
                  "Kaligtasan ng Impormasyon at Abiso ng Pagkapribado",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Mangalakal ng coinFullName Futures sa Topcredit Exchange | coinName/USDT Walang Katapusang Futures",
              meta_name: {
                description:
                  "Mangalakal ng coinName USDT Futures hanggang 200X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "Topcredit Exchange, Futures ng coinFullName USDT, Futures ng Crypto, Walang Katapusang Futures ng coinName",
                "twitter:title":
                  "Mangalakal ng coinFullName Futures sa Topcredit Exchange : coinName/USDT Walang Katapusang Futures",
                "twitter:description":
                  "Mangalakal ng coinName USDT Futures hanggang 200X Margin : Sumali sa Palitan ng Crypto sa Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Mangalakal ng coinFullName Futures sa Topcredit Exchange : coinName/USDT Walang Katapusang Futures",
                "og:description":
                  "Mangalakal ng coinName USDT Futures hanggang 200X Margin : Sumali sa Palitan ng Crypto sa Metaverse Zone",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            delivery: {
              title:
                "Mangalakal ng coinFullName Futures sa Topcredit Exchange | Naka-margin na Futures ng coinName",
              meta_name: {
                description:
                  "Mangalakal ng Naka-margin na Futures ng coinName hanggang 100X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "Topcredit Exchange, Futures ng coinName USDT, Futures ng coinFullName, Walang Katapusang Futures",
                "twitter:title":
                  "Mangalakal ng coinFullName Futures sa Topcredit Exchange : Naka-margin na Futures ng coinName",
                "twitter:description":
                  "Mangalakal ng Naka-margin na Futures ng coinName hanggang 100X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Mangalakal ng coinFullName Futures sa Topcredit Exchange : Naka-margin na Futures ng coinName",
                "og:description":
                  "Mangalakal ng Naka-margin na Futures ng coinName hanggang 100X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
            exchange: {
              title:
                "Mangalakal ng coinFullName sa Topcredit Exchange | Tsart ng coinName Spot Market",
              meta_name: {
                description:
                  "Bumili ng coinName Sa Ilang Segundo | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "Topcredit Exchange, Spot ng coinFullName, Tsart ng coinName, presyo ng digital currency",
                "twitter:title":
                  "Bumili ng coinName Sa Ilang Segundo : Tsart ng coinName Spot Market",
                "twitter:description":
                  "Bumili ng coinName Sa Ilang Segundo : Sumali sa Palitan ng Crypto sa Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
                "og:title":
                  "Bumili ng coinName Sa Ilang Segundo : Tsart ng coinName Spot Market",
                "og:description":
                  "Bumili ng coinName Sa Ilang Segundo : Sumali sa Palitan ng Crypto sa Metaverse Zone",
                "og:image:alt":
                  "Topcredit International | Bumili, Magkalakal at Maghawak ng Daan-daang Cryptocurrencies-Topcredit International",
              },
            },
          },
        },
        {
          name: "ภาษาไทย",
          value: "th",
          valueFull: "th_TH",
          seoMsg: {
            index: {
              title:
                "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              meta_name: {
                description:
                  "เทรด BTC ETH DOGE USDT และสกุลเงินดิจิทัลอีกกว่า 200 สกุลเงิน | การเทรดแบบทันที - แบบมีหลักประกัน 300 เท่า - OTC - อนุพันธ์ - การเดิมพัน | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "Topcredit Bank, Topcredit International, Topcredit, Metaverse, สกุลเงินเสมือน, สกุลเงินดิจิทัล, สินทรัพย์ที่เข้ารหัส, blockchain, Crypto, สกุลเงินดิจิทัล, อนุพันธ์ที่เข้ารหัส, การแลกเปลี่ยนอนุพันธ์ที่เข้ารหัส, การแลกเปลี่ยน, แพลตฟอร์มการซื้อขายสกุลเงินดิจิทัล, การเข้ารหัสการแลกเปลี่ยนสกุลเงิน, Binance, Binance, Coinbase, Exchange , บายบิต, คราเคน, KuCoin, OKX, Gate.io, Bitget, Pionex, Bingx, MEXC",
                "twitter:title":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "twitter:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            metaverse: {
              title:
                "Topcredit Metaverse | เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส",
              meta_name: {
                description:
                  "เข้าสู่เขตเมตาเวิร์ส | เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส | เท่าทันข่าวสารและราคาสกุลเงินดิจิทัลล่าสุดในโลกเมตาเวิร์ส",
                keywords:
                  "Topcredit Exchange, หน้าที่มีข้อมูลเกี่ยวกับเมตาเวิร์ส, ตลาดเมตาเวิร์ส, แพลตฟอร์มซื้อขายในโลกเมตาเวิร์ส",
                "twitter:title":
                  "Topcredit Metaverse Zone : เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส",
                "twitter:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "Topcredit Metaverse Zone : เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส",
                "og:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Earn | ทำกำไรจากคริปโตด้วยการเดิมพันที่มี %APY สูง",
              meta_name: {
                description:
                  "เดิมพันกับ BTC ETH DOGE USDT และสกุลเงินดิจิทัลอื่นๆ อีกมากมาย| รับผลตอบแทนรายปีสูงสุดถึง 22.5% | มีบริการเดิมพัน DeFi และขุดคริปโตบนคลาวด์",
                keywords:
                  "Topcredit Earn, การเดิมพันกับคริปโต, แพลตฟอร์มซื้อขายคริปโตในโลกเมตาเวิร์ส, การเดินพันสกุลเงินดิจิทัล",
                "twitter:title":
                  "Topcredit Earn : การเดิมพันที่มี %APY สูงบน Topcredit Exchange",
                "twitter:description":
                  "เดิมพันบิตคอยน์ในสกุลเงิน USDT และ ETH ในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "Topcredit Earn : ทำกำไรกับ Topcredit: การเดิมพันที่มี %APY สูงบน Topcredit Exchange",
                "og:description":
                  "เดิมพันบิตคอยน์ในสกุลเงิน USDT และ ETH ในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | เกี่ยวกับเรา",
              meta_name: {
                description:
                  "Topcredit เป็นผู้นำการแข่งขันเพื่อนิยามโลกเมตาเวิร์ส เรานำเสนอโทเค็นดิจิทัลที่คัดสรรและผลิตภัณฑ์ทางการเงินที่เพิ่มมากขึ้นเรื่อยๆ เพื่อให้ผู้ใช้สามารถลงทุนได้อย่างสบายใจ",
                keywords:
                  "Topcredit Exchange, หน้าเกี่ยวกับเรา, แพลตฟอร์มซื้อขายเงินตราเข้ารหัสลับในโลกเมตาเวิร์ส, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title": "Topcredit Exchange : เกี่ยวกับเรา",
                "twitter:description":
                  "Topcredit เป็นผู้นำการแข่งขันเพื่อนิยามโลกเมตาเวิร์ส เรานำเสนอโทเค็นดิจิทัลที่คัดสรรและผลิตภัณฑ์ทางการเงินที่เพิ่มมากขึ้นเรื่อยๆ เพื่อให้ผู้ใช้สามารถลงทุนได้อย่างสบายใจ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title": "Topcredit Exchange : เกี่ยวกับเรา",
                "og:description":
                  "Topcredit เป็นผู้นำการแข่งขันเพื่อนิยามโลกเมตาเวิร์ส เรานำเสนอโทเค็นดิจิทัลที่คัดสรรและผลิตภัณฑ์ทางการเงินที่เพิ่มมากขึ้นเรื่อยๆ เพื่อให้ผู้ใช้สามารถลงทุนได้อย่างสบายใจ",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | สัญญาบริการ",
              meta_name: {
                description: "ข้อกำหนดและเงื่อนไข",
                keywords:
                  "Topcredit Exchange, สัญญาบริการ, ข้อกำหนดและเงื่อนไข, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title": "Topcredit Exchange : สัญญาบริการ",
                "twitter:description": "ข้อกำหนดและเงื่อนไข",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title": "Topcredit Exchange : สัญญาบริการ",
                "og:description": "ข้อกำหนดและเงื่อนไข",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | สมัคร",
              meta_name: {
                description:
                  "เทรด BTC ETH DOGE USDT และสกุลเงินดิจิทัลอีกกว่า 200 สกุลเงิน | การเทรดแบบทันที - แบบมีหลักประกัน 300 เท่า - OTC - อนุพันธ์ - การเดิมพัน | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "Topcredit Exchange, ลงทะเบียน, สมัคร, กรอกที่อยู่อีเมล",
                "twitter:title": "Topcredit Exchange : สมัคร",
                "twitter:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title": "Topcredit Exchange : สมัคร",
                "og:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | เข้าสู่ระบบ",
              meta_name: {
                description:
                  "เทรด BTC ETH DOGE USDT และสกุลเงินดิจิทัลอีกกว่า 200 สกุลเงิน | การเทรดแบบทันที - แบบมีหลักประกัน 300 เท่า - OTC - อนุพันธ์ - การเดิมพัน | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "Topcredit Exchange, เข้าสู่ระบบ, กรอกอีเมล, กรอกรหัสผ่าน",
                "twitter:title": "Topcredit Exchange : เข้าสู่ระบบ",
                "twitter:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title": "Topcredit Exchange : เข้าสู่ระบบ",
                "og:description":
                  "Topcredit International เป็นแพลตฟอร์มการจัดการสินทรัพย์ดิจิทัลแบบครบวงจรสำหรับการลงทุนใน Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) และสกุลเงินดิจิทัลอื่น ๆ",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | รีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ",
              meta_name: {
                description:
                  "หลังจากรีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ คุณจะไม่สามารถถอนเงินได้อีก 24 ชั่วโมง",
                keywords:
                  "Topcredit Exchange, รีเซ็ตรหัสผ่าน, ลืมรหัสผ่าน,, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title":
                  "Topcredit Exchange : รีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ",
                "twitter:description":
                  "หลังจากรีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ คุณจะไม่สามารถถอนเงินได้อีก 24 ชั่วโมง",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "Topcredit Exchange : รีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ",
                "og:description":
                  "หลังจากรีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ คุณจะไม่สามารถถอนเงินได้อีก 24 ชั่วโมง",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | นโยบายความเป็นส่วนตัว",
              meta_name: {
                description: "Topcredit Exchange นโยบายความเป็นส่วนตัว",
                keywords:
                  "Topcredit Exchange, นโยบายความเป็นส่วนตัว, ความปลอดภัยของข้อมูล, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title": "Topcredit Exchange : นโยบายความเป็นส่วนตัว",
                "twitter:description":
                  "ความปลอดภัยของข้อมูลและประกาศความเป็นส่วนตัว",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title": "Topcredit Exchange : นโยบายความเป็นส่วนตัว",
                "og:description":
                  "ความปลอดภัยของข้อมูลและประกาศความเป็นส่วนตัว",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "ซื้อขาย coinFullName ฟิวเจอร์สบน Topcredit Exchange | สัญญาซื้อขาย coinName/USDT ล่วงหน้าแบบไม่มีกำหนดเวลา",
              meta_name: {
                description:
                  "เทรด coinName USDT แบบฟิวเจอร์ส โดยใช้หลักประกันสูงถึง 200x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "Topcredit Exchange, USDT coinFullName ฟิวเจอร์ส, ฟิวเจอร์สของคริปโต, สัญญาซื้อขาย coinName ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "twitter:title":
                  "ซื้อขาย coinFullName ฟิวเจอร์สบน Topcredit Exchange : สัญญาซื้อขาย coinName/USDT ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "twitter:description":
                  "เทรด coinName USDT แบบฟิวเจอร์ส โดยใช้หลักประกันสูงถึง 200x เท่า : เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "ซื้อขาย coinFullName ฟิวเจอร์สบน Topcredit Exchange : สัญญาซื้อขาย coinName/USDT ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "og:description":
                  "เทรด coinName USDT แบบฟิวเจอร์ส โดยใช้หลักประกันสูงถึง 200x เท่า : เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            delivery: {
              title:
                "เทรดบิตคอย coinFullName วเจอร์สบน Topcredit Exchange | coinName ฟิวเจอร์สแบบมีหลักประกันของ coinName",
              meta_name: {
                description:
                  "เทรด coinName ฟิวเจอร์สแบบมีหลักประกันสูงถึง 100x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "Topcredit Exchange, ฟิวเจอ coinName ตคอยน์ในสกุลเงิน USDT, coinFullName สของบิตคอยน์, สัญญาซื้อขาย ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "twitter:title":
                  "เทรดบิตคอย coinFullName วเจอร์สบน Topcredit Exchange | coinName ฟิวเจอร์สแบบมีหลักประกันของ coinName",
                "twitter:description":
                  "เทรด coinName ฟิวเจอร์สแบบมีหลักประกันสูงถึง 100x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "เทรดบิตคอย coinFullName วเจอร์สบน Topcredit Exchange | coinName ฟิวเจอร์สแบบมีหลักประกันของ coinName",
                "og:description":
                  "เทรด coinName ฟิวเจอร์สแบบมีหลักประกันสูงถึง 100x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
            exchange: {
              title:
                "เทรด coinFullName บน Topcredit Exchange | แผนภูมิตลาดซื้อขาย coinName ทันท",
              meta_name: {
                description:
                  "ซื้อ coinName ได้ภายในไม่กี่วินาที | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "Topcredit Exchange, การซื้อขาย coinFullName แบบทันที, แผนภูมิ coinName, ราคาสกุลเงินดิจิทัล",
                "twitter:title":
                  "เทรด coinFullName บน Topcredit Exchange | แผนภูมิตลาดซื้อขาย coinName ทันท",
                "twitter:description":
                  "ซื้อ coinName ได้ภายในไม่กี่วินาที | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
                "og:title":
                  "เทรด coinFullName บน Topcredit Exchange | แผนภูมิตลาดซื้อขาย coinName ทันท",
                "og:description":
                  "ซื้อ coinName ได้ภายในไม่กี่วินาที | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                "og:image:alt":
                  "Topcredit International | ซื้อ แลกเปลี่ยน และถือครอง Cryptocurrencies หลายร้อยรายการ-Topcredit International",
              },
            },
          },
        },
        {
          name: "Türk",
          value: "tr",
          valueFull: "tr_TR",
          seoMsg: {
            index: {
              title:
                "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve 200'den fazla Dijital Para Biriminde yatırım yapın | Spot - 300 Kat Marj - OTC - Türevler - Staking | Metaverse Alanıyla Kripto Borsasına Katılın",
                keywords:
                  "Topcredit bank,Topcredit international,topcredit,metaverse,virtual currency,cryptocurrency,crypto asset,blockchain,crypto,digital currency,crypto derivatives,crypto derivatives exchange,exchange,digital currency trading platform,cryptocurrency exchange,coinan,binance,coinbase,exchange,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "twitter:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit Metaverse | Metaverse Kripto Piyasası Takibi",
              meta_name: {
                description:
                  "Metaverse Alanına Girin | Metaverse Kripto Piyasası Takibi | En Güncel Metaverse Dijital Para Haberleri ve Fiyatlarından Haberdar Olun",
                keywords:
                  "Topcredit Borsası, Metaverse Sayfası, Metaverse Market, Metaverse Borsası",
                "twitter:title":
                  "Topcredit Metaverse Alanı : Metaverse Kripto İzleyici",
                "twitter:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title":
                  "Topcredit Metaverse Alanı : Metaverse Kripto İzleyici",
                "og:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            finances: {
              title:
                "Topcredit Kazan | Yüksek APY% Staking ile Kripto Para Kazanın",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve Daha Birçok Dijital Parayı Stake Edin | Yıllık %22,5'e Varan Getiri | DeFi Staking ve Bulut Madenciliği Mevcut",
                keywords:
                  "Topcredit Earn, Kripto Staking, Metaverse Kripto Borsası, Dijital Para Staking",
                "twitter:title":
                  "Topcredit Kazan : Topcredit Borsasında Yüksek APY% Staking",
                "twitter:description":
                  "Metaverse için oluşturulan kripto borsasında USDT Bitcoin ve ETH stake edin",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title":
                  "Topcredit Kazan : Topcredit Borsasında Yüksek APY% Staking",
                "og:description":
                  "Metaverse için oluşturulan kripto borsasında USDT Bitcoin ve ETH stake edin",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Borsası | Hakkımızda",
              meta_name: {
                description:
                  "Topcredit, metaverse'i tanımlama yarışına liderlik ediyor, kullanıcılarımızın gönül rahatlığıyla yatırım yapabilmeleri için sürekli büyüyen bir dijital token ve finansal ürün yelpazesi sunuyoruz",
                keywords:
                  "Topcredit Borsası, hakkımızda sayfası, Metaverse Kripto Para Borsası, Dijital Para Borsası",
                "twitter:title": "Topcredit Borsası : Hakkımızda",
                "twitter:description":
                  "Topcredit, metaverse'i tanımlama yarışına liderlik ediyor, kullanıcılarımızın gönül rahatlığıyla yatırım yapabilmeleri için sürekli büyüyen bir dijital token ve finansal ürün yelpazesi sunuyoruz",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title": "Topcredit Borsası : Hakkımızda",
                "og:description":
                  "Topcredit, metaverse'i tanımlama yarışına liderlik ediyor, kullanıcılarımızın gönül rahatlığıyla yatırım yapabilmeleri için sürekli büyüyen bir dijital token ve finansal ürün yelpazesi sunuyoruz",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Borsası | Hizmet Sözleşmesi",
              meta_name: {
                description: "Şartlar ve Koşullar",
                keywords:
                  "Topcredit Borsası, Hizmet Sözleşmesi, Şartlar ve Koşullar, Dijital Para Borsası",
                "twitter:title": "Topcredit Borsası : Hizmet Sözleşmesi",
                "twitter:description": "Şartlar ve Koşullar",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title": "Topcredit Borsası : Hizmet Sözleşmesi",
                "og:description": "Şartlar ve Koşullar",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            register: {
              title: "Topcredit Borsası | Kaydol",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve 200'den fazla Dijital Para Biriminde yatırım yapın | Spot - 300 Kat Marj - OTC - Türevler - Staking | Metaverse Alanıyla Kripto Borsasına Katılın",
                keywords:
                  "Topcredit Borsası, kayıt ol, Kaydol, e-posta adresini gir",
                "twitter:title": "Topcredit Borsası : Kaydol",
                "twitter:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title": "Topcredit Borsası : Kaydol",
                "og:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            login: {
              title: "Topcredit Borsası | Giriş Yap",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve 200'den fazla Dijital Para Biriminde yatırım yapın | Spot - 300 Kat Marj - OTC - Türevler - Staking | Metaverse Alanıyla Kripto Borsasına Katılın",
                keywords:
                  "Topcredit Borsası, Giriş Yap, e-posta gir, şifre gir",
                "twitter:title": "Topcredit Borsası : Giriş Yap",
                "twitter:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title": "Topcredit Borsası : Giriş Yap",
                "og:description":
                  "Topcredit International, Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) ve diğer kripto para birimlerine yatırım yapmak için tek noktadan dijital varlık yönetimi platformudur.",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Borsası | Giriş Şifresini Sıfırla",
              meta_name: {
                description:
                  "Oturum Açma Şifresi Sıfırlandıktan Sonra Para Çekme İşlemleri 24 Saat Süreyle Yasaklanır",
                keywords:
                  "Topcredit Borsası, Şifre Sıfırla, Şifremi Unuttum, Dijital Para Borsası",
                "twitter:title": "Topcredit Borsası : Giriş Şifresini Sıfırla",
                "twitter:description":
                  "Oturum Açma Şifresi Sıfırlandıktan Sonra Para Çekme İşlemleri 24 Saat Süreyle Yasaklanır",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title": "Topcredit Borsası : Giriş Şifresini Sıfırla",
                "og:description":
                  "Oturum Açma Şifresi Sıfırlandıktan Sonra Para Çekme İşlemleri 24 Saat Süreyle Yasaklanır",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Borsası | Gizlilik Politikası",
              meta_name: {
                description: "Topcredit Borsası Gizlilik Politikası",
                keywords:
                  "Topcredit Borsası, Gizlilik Politikası, bi̇lgi̇ güvenli̇ği̇, Dijital Para Borsası",
                "twitter:title": "Topcredit Borsası : Gizlilik Politikası",
                "twitter:description": "Bilgi Güvenliği ve Gizlilik Bildirimi",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title": "Topcredit Borsası : Gizlilik Politikası",
                "og:description": "Bilgi Güvenliği ve Gizlilik Bildirimi",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Topcredit Borsasında coinFullName Futures İşlemleri Yapın | coinName/USDT Kalıcı Futures İşlemleri",
              meta_name: {
                description:
                  "200 Kat Marja Kadar coinName USDT Futures İşlemleri Yapın | Metaverse Alanı ile Kripto Borsasına Katılın",
                keywords:
                  "Topcredit Borsası, coinFullName USDT Futures İşlemleri, Kripto Futures İşlemleri, coinName Kalıcı Futures İşlemleri",
                "twitter:title":
                  "Topcredit Borsasında coinFullName Futures İşlemleri Yapın : coinName/USDT Kalıcı Futures İşlemleri",
                "twitter:description":
                  "200 Kat Marja Kadar coinName USDT Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title":
                  "Topcredit Borsasında coinFullName Futures İşlemleri Yapın : coinName/USDT Kalıcı Futures İşlemleri",
                "og:description":
                  "200 Kat Marja Kadar coinName USDT Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            delivery: {
              title:
                "Topcredit Borsasında coinFullName Futures İşlemleri Yapın | coinName Marjlı Futures İşlemleri",
              meta_name: {
                description:
                  "100 Kat Marja Kadar coinName Marjlı Futures İşlemleri Yapın | Metaverse Alanı ile Kripto Borsasına Katılın",
                keywords:
                  "Topcredit Borsası, coinName USDT Futures İşlemleri, coinFullName Futures İşlemleri, Kalıcı Futures İşlemleri",
                "twitter:title":
                  "Topcredit Borsasında coinFullName Futures İşlemleri Yapın : coinName Marjlı Futures İşlemleri",
                "twitter:description":
                  "100 Kat Marja Kadar coinName Marjlı Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title":
                  "Topcredit Borsasında coinFullName Futures İşlemleri Yapın : coinName Marjlı Futures İşlemleri",
                "og:description":
                  "100 Kat Marja Kadar coinName Marjlı Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
            exchange: {
              title:
                "Topcredit Borsasında coinFullName İşlemleri Yapın | coinName Spot Piyasa Grafiği",
              meta_name: {
                description:
                  "Saniyeler İçinde coinName Satın Alın | Metaverse Alanı ile Kripto Borsasına Katılın",
                keywords:
                  "Topcredit Borsası, coinFullName spot, coinName grafiği, dijital para fiyatı",
                "twitter:title":
                  "Topcredit Borsasında coinFullName İşlemleri Yapın : coinName Spot Piyasa Grafiği",
                "twitter:description":
                  "Saniyeler İçinde coinName Satın Alın : Metaverse Alanı ile Kripto Borsasına Katılın",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
                "og:title":
                  "Topcredit Borsasında coinFullName İşlemleri Yapın : coinName Spot Piyasa Grafiği",
                "og:description":
                  "Saniyeler İçinde coinName Satın Alın : Metaverse Alanı ile Kripto Borsasına Katılın",
                "og:image:alt":
                  "Topcredit Bank International | Yüzlerce Kripto Para Birimi Satın Alın, Ticaretini Yapın ve Elinizde Tutun - Topcredit International",
              },
            },
          },
        },
        {
          name: "Italiano",
          value: "it",
          valueFull: "it_IT",
          seoMsg: {
            index: {
              title:
                "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              meta_name: {
                description:
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
                keywords:
                  "Topcredit bank,Topcredit international,topcredit,metaverse,moneta virtuale,criptovaluta,crypto asset,blockchain,crypto,moneta digitale,crypto derivati,crypto derivati exchange,exchange,piattaforma di trading di valuta digitale,cryptocurrency exchange,coinan,binance,coinbase,exchange,bybit. Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "twitter:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            metaverse: {
              title:
                "Metaverso Topcredit | Monitoraggio di Mercato delle Criptovalute del Metaverso",
              meta_name: {
                description:
                  "Entra nella zona del Metaverso | Resta Aggiornato Con le Ultime Novità e Prezzi della Valuta Digitale del Metaverso",
                keywords:
                  "Topcredit Exchange, Pagina del Metaverso, Mercato del Metaverso, Scambio del Metaverso",
                "twitter:title":
                  "Zona Topcredit del Metaverso: Monitoraggio Criptovalute del Metaverso",
                "twitter:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Zona Topcredit del Metaverso: Monitoraggio Criptovalute del Metaverso",
                "og:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            finances: {
              title:
                "Guadagno Topcredit | Guadagna Criptovalute con un Puntata ad Alto APY%",
              meta_name: {
                description:
                  "Puntare su BTC ETH DOGE USDT & Molte Altre Valute Digitali | Fino a 22.5% di Rendimento Annuale | Posizionamento DeFi e Cloud Mining Disponibile",
                keywords:
                  "Topcredit Earn, Puntata di Criptovalute, Scambio di Criptovalute del Metaverso, Puntata della Valuta Digitale",
                "twitter:title":
                  "Guadagno Topcredit: Puntata ad Alto APY% su Scambi Topcredit",
                "twitter:description":
                  "Puntare su USDT Bitcoin e ETH sullo scambio di criptovalute costruito per il Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Guadagno Topcredit: Puntata ad Alto APY% su Scambi Topcredit",
                "og:description":
                  "Puntare su USDT Bitcoin e ETH sullo scambio di criptovalute costruito per il Metaverso",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            aboutus: {
              title: "Topcredit Exchange | Su di noi",
              meta_name: {
                description:
                  "Topcredit sta guidando la corsa alla definizione del metaverso, offriamo una selezione sempre crescente di token digitali e prodotti finanziari in modo che i nostri utenti possano investire in tutta tranquillità",
                keywords:
                  "Topcredit Exchange, pagina su di noi, Borsa di Criptovaluta del Metaverso, Borsa di Valuta Digitale",
                "twitter:title": "Topcredit Exchange : Su di noi",
                "twitter:description":
                  "Topcredit sta guidando la corsa alla definizione del metaverso, offriamo una selezione sempre crescente di token digitali e prodotti finanziari in modo che i nostri utenti possano investire in tutta tranquillità",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title": "Topcredit Exchange : Su di noi",
                "og:description":
                  "Topcredit sta guidando la corsa alla definizione del metaverso, offriamo una selezione sempre crescente di token digitali e prodotti finanziari in modo che i nostri utenti possano investire in tutta tranquillità",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Topcredit Exchange | Contratto di Servizio",
              meta_name: {
                description: "Termini e Condizioni",
                keywords:
                  "Topcredit Exchange, Contratto di Servizio, Termini e Condizioni, Borsa di Valuta Digitale",
                "twitter:title": "Topcredit Exchange : Contratto di Servizio",
                "twitter:description": "Termini e Condizioni",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title": "Topcredit Exchange : Contratto di Servizio",
                "og:description": "Termini e Condizioni",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            register: {
              title: "Topcredit Exchange | Iscriviti",
              meta_name: {
                description:
                  "Scambia BTC ETH DOGE USDT & oltre 200 altre Valute Digitali | Posto - Margine 300X - OTC - Derivati - Puntata | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "Topcredit Exchange, registrazione, Iscriviti, inserisci l'indirizzo email",
                "twitter:title": "Topcredit Exchange : Iscriviti",
                "twitter:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title": "Topcredit Exchange : Iscriviti",
                "og:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            login: {
              title: "Topcredit Exchange | Accesso",
              meta_name: {
                description:
                  "Scambia BTC ETH DOGE USDT & oltre 200 altre Valute Digitali | Posto - Margine 300X - OTC - Derivati - Puntata | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "Topcredit Exchange, Accesso, inserisci l'email, inserisci la password",
                "twitter:title": "Topcredit Exchange : Accesso",
                "twitter:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title": "Topcredit Exchange : Accesso",
                "og:description":
                  "Topcredit International è una piattaforma unica di gestione degli asset digitali per investire in Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB) e altre criptovalute.",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            forgetPwd: {
              title: "Topcredit Exchange | Resetta la Password per l'Accesso",
              meta_name: {
                description:
                  "Dopo la Reimpostazione della Password di Accesso, i Prelievi sono Vietati per 24 Ore",
                keywords:
                  "Topcredit Exchange, Resetta la password, Ho Dimenticato la Password, Borsa di Valuta Digitale",
                "twitter:title":
                  "Topcredit Exchange : Resetta la Password per l'Accesso",
                "twitter:description":
                  "Dopo la Reimpostazione della Password di Accesso, i Prelievi sono Vietati per 24 Ore",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Topcredit Exchange : Resetta la Password per l'Accesso",
                "og:description":
                  "Dopo la Reimpostazione della Password di Accesso, i Prelievi sono Vietati per 24 Ore",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Topcredit Exchange | Informativa sulla Privacy",
              meta_name: {
                description: "Topcredit Exchange Informativa sulla Privacy",
                keywords:
                  "Topcredit Exchange, Informativa sulla Privacy, sicurezza della privacy, Borsa di Valuta Digitale",
                "twitter:title":
                  "Topcredit Exchange : Informativa sulla Privacy",
                "twitter:description":
                  "Avviso sulle Informazioni di Sicurezza e Privacy",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title": "Topcredit Exchange : Informativa sulla Privacy",
                "og:description":
                  "Avviso sulle Informazioni di Sicurezza e Privacy",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Negozia i Futures di coinFullName sullo Scambio Topcredit | Futures coinName/USDT Perpetui",
              meta_name: {
                description:
                  "Negozia i Futures di coinName USDT fino a un Margine di 200x | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "Topcredit Exchange, Futures di coinFullName USDT, Futures di Criptovalute, Futures di coinName Perpetui",
                "twitter:title":
                  "Negozia i Futures di coinFullName sullo Scambio Topcredit : Futures coinName/USDT Perpetui",
                "twitter:description":
                  "Negozia i Futures di coinName USDT fino a un Margine di 200x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Negozia i Futures di coinFullName sullo Scambio Topcredit : Futures coinName/USDT Perpetui",
                "og:description":
                  "Negozia i Futures di coinName USDT fino a un Margine di 200x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            delivery: {
              title:
                "Negozia futures di coinFullName sullo Scambio Topcredit | Futures coinName Marginati",
              meta_name: {
                description:
                  "Negozia Futures Marginati coinName fino a un Margine di 100x | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "Topcredit Exchange, Futures di coinName USDT, Futures di coinFullName, Futures Perpetui",
                "twitter:title":
                  "Negozia futures di coinFullName sullo Scambio Topcredit : Futures coinName Marginati",
                "twitter:description":
                  "Negozia Futures Marginati coinName fino a un Margine di 100x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Negozia futures di coinFullName sullo Scambio Topcredit : Futures coinName Marginati",
                "og:description":
                  "Negozia Futures Marginati coinName fino a un Margine di 100x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
            exchange: {
              title:
                "Negozia coinFullName sullo Scambio Topcredit | Grafico di Posizionamento nel Mercato coinName",
              meta_name: {
                description:
                  "Compra coinName In Secondi | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "Topcredit Exchange, Posizione coinFullName, Grafico coinName, prezzo valuta digitale",
                "twitter:title":
                  "Negozia coinFullName sullo Scambio Topcredit : Grafico di Posizionamento nel Mercato coinName",
                "twitter:description":
                  "Compra coinName In Secondi : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
                "og:title":
                  "Negozia coinFullName sullo Scambio Topcredit : Grafico di Posizionamento nel Mercato coinName",
                "og:description":
                  "Compra coinName In Secondi : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                "og:image:alt":
                  "Topcredit Bank International | Compra, scambia e detiene centinaia di criptovalute - Topcredit International",
              },
            },
          },
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          valueFull: "id_ID",
          seoMsg: {
            index: {
              title:
                "Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              meta_name: {
                description:
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
                keywords:
                  "Bank Topcredit  Topcredit internasional, topcredit, metaverse, mata uang virtual, mata uang kripto, aset kripto, blockchain, kripto, mata uang digital, turunan kripto, pertukaran turunan kripto, pertukaran, platform perdagangan mata uang digital, pertukaran mata uang kripto, coinan, binance, coinbase, pertukaran, bybit. Kraken, KuCoin, OKX, Gate.io, Bitget, Pionex, Bingx, MEXC",
                "twitter:title":
                  "Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "twitter:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title":
                  "Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
                "og:image:alt":
                  "Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            metaverse: {
              title: "Metaverse Topcredit | Pelacak Pasar Kripto Metaverse",
              meta_name: {
                description:
                  "Masuk ke Zona Metaverse | Simak Terus Berita dan Harga Mata Uang Digital Metaverse Terbaru",
                keywords:
                  "Bursa Topcredit, Halaman Metaverse, Pasar Metaverse, Bursa Metaverse",
                "twitter:title":
                  "Zona Metaverse Topcredit : Pelacak Kripto Metaverse",
                "twitter:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title":
                  "Zona Metaverse Topcredit : Pelacak Kripto Metaverse",
                "og:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            finances: {
              title:
                "Penghasilan Topcredit | Hasilkan Kripto dengan Pertaruhan APY% Tinggi",
              meta_name: {
                description:
                  "Pertaruhkan BTC ETH DOGE USDT & Berbagai Mata Uang Digital Lainnya | Hingga 22,5% Hasil Tahunan | Tersedia DeFi Pertaruhan dan Penambangan Cloud",
                keywords:
                  "Penghasilan Topcredit, Pertaruhan Kripto, Bursa Kripto Metaverse, Pertaruhan Mata Uang Digital",
                "twitter:title":
                  "Penghasilan Topcredit: Pertaruhan APY% tinggi di Bursa Topcredit",
                "twitter:description":
                  "Pertaruhkan Bitcoin USDT dan ETH di bursa kripto yang diciptakan untuk Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title":
                  "Penghasilan Topcredit: Pertaruhan APY% tinggi di Bursa Topcredit",
                "og:description":
                  "Pertaruhkan Bitcoin USDT dan ETH di bursa kripto yang diciptakan untuk Metaverse",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            aboutus: {
              title: "Bursa Topcredit | Tentang Kami",
              meta_name: {
                description:
                  "Topcredit adalah yang terdepan dalam menetapkan metaverse, kami menawarkan pilihan token digital dan produk keuangan yang terus berkembang sehingga pengguna kami selalu merasa aman saat berinvestasi",
                keywords:
                  "Bursa Topcredit, halaman tentang kami, Bursa Mata Uang Kripto Metaverse, Bursa Mata Uang Digital",
                "twitter:title": "Bursa Topcredit : Tentang Kami",
                "twitter:description":
                  "Topcredit adalah yang terdepan dalam menetapkan metaverse, kami menawarkan pilihan token digital dan produk keuangan yang terus berkembang sehingga pengguna kami selalu merasa aman saat berinvestasi",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title": "Bursa Topcredit : Tentang Kami",
                "og:description":
                  "Topcredit adalah yang terdepan dalam menetapkan metaverse, kami menawarkan pilihan token digital dan produk keuangan yang terus berkembang sehingga pengguna kami selalu merasa aman saat berinvestasi",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            "service-agreement": {
              title: "Bursa Topcredit | Perjanjian Layanan",
              meta_name: {
                description: "Syarat dan Ketentuan",
                keywords:
                  "Bursa Topcredit, Perjanjian Layanan, Syarat dan Ketentuan, Bursa Mata Uang Digital",
                "twitter:title": "Bursa Topcredit : Perjanjian Layanan",
                "twitter:description": "Syarat dan Ketentuan",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title": "Bursa Topcredit : Perjanjian Layanan",
                "og:description": "Syarat dan Ketentuan",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            register: {
              title: "Bursa Topcredit | Daftar",
              meta_name: {
                description:
                  "Perdagangkan BTC ETH DOGE USDT & lebih dari 200 Mata Uang Digital lainnya | Spot - Margin 300X - OTC - Derivatif - Pertaruhan | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa Topcredit, register, Daftar, masukkan alamat email",
                "twitter:title": "Bursa Topcredit : Daftar",
                "twitter:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title": "Bursa Topcredit : Daftar",
                "og:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            login: {
              title: "Bursa Topcredit | Masuk",
              meta_name: {
                description:
                  "Perdagangkan BTC ETH DOGE USDT & lebih dari 200 Mata Uang Digital lainnya | Spot - Margin 300X - OTC - Derivatif - Pertaruhan | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa Topcredit, Masuk, masukkan email, masukkan kata sandi",
                "twitter:title": "Bursa Topcredit : Masuk",
                "twitter:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title": "Bursa Topcredit : Masuk",
                "og:description":
                  "Topcredit  International adalah platform manajemen aset digital satu atap untuk berinvestasi dalam Bitcoin (BTC), Ethereum (ETH), USDT, Shiba Inu (SHIB), dan mata uang kripto lainnya.",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            forgetPwd: {
              title: "Bursa Topcredit | Atur Ulang Kata Sandi Masuk",
              meta_name: {
                description:
                  "Setelah Mengatur Ulang Kata Sandi Masuk, Penarikan Tidak Bisa Dilakukan Selama 24 Jam",
                keywords:
                  "Bursa Topcredit, Reset Password, Forgot Password, Bursa Mata Uang Digital",
                "twitter:title":
                  "Bursa Topcredit : Atur Ulang Kata Sandi Masuk",
                "twitter:description":
                  "Setelah Mengatur Ulang Kata Sandi Masuk, Penarikan Tidak Bisa Dilakukan Selama 24 Jam",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title": "Bursa Topcredit : Atur Ulang Kata Sandi Masuk",
                "og:description":
                  "Setelah Mengatur Ulang Kata Sandi Masuk, Penarikan Tidak Bisa Dilakukan Selama 24 Jam",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            "privacy-policy": {
              title: "Bursa Topcredit | Kebijakan Privasi",
              meta_name: {
                description: "Bursa Topcredit Kebijakan Privasi",
                keywords:
                  "Bursa Topcredit, Kebijakan Privasi, keamanan informasi, Bursa Mata Uang Digital",
                "twitter:title": "Bursa Topcredit : Kebijakan Privasi",
                "twitter:description":
                  "Keamanan Informasi dan Pemberitahuan Privasi",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title": "Bursa Topcredit : Kebijakan Privasi",
                "og:description":
                  "Keamanan Informasi dan Pemberitahuan Privasi",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            "contract-exchange": {
              title:
                "Perdagangkan Kontrak Berjangka coinFullName di Bursa Topcredit | Kontrak Berjangka Perpetual coinName/USDT",
              meta_name: {
                description:
                  "Perdagangkan Kontrak Berjangka coinName USDT hingga 200X Margin | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa Topcredit, Kontrak Berjangka USDT coinFullName, Kontrak Berjangka Kripto, Kontrak Berjangka Perpetual coinName",
                "twitter:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa Topcredit : Kontrak Berjangka Perpetual coinName/USDT",
                "twitter:description":
                  "Perdagangkan Kontrak Berjangka coinName USDT hingga 200X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa Topcredit : Kontrak Berjangka Perpetual coinName/USDT",
                "og:description":
                  "Perdagangkan Kontrak Berjangka coinName USDT hingga 200X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            delivery: {
              title:
                "Perdagangkan Kontrak Berjangka coinFullName di Bursa Topcredit | Kontrak Berjangka Bermargin coinName",
              meta_name: {
                description:
                  "Perdagangkan Kontrak Berjangka Bermargin coinName hingga 100X Margin | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa Topcredit, Kontrak Berjangka coinName USDT, Kontrak Berjangka coinFullName, Kontrak Berjangka Perpetual",
                "twitter:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa Topcredit : Kontrak Berjangka Bermargin coinName",
                "twitter:description":
                  "Perdagangkan Kontrak Berjangka Bermargin coinName hingga 100X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa Topcredit : Kontrak Berjangka Bermargin coinName",
                "og:description":
                  "Perdagangkan Kontrak Berjangka Bermargin coinName hingga 100X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
            exchange: {
              title:
                "Perdagangkan coinFullName di Bursa Topcredit | Bagan Pasar coinName",
              meta_name: {
                description:
                  "Beli coinName Dalam Hitungan Detik | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa Topcredit, Spot coinFullName, bagan coinName, harga mata uang digital",
                "twitter:title":
                  "Perdagangkan coinFullName di Bursa Topcredit : Bagan Pasar coinName",
                "twitter:description":
                  "Beli coinName Dalam Hitungan Detik : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
                "og:title":
                  "Perdagangkan coinFullName di Bursa Topcredit : Bagan Pasar coinName",
                "og:description":
                  "Beli coinName Dalam Hitungan Detik : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                "og:image:alt":
                  " Topcredit International | Beli, Perdagangkan, dan Pegang Ratusan Mata Uang Kripto - Topcredit International",
              },
            },
          },
        },
        {
          name: "عربي",
          value: "ar",
          valueFull: "ar_AR",
          seoMsg: {
            index: {
              title:
                "Topcredit International | شراء وتداول وامتلاك مئات العملات المشفرة - Topcredit International",
              // title: "Topcredit | ﻦﻣﺁ ﻞﻜﺸﺑ ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻠﻤﻋﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ  ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | (ﻝاﻮﻣﻷﻟ) ﻝﺎﻔﻗﻹا ﻭﺃ ﺖﻴﺒﺜﺘﻟا - ﺔﻘﺘﺸﻤﻟا ﺕاﻭﺩﻷا - (OTC) ﺔﺻﺭﻮﻟا ﺝﺭﺎﺧ ﻝﻭاﺪﺘﻟا - 300X ﺶﻣﺎﻫ - ﻱﺭﻮﻔﻟا ﻝﻭاﺪﺘﻟا | ﻯﺮﺧﺃ ﺔﻴﻤﻗﺭ ﺔﻠﻤﻋ 200 ﻦﻣ ﺮﺜﻛﺃﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "Topcredit Bank ، Topcredit International ، Topcredit ، Metaverse ، العملة الافتراضية ، cryptocurrency ، الأصول المشفرة ، blockchain ، التشفير ، العملات الرقمية ، المشتقات المشفرة ، تبادل المشتقات المشفرة ، التبادل ، منصة تداول العملات الرقمية ، تشفير صرف العملات ، Binance ، Binance ، Coinbase ، Exchange ، bybit، Kraken، KuCoin، OKX، Gate.io، Bitget، Pionex، Bingx، MEXC",
                "twitter:title":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                // "twitter:title": "Topcredit: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ  ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                // "Topcredit: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ ﺔﺻﺭﻮﺑ" ,
                "og:title":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                // "og:title": "Topcredit: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ  ﺔﺻﺭﻮﺑ",
                "og:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                // "og:image:alt": "Topcredit: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ  ﺔﺻﺭﻮﺑ" ,
              },
            },
            metaverse: {
              title:
                "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻕﻮﺳ ﻊﺒﺘﺗ ﺓاﺩﺃ | Topcredit ﺱﺮﻴﻓﺎﺘﻴﻣ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺭﺎﻌﺳﺃﻭ ﺭﺎﺒﺧﺃ ﺙﺪﺣﺄﺑ ﻉﻼﻃا ﻰﻠﻋ ﻖﺑا | ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻞﺧﺩا",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﺤﻔﺻ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻕﻮﺳ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻊﺒﺘﺗ ﺓاﺩﺃ : Topcredit ﺱﺮﻴﻓﺎﺘﻴﻣ ﺔﻘﻄﻨﻣ",
                "twitter:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻊﺒﺘﺗ ﺓاﺩﺃ : Topcredit ﺱﺮﻴﻓﺎﺘﻴﻣ ﺔﻘﻄﻨﻣ",
                "og:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            finances: {
              title:
                "ﺔﻌﻔﺗﺮﻣ (%) ﺔﻳﻮﻨﺳ ﺪﺋﺎﻋ ﺔﺒﺴﻨﺑ ﻝاﻮﻣﻷا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ ﻊﻣ ﺓﺮﻔﺸﻣ ﺕﻼﻤﻋ ﺢﺑﺭا | Topcredit ﺢﺑﺭ",
              meta_name: {
                description:
                  ".ﻲﺑﺎﺤﺴﻟا ﻦﻳﺪﻌﺘﻟاﻭ ﻱﺰﻛﺮﻣﻻﻟا ﻞﻳﻮﻤﺘﻟا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ ﺮﻓﻮﺘﻳ | %22.5 ﻰﻟﺇ ﻞﺼﺗ ﺔﺒﺴﻨﺑ ﻱﻮﻨﺳ ﺪﺋﺎﻋ | ﻯﺮﺧﻷا ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﻦﻣ ﺎﻫﺮﻴﻏ ﺮﻴﺜﻜﻟاﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﺕﻼﻤﻋ ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
                keywords:
                  "Topcredit ﺢﺑﺭ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
                "twitter:title":
                  "Topcredit ﺔﺻﺭﻮﺑ ﻲﻓ ﺔﻌﻔﺗﺮﻣ (%) ﺔﻳﻮﻨﺳ ﺪﺋﺎﻋ ﺔﺒﺴﻨﺑ ﻝاﻮﻣﻷا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ : Topcredit ﺢﺑﺭ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺘﻴﺒﻟاﻭ ﻡﻮﻳﺮﺜﻳﻹا ﻝﻭاﺪﺗ ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title":
                  "Topcredit ﺔﺻﺭﻮﺑ ﻲﻓ ﺔﻌﻔﺗﺮﻣ (%) ﺔﻳﻮﻨﺳ ﺪﺋﺎﻋ ﺔﺒﺴﻨﺑ ﻝاﻮﻣﻷا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ : Topcredit ﺢﺑﺭ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺘﻴﺒﻟاﻭ ﻡﻮﻳﺮﺜﻳﻹا ﻝﻭاﺪﺗ ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            aboutus: {
              title: "ﺎﻨﻋ ﺓﺬﺒﻧ | Topcredit ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﻝﺎﺑ ﺔﺣاﺭﻭ ﺔﻴﺤﻳﺭﺄﺑ ﺭﺎﻤﺜﺘﺳﻻا ﻦﻣ ﺎﻨﻳﺪﻟ ﻥﻮﻣﺪﺨﺘﺴﻤﻟا ﻦﻜﻤﺘﻳ ﻰﺘﺣ ﺔﻴﻟﺎﻤﻟا ﺕﺎﺠﺘﻨﻤﻟاﻭ ﺔﻴﻤﻗﺮﻟا ﺯﻮﻣﺮﻟا ﻦﻣ ﺭاﺮﻤﺘﺳﺎﺑ ﺓﺪﻳاﺰﺘﻣ ﺔﻋﻮﻤﺠﻣ ﻡﺪﻘﻧ ﻦﺤﻨﻓ ،ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺪﻳﺪﺤﺘﻟ ﻕﺎﺒﺴﻟا Topcredit ﺩﻮﻘﺗ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺔﻠﻤﻌﻟا ﺔﺻﺭﻮﺑ, ﺎﻨﻋ ﺓﺬﺒﻧ ﺔﺤﻔﺻ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺎﻨﻋ ﺓﺬﺒﻧ : Topcredit ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﻝﺎﺑ ﺔﺣاﺭﻭ ﺔﻴﺤﻳﺭﺄﺑ ﺭﺎﻤﺜﺘﺳﻻا ﻦﻣ ﺎﻨﻳﺪﻟ ﻥﻮﻣﺪﺨﺘﺴﻤﻟا ﻦﻜﻤﺘﻳ ﻰﺘﺣ ﺔﻴﻟﺎﻤﻟا ﺕﺎﺠﺘﻨﻤﻟاﻭ ﺔﻴﻤﻗﺮﻟا ﺯﻮﻣﺮﻟا ﻦﻣ ﺭاﺮﻤﺘﺳﺎﺑ ﺓﺪﻳاﺰﺘﻣ ﺔﻋﻮﻤﺠﻣ ﻡﺪﻘﻧ ﻦﺤﻨﻓ ،ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺪﻳﺪﺤﺘﻟ ﻕﺎﺒﺴﻟا Topcredit ﺩﻮﻘﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title": "ﺎﻨﻋ ﺓﺬﺒﻧ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﻝﺎﺑ ﺔﺣاﺭﻭ ﺔﻴﺤﻳﺭﺄﺑ ﺭﺎﻤﺜﺘﺳﻻا ﻦﻣ ﺎﻨﻳﺪﻟ ﻥﻮﻣﺪﺨﺘﺴﻤﻟا ﻦﻜﻤﺘﻳ ﻰﺘﺣ ﺔﻴﻟﺎﻤﻟا ﺕﺎﺠﺘﻨﻤﻟاﻭ ﺔﻴﻤﻗﺮﻟا ﺯﻮﻣﺮﻟا ﻦﻣ ﺭاﺮﻤﺘﺳﺎﺑ ﺓﺪﻳاﺰﺘﻣ ﺔﻋﻮﻤﺠﻣ ﻡﺪﻘﻧ ﻦﺤﻨﻓ ،ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺪﻳﺪﺤﺘﻟ ﻕﺎﺒﺴﻟا Topcredit ﺩﻮﻘﺗ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            "service-agreement": {
              title: "ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا | Topcredit ﺔﺻﺭﻮﺑ",
              meta_name: {
                description: "ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا, ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا : Topcredit ﺔﺻﺭﻮﺑ",
                "twitter:description": "ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title": "ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا : Topcredit ﺔﺻﺭﻮﺑ",
                "og:description": "ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            register: {
              title: "ﻙاﺮﺘﺷﻻا | Topcredit ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | (ﻝاﻮﻣﻷﻟ) ﻝﺎﻔﻗﻹا ﻭﺃ ﺖﻴﺒﺜﺘﻟا - ﺔﻘﺘﺸﻤﻟا ﺕاﻭﺩﻷا - (OTC) ﺔﺻﺭﻮﻟا ﺝﺭﺎﺧ ﻝﻭاﺪﺘﻟا - 300X ﺶﻣﺎﻫ - ﻱﺭﻮﻔﻟا ﻝﻭاﺪﺘﻟا | ﻯﺮﺧﺃ ﺔﻴﻤﻗﺭ ﺔﻠﻤﻋ 200 ﻦﻣ ﺮﺜﻛﺃﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺗﺴﺠﻴﻞ, ﻙاﺮﺘﺷا, ﻲﻧﻭﺮﺘﻜﻟﻹا ﺪﻳﺮﺒﻟا ﻥاﻮﻨﻋ ﻞﺧﺩﺃ",
                "twitter:title": "ﻙاﺮﺘﺷﻻا : Topcredit ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title": "ﻙاﺮﺘﺷﻻا : Topcredit ﺔﺻﺭﻮﺑ",
                "og:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            login: {
              title: "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ | Topcredit ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | (ﻝاﻮﻣﻷﻟ) ﻝﺎﻔﻗﻹا ﻭﺃ ﺖﻴﺒﺜﺘﻟا - ﺔﻘﺘﺸﻤﻟا ﺕاﻭﺩﻷا - (OTC) ﺔﺻﺭﻮﻟا ﺝﺭﺎﺧ ﻝﻭاﺪﺘﻟا - 300X ﺶﻣﺎﻫ - ﻱﺭﻮﻔﻟا ﻝﻭاﺪﺘﻟا | ﻯﺮﺧﺃ ﺔﻴﻤﻗﺭ ﺔﻠﻤﻋ 200 ﻦﻣ ﺮﺜﻛﺃﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ, ﻲﻧﻭﺮﺘﻜﻟﻹا ﺪﻳﺮﺒﻟا ﻞﺧﺩﺃ, ﺭﻭﺮﻤﻟا ﺔﻤﻠﻛ ﻞﺧﺩﺃ",
                "twitter:title": "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ : Topcredit ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title": "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:description":
                  "Topcredit International هي عبارة عن منصة واحدة لإدارة الأصول الرقمية للاستثمار في Bitcoin (BTC) و Ethereum (ETH) و USDT و Shiba Inu (SHIB) وغيرها من العملات المشفرة.",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            forgetPwd: {
              title: "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ | Topcredit ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺔﻋﺎﺳ 24 ﺓﺪﻤﻟ ﺐﺤﺴﻟا ﺮﻈﺤُﻳ ،ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ ﺪﻌﺑ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺭﻭﺮﻤﻟا ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ, ﺭﻭﺮﻤﻟا ﺔﻤﻠﻛ ﺖﻴﺴﻧ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title":
                  "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ : Topcredit ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﺔﻋﺎﺳ 24 ﺓﺪﻤﻟ ﺐﺤﺴﻟا ﺮﻈﺤُﻳ ،ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ ﺪﻌﺑ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﺔﻋﺎﺳ 24 ﺓﺪﻤﻟ ﺐﺤﺴﻟا ﺮﻈﺤُﻳ ،ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ ﺪﻌﺑ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            "privacy-policy": {
              title: "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ | Topcredit ﺔﺻﺭﻮﺑ",
              meta_name: {
                description: "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ Topcredit ﺔﺻﺭﻮﺑ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ, ﺕﺎﻣﻮﻠﻌﻤﻟا ﻦﻣﺃ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ : Topcredit ﺔﺻﺭﻮﺑ",
                "twitter:description": "ﺕﺎﻣﻮﻠﻌﻤﻟا ﻦﻣﺃﻭ ﺔﻴﺻﻮﺼﺨﻟا ﺭﺎﻌﺷﺇ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title": "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:description": "ﺕﺎﻣﻮﻠﻌﻤﻟا ﻦﻣﺃﻭ ﺔﻴﺻﻮﺼﺨﻟا ﺭﺎﻌﺷﺇ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            "contract-exchange": {
              title:
                "ﺮﺜﻴﺗ /coinName ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا | Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵاcoinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | 200X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ ﺮﺜﻴﺗﻭ coinFullName ـﻝ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا coinName ﺩﻮﻘﻋﻭ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻠﻟ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟاﻭ, ﺔﻠﺟﻵا USDT coinFullName ﺩﻮﻘﻋ",
                "twitter:title":
                  "ﺮﺜﻴﺗ / coinName ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 200X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ ﺮﺜﻴﺗﻭ coinFullName ـﻝ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﺮﺜﻴﺗ / coinName ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 200X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ ﺮﺜﻴﺗﻭ coinFullName ـﻝ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            delivery: {
              title:
                "coinName ﺶﻣﺎﻬﻟا ﺕاﺫ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا | Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵاcoinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | 100X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ coinName ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا, ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ, ﺔﻠﺟﻵا USDT coinName ﺩﻮﻘﻋ",
                "twitter:title":
                  "coinName ﺶﻣﺎﻬﻟا ﺕاﺫ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 100X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ coinName ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title":
                  "coinName ﺶﻣﺎﻬﻟا ﺕاﺫ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 100X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ coinName ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
            exchange: {
              title:
                "ﻱﺭﻮﻔﻟاcoinName ﻕﻮﺳ ﻂﻄﺨﻣ | Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋcoinFullName ﻝﻭاﺪﺗ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | ﺓﺩﻭﺪﻌﻣ ٍﻥاﻮﺛ ﻲﻓ coinName ءاﺮﺷ",
                keywords:
                  "Topcredit ﺔﺻﺭﻮﺑ, ﺔﻴﻤﻗﺮﻟا ﺔﻠﻤﻌﻟا ﺮﻌﺳ, coinName ﻂﻄﺨﻣ, ﻱﺭﻮﻔﻟا coinFullName ﻝﻭاﺪﺗ",
                "twitter:title":
                  "ﻱﺭﻮﻔﻟا coinName ﻕﻮﺳ ﻂﻄﺨﻣ : Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ coinFullName ﻝﻭاﺪﺗ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : ﺓﺩﻭﺪﻌﻣ ٍﻥاﻮﺛ ﻲﻓ coinName ءاﺮﺷ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﻱﺭﻮﻔﻟا coinName ﻕﻮﺳ ﻂﻄﺨﻣ : Topcredit ﺔﺻﺭﻮﺑ ﻰﻠﻋ coinFullName ﻝﻭاﺪﺗ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : ﺓﺩﻭﺪﻌﻣ ٍﻥاﻮﺛ ﻲﻓ coinName ءاﺮﺷ",
                "og:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : Topcredit ﺔﺻﺭﻮﺑ",
              },
            },
          },
        },
        {
          name: "中文简体",
          value: "zh_CN",
          valueFull: "zh_CN",
          seoMsg: {
            index: {
              title:
                "汇信国际 | 购买、交易和持有几百种加密货币-Topcredit International",
              meta_name: {
                description:
                  "汇信国际是一个一站式数字资产管理平台，用于投资比特币 (BTC)、以太坊 (ETH)、USDT、Shiba Inu (SHIB) 和其他加密货币。",
                keywords:
                  "汇信银行,汇信国际,Topcredit,元宇宙,虚拟货币,加密货币,加密资产,区块链,Crypto,数字货币,加密衍生品,加密衍生品交易所,交易所,数字货币交易平台,加密货币交易所,币安,Binance,Coinbase,Exchange,bybit,Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "汇信国际 | 购买、交易和持有几百种加密货币-Topcredit International",
                "twitter:description":
                  "汇信国际是一个一站式数字资产管理平台，用于投资比特币 (BTC)、以太坊 (ETH)、USDT、Shiba Inu (SHIB) 和其他加密货币。",
              },
              meta_property: {
                "twitter:image:alt":
                  "汇信国际 | 购买、交易和持有几百种加密货币-Topcredit International",
                "og:title": "Topcredit加密货币交易所｜全球首家元宇宙生态交易所",
                "og:description":
                  "汇信国际是一个一站式数字资产管理平台，用于投资比特币 (BTC)、以太坊 (ETH)、USDT、Shiba Inu (SHIB) 和其他加密货币。",
                "og:image:alt":
                  "汇信国际 | 购买、交易和持有几百种加密货币-Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
              meta_name: {
                description:
                  "Topcredit元宇宙生态区提供加密货币市场最实时、最齐全、最有价值的内容 | 让你快速了解 Metaverse 数字货币及元宇宙币的新闻和市场价格。",
                keywords:
                  "Topcredit加密货币交易所,元宇宙生态,元宇宙市场,元宇宙新闻",
                "twitter:title":
                  "Topcredit元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
                "twitter:description":
                  "Topcredit元宇宙生态区提供加密货币市场最实时、最齐全、最有价值的内容。",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
                "og:title":
                  "Topcredit元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
                "og:description":
                  "Topcredit元宇宙生态区提供加密货币市场最实时、最齐全、最有价值的内容。",
                "og:image:alt":
                  "Topcredit元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
              },
            },
            finances: {
              title: "Topcredit理财产品 | 每日速赚高年化率的定存、活存选择",
              meta_name: {
                description:
                  "小资族、保守型投资人的最爱！Topcredit理财产品提供多样化的投资选择，如BTC、ETH、DOGE、USDT及更多加密货币的定活存产品，每日领取高额利息，年化收益率最高 22.5%！质押、DeFi和云挖矿都适用。",
                keywords:
                  "Topcredit理财产品,加密货币质押,元宇宙币交易,定期储蓄存款,活存储蓄存款",
                "twitter:title":
                  "Topcredit理财产品 | 每日速赚高年化率的定存、活存选择",
                "twitter:description":
                  "Topcredit理财产品年化率最高22.5%！小资族、保守型投资人的最爱！",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit理财产品 | 每日速赚高年化率的定存、活存选择",
                "og:title":
                  "Topcredit理财产品 | 每日速赚高年化率的定存、活存选择",
                "og:description":
                  "Topcredit理财产品年化率最高22.5%！小资族、保守型投资人的最爱！",
                "og:image:alt":
                  "Topcredit理财产品 | 每日速赚高年化率的定存、活存选择",
              },
            },
            aboutus: {
              title: "Topcredit加密货币交易所 | 关于我们",
              meta_name: {
                description:
                  "自从比特币挖矿诞生以来，加密货币市场就一直在改变世界——而 Topcredit 正在领导这场竞赛。Topcredit致力于建构元宇宙生态，我们所新增的数字货币币种和金融产品选择都必须经过严格的审查，在Topcredit交易所裡用户可以安心投资！",
                keywords:
                  "Topcredit加密货币交易所,Topcredit理念,元宇宙币交易,数字货币交易",
                "twitter:title": "Topcredit加密货币交易所 | 关于我们",
                "twitter:description":
                  "Topcredit爲元宇宙交易所的领导品牌！我们致力于建构元宇宙生态，实时新增数字货币和金融产品，并必须经过严格的审查，在Topcredit交易所裡用户可以安心投资！",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 关于我们",
                "og:title": "Topcredit加密货币交易所 | 关于我们",
                "og:description":
                  "Topcredit爲元宇宙交易所的领导品牌！我们致力于建构元宇宙生态，实时新增数字货币和金融产品，并必须经过严格的审查，在Topcredit交易所裡用户可以安心投资！",
                "og:image:alt": "Topcredit加密货币交易所 | 关于我们",
              },
            },
            "service-agreement": {
              title: "Topcredit加密货币交易所 | 用户协议",
              meta_name: {
                description: "Topcredit详细服务条款与条件",
                keywords:
                  "Topcredit加密货币交易所,用户协议,服务条款,数位货币交易",
                "twitter:title": "Topcredit加密货币交易所 | 用户协议",
                "twitter:description": "Topcredit详细服务条款与条件",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 用户协议",
                "og:title": "Topcredit加密货币交易所 | 用户协议",
                "og:description": "Topcredit详细服务条款与条件",
                "og:image:alt": "Topcredit加密货币交易所 | 用户协议",
              },
            },
            register: {
              title: "Topcredit加密货币交易所 | 註册",
              meta_name: {
                description:
                  "立即註册Topcredit加密货币交易所｜Topcredit是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                keywords: "Topcredit加密货币交易所,註册,KYC,双重认证",
                "twitter:title": "Topcredit加密货币交易所 | 註册",
                "twitter:description":
                  "立即註册Topcredit加密货币交易所｜Topcredit是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 註册",
                "og:title": "Topcredit加密货币交易所 | 註册",
                "og:description":
                  "立即註册Topcredit加密货币交易所｜Topcredit是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                "og:image:alt": "Topcredit加密货币交易所 | 註册",
              },
            },
            login: {
              title: "Topcredit加密货币交易所 | 登入",
              meta_name: {
                description:
                  "Topcredit是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                keywords: "Topcredit加密货币交易所,登入,登入Email,登入密码",
                "twitter:title": "Topcredit加密货币交易所 | 登入",
                "twitter:description":
                  "Topcredit是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 登入",
                "og:title": "Topcredit加密货币交易所 | 登入",
                "og:description":
                  "Topcredit是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                "og:image:alt": "Topcredit加密货币交易所 | 登入",
              },
            },
            forgetPwd: {
              title: "Topcredit加密货币交易所 | 重置登入密码",
              meta_name: {
                description: "重置登入密码后，24小时内禁止充值提现。",
                keywords:
                  "Topcredit加密货币交易所,重置密码,忘记密码,数字货币交易",
                "twitter:title": "Topcredit加密货币交易所 | 重置登入密码",
                "twitter:description": "重置登入密码后，24小时内禁止充值提现。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 重置登入密码",
                "og:title": "Topcredit加密货币交易所 | 重置登入密码",
                "og:description": "重置登入密码后，24小时内禁止充值提现。",
                "og:image:alt": "Topcredit加密货币交易所 | 重置登入密码",
              },
            },
            "privacy-policy": {
              title: "Topcredit加密货币交易所 | 隐私协议",
              meta_name: {
                description:
                  "Topcredit加密货币交易所的隐私协议及用户数据、信息安全。",
                keywords:
                  "Topcredit加密货币交易所,隐私协议,用户数据安全,数字货币交易",
                "twitter:title": "Topcredit加密货币交易所 | 隐私协议",
                "twitter:description": "用户数据安全和隐私声明",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 隐私协议",
                "og:title": "Topcredit加密货币交易所 | 隐私协议",
                "og:description": "用户数据安全和隐私声明",
                "og:image:alt": "Topcredit加密货币交易所 | 隐私协议",
              },
            },
            //coinFullName coinName（大写） 需替换
            "contract-exchange": {
              title:
                "Topcredit加密货币交易所｜coinFullName U本位合约交易 | coinName/USDT永续合约",
              meta_name: {
                description:
                  "Topcredit加密货币交易所｜U本位交易 coinName/USDT合约高达 200倍保证金 | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                keywords:
                  "Topcredit加密货币交易所, coinFullName/USDT合约交易,加密货币合约交易, coinName永续合约",
                "twitter:title":
                  "Topcredit加密货币交易所｜coinFullName U本位合约交易 | coinName/USDT永续合约",
                "twitter:description":
                  "Topcredit加密货币交易所｜U本位交易 coinName/USDT合约高达 200倍保证金 | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | U本位合约交易",
                "og:title":
                  "Topcredit加密货币交易所｜coinFullName U本位合约交易 | coinName/USDT永续合约",
                "og:description":
                  "Topcredit加密货币交易所｜U本位交易 coinName/USDT合约高达 200倍保证金 | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                "og:image:alt": "Topcredit加密货币交易所 | U本位合约交易",
              },
            },
            //coinFullName coinName（大写） 需替换
            delivery: {
              title:
                "Topcredit加密货币交易所｜coinFullName币本位合约交易：coinName期货合约保证金",
              meta_name: {
                description:
                  "Topcredit加密货币交易所｜coinName币本位合约交易高达100倍保证金 | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                keywords:
                  "Topcredit加密货币交易所, coinName/USDT合约交易, coinFullName期货合约,永续合约",
                "twitter:title":
                  "Topcredit加密货币交易所｜coinFullName币本位合约交易： coinName期货合约保证金",
                "twitter:description":
                  "Topcredit加密货币交易所｜coinName币本位合约交易高达100倍保证金 | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所 | 币本位合约交易",
                "og:title":
                  "Topcredit加密货币交易所｜coinFullName币本位合约交易：coinName 期货合约保证金",
                "og:description":
                  "Topcredit加密货币交易所｜coinName币本位合约交易高达100倍保证金 | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                "og:image:alt": "Topcredit加密货币交易所 | 币本位合约交易",
              },
            },
            //coinFullName coinName（大写） 需替换
            exchange: {
              title:
                "coinFullName/USDT 现货市场走势 | 买入coinName | Topcredit加密货币交易所",
              meta_name: {
                description:
                  "买入coinName | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                keywords:
                  "Topcredit加密货币交易所, coinFullName现货, coinName走势图, coinName深度图, coinName-K线图",
                "twitter:title":
                  "coinFullName/USDT 现货市场走势 | 买入coinName | Topcredit加密货币交易所",
                "twitter:description":
                  "买入coinName | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密货币交易所｜现货市场走势图",
                "og:title":
                  "coinFullName/USDT 现货市场走势 | 买入coinName | Topcredit加密货币交易所",
                "og:description":
                  "买入coinName | 快加入Topcredit元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                "og:image:alt": "Topcredit加密货币交易所｜现货市场走势图",
              },
            },
          },
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          valueFull: "zh_HK",
          seoMsg: {
            index: {
              title:
                "匯信國際 | 購買、交易和持有幾百種加密貨幣-Topcredit International",
              meta_name: {
                description:
                  "Topcredit支援200種加密貨幣及元宇宙幣，如比特幣、以太幣、DOGE、USDT、Binance Coin（幣安幣）| 現貨、理財產品、合約、槓桿｜元宇宙入口就在Topcredit，提供穩定安全、高收益率的頂級服務。",
                keywords:
                  "匯信銀行,匯信國際,Topcredit,元宇宙,虛擬貨幣,加密貨幣,加密資產,區塊鏈,Crypto,數字貨幣,加密衍生品,加密衍生品交易所,交易所,數字貨幣交易平台,加密貨幣交易所,幣安,Binance,Coinbase,Exchange,bybit,Kraken,KuCoin,OKX,Gate.io,Bitget,Pionex,Bingx,MEXC",
                "twitter:title":
                  "匯信國際 | 購買、交易和持有幾百種加密貨幣-Topcredit International",
                "twitter:description":
                  "匯信國際是一個一站式數字資產管理平台，用於投資比特幣 (BTC)、以太坊 (ETH)、USDT、Shiba Inu (SHIB) 和其他加密貨幣。",
              },
              meta_property: {
                "twitter:image:alt":
                  "匯信國際 | 購買、交易和持有幾百種加密貨幣-Topcredit International",
                "og:title":
                  "匯信國際 | 購買、交易和持有幾百種加密貨幣-Topcredit International",
                "og:description":
                  "匯信國際是一個一站式數字資產管理平台，用於投資比特幣 (BTC)、以太坊 (ETH)、USDT、Shiba Inu (SHIB) 和其他加密貨幣。",
                "og:image:alt":
                  "匯信國際 | 購買、交易和持有幾百種加密貨幣-Topcredit International",
              },
            },
            metaverse: {
              title: "Topcredit元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
              meta_name: {
                description:
                  "Topcredit元宇宙生態區提供加密貨幣市場最新、最齊全、最有價值的內容 | 讓你快速了解 Metaverse 數位貨幣及元宇宙幣的新聞和市場價格。",
                keywords:
                  "Topcredit加密貨幣交易所,元宇宙生態,元宇宙市場,元宇宙新聞",
                "twitter:title":
                  "Topcredit元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
                "twitter:description":
                  "Topcredit元宇宙生態區提供加密貨幣市場最新、最齊全、最有價值的內容。",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
                "og:title":
                  "Topcredit元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
                "og:description":
                  "Topcredit元宇宙生態區提供加密貨幣市場最新、最齊全、最有價值的內容。",
                "og:image:alt":
                  "Topcredit元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
              },
            },
            finances: {
              title: "Topcredit理財產品 | 每日速賺高年化率的定存、活存選擇",
              meta_name: {
                description:
                  "小資族、保守型投資人的最愛！Topcredit理財產品提供多樣化的投資選擇，如BTC、ETH、DOGE、USDT及更多加密貨幣的定活存產品，每日領取高額利息，年化率最高 22.5%！不只質押、DeFi和雲端挖礦都適用。",
                keywords:
                  "Topcredit理財產品,加密貨幣質押,元宇宙幣交易,定期儲蓄存款,活存儲蓄存款",
                "twitter:title":
                  "Topcredit理財產品 | 每日速賺高年化率的定存、活存選擇",
                "twitter:description":
                  "Topcredit理財產品年化率最高22.5%！小資族、保守型投資人的最愛！",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit理財產品 | 每日速賺高年化率的定存、活存選擇",
                "og:title":
                  "Topcredit理財產品 | 每日速賺高年化率的定存、活存選擇",
                "og:description":
                  "Topcredit理財產品年化率最高22.5%！小資族、保守型投資人的最愛！",
                "og:image:alt":
                  "Topcredit理財產品 | 每日速賺高年化率的定存、活存選擇",
              },
            },
            aboutus: {
              title: "Topcredit加密貨幣交易所 | 關於我們",
              meta_name: {
                description:
                  "自從比特幣挖礦誕生以來，加密貨幣市場就一直在改變世界——而 Topcredit 正在領導這場競賽。Topcredit致力於建構元宇宙生態，我們所新增的數位貨幣幣種和金融產品選擇都必須經過嚴格的審查，在Topcredit交易所裡用戶可以安心投資！",
                keywords:
                  "Topcredit加密貨幣交易所,Topcredit理念,元宇宙幣交易,數位貨幣交易",
                "twitter:title": "Topcredit加密貨幣交易所 | 關於我們",
                "twitter:description":
                  "Topcredit爲元宇宙交易所的領導品牌！我們致力於建構元宇宙生態，隨時新增數位貨幣和金融產品，並必須經過嚴格的審查，在Topcredit交易所裡用戶可以安心投資！",
              },
              meta_property: {
                "twitter:image:alt":
                  "Topcredit加密貨幣交易所 | 全球首家元宇宙生態交易所",
                "og:title": "Topcredit加密貨幣交易所 | 關於我們",
                "og:description":
                  "Topcredit爲元宇宙交易所的領導品牌！我們致力於建構元宇宙生態，隨時新增數位貨幣和金融產品，並必須經過嚴格的審查，在Topcredit交易所裡用戶可以安心投資！",
                "og:image:alt":
                  "Topcredit加密貨幣交易所 | 全球首家元宇宙生態交易所",
              },
            },
            "service-agreement": {
              title: "Topcredit加密貨幣交易所 | 用戶協議",
              meta_name: {
                description: "Topcredit詳細服務條款與條件",
                keywords:
                  "Topcredit加密貨幣交易所,用戶協議,服務條款,數位貨幣交易",
                "twitter:title": "Topcredit加密貨幣交易所 | 用戶協議",
                "twitter:description": "Topcredit詳細服務條款與條",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | 用戶協議",
                "og:title": "Topcredit加密貨幣交易所 | 用戶協議",
                "og:description": "Topcredit詳細服務條款與條",
                "og:image:alt": "Topcredit加密貨幣交易所 | 用戶協議",
              },
            },
            register: {
              title: "Topcredit加密貨幣交易所 | 註冊",
              meta_name: {
                description:
                  "立即註冊Topcredit加密貨幣交易所｜Topcredit是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                keywords: "Topcredit加密貨幣交易所,註冊,KYC,雙重驗證",
                "twitter:title": "Topcredit加密貨幣交易所 | 註冊",
                "twitter:description":
                  "Topcredit是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | 註冊",
                "og:title": "Topcredit加密貨幣交易所 | 註冊",
                "og:description":
                  "Topcredit是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                "og:image:alt": "Topcredit加密貨幣交易所 | 註冊",
              },
            },
            login: {
              title: "Topcredit加密貨幣交易所 | 登入",
              meta_name: {
                description:
                  "Topcredit是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                keywords: "Topcredit加密貨幣交易所,登入,登入Email,登入密碼",
                "twitter:title": "Topcredit加密貨幣交易所 | 登入",
                "twitter:description":
                  "Topcredit是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | 登入",
                "og:title": "Topcredit加密貨幣交易所 | 登入",
                "og:description":
                  "Topcredit是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                "og:image:alt": "Topcredit加密貨幣交易所 | 登入",
              },
            },
            forgetPwd: {
              title: "Topcredit加密貨幣交易所 | 重置登入密碼",
              meta_name: {
                description: "重置登入密碼後，24小時內禁止出入金。",
                keywords:
                  "Topcredit加密貨幣交易所,重置密碼,忘記密碼,數位貨幣交易",
                "twitter:title": "Topcredit加密貨幣交易所 | 重置登入密碼",
                "twitter:description": "重置登入密碼後，24小時內禁止出入金。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | 重置登入密碼",
                "og:title": "Topcredit加密貨幣交易所 | 重置登入密碼",
                "og:description": "重置登入密碼後，24小時內禁止出入金。",
                "og:image:alt": "Topcredit加密貨幣交易所 | 重置登入密碼",
              },
            },
            "privacy-policy": {
              title: "Topcredit加密貨幣交易所 | 隱私權政策",
              meta_name: {
                description:
                  "Topcredit加密貨幣交易所的隱私權政策及個資安全資訊。",
                keywords:
                  "Topcredit加密貨幣交易所,隱私權政策,個資安全資訊,數位貨幣交易",
                "twitter:title": "Topcredit加密貨幣交易所 | 隱私權政策",
                "twitter:description": "隱私權政策及個資安全資訊",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | 隱私權政策",
                "og:title": "Topcredit加密貨幣交易所 | 隱私權政策",
                "og:description": "隱私權政策及個資安全資訊",
                "og:image:alt": "Topcredit加密貨幣交易所 | 隱私權政策",
              },
            },
            //coinFullName coinName（大写） 需替换
            "contract-exchange": {
              title:
                "Topcredit加密貨幣交易所｜ coinFullName U本位合約交易 | coinName/USDT永續合約",
              meta_name: {
                description:
                  "Topcredit加密貨幣交易所｜U本位交易 coinName/USDT合約高達 200倍保證金 | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                keywords:
                  "Topcredit加密貨幣交易所, coinFullName/USDT合約交易,加密貨幣合約交易, coinName永續合約",
                "twitter:title":
                  "Topcredit加密貨幣交易所｜coinFullNameU本位合約交易 : coinName/USDT永續合約",
                "twitter:description":
                  "Topcredit加密貨幣交易所｜U本位交易 coinName/USDT合約高達200倍保證金  | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | U本位合約交易",
                "og:title": "Topcredit加密貨幣交易所 | 隱私權政策",
                "og:description": "隱私權政策及個資安全資訊",
                "og:image:alt": "Topcredit加密貨幣交易所 | U本位合約交易",
              },
            },
            delivery: {
              title:
                "Topcredit加密貨幣交易所｜coinFullName 幣本位合約交易： coinName 期貨合約保證金",
              meta_name: {
                description:
                  "Topcredit加密貨幣交易所｜coinName 幣本位合約交易高達100倍保證金 | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                keywords:
                  "Topcredit加密貨幣交易所, coinName/USDT合約交易, coinFullName期貨合約,永續合約",
                "twitter:title":
                  "Topcredit加密貨幣交易所｜coinFullName幣本位合約交易： coinName期貨合約保證金",
                "twitter:description":
                  "Topcredit加密貨幣交易所｜coinName幣本位合約交易高達100倍保證金 | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所 | 幣本位合約交易",
                "og:title":
                  "Topcredit加密貨幣交易所｜coinFullName幣本位合約交易： coinName期貨合約保證金",
                "og:description":
                  "Topcredit加密貨幣交易所｜coinName幣本位合約交易高達100倍保證金 | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                "og:image:alt": "Topcredit加密貨幣交易所 | 幣本位合約交易",
              },
            },
            exchange: {
              title:
                "coinFullName/USDT 現貨市場走勢 | 買入coinName | Topcredit加密貨幣交易所",
              meta_name: {
                description:
                  "即時買入coinName | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                keywords:
                  "Topcredit加密貨幣交易所, coinFullName現貨, coinName走勢圖, coinName深度圖, coinName-K線圖",
                "twitter:title":
                  "coinFullName/USDT 現貨市場走勢 | 買入coinName | Topcredit加密貨幣交易所",
                "twitter:description":
                  "即時買入coinName | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
              },
              meta_property: {
                "twitter:image:alt": "Topcredit加密貨幣交易所｜現貨市場走勢圖",
                "og:title":
                  "coinFullName/USDT 現貨市場走勢 | 買入coinName | Topcredit加密貨幣交易所",
                "og:description":
                  "即時買入coinName | 快加入Topcredit元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                "og:image:alt": "Topcredit加密貨幣交易所｜現貨市場走勢圖",
              },
            },
          },
        },
      ],
      coinInfoMap: [],
    };
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    coinMsg() {
      // console.log("this.$route.path", this.$route.path);
      let coinFullName = "";
      let coinName = "";
      if (VUE_UNION_STATION != "TopCredit") {
        return { coinFullName, coinName };
      }

      let pathArray = this.$route.path.split("/");
      if (pathArray[1] == "contract-exchange") {
        coinFullName = "Bitcoin";
        coinName = "BTC";
        if (pathArray.length >= 3) {
          let tempCoin = pathArray[2].split("usdt");
          this.coinInfoMap.forEach(item => {
            if (item.name.toLowerCase() == tempCoin[0].toLowerCase()) {
              coinFullName = item.language.display_en;
              coinName = tempCoin[0].toUpperCase();
            }
          });
        }
      } else if (pathArray[1] == "delivery") {
        coinFullName = "Ethereum";
        coinName = "ETH";
        if (pathArray.length >= 3) {
          let tempCoin = pathArray[2].split("usd");
          this.coinInfoMap.forEach(item => {
            if (item.name.toLowerCase() == tempCoin[0].toLowerCase()) {
              coinFullName = item.language.display_en;
              coinName = tempCoin[0].toUpperCase();
            }
          });
        }
      } else if (pathArray[1] == "exchange") {
        coinFullName = "Bitcoin";
        coinName = "BTC";
        if (pathArray.length >= 3) {
          let tempCoin = pathArray[2].split("_");
          this.coinInfoMap.forEach(item => {
            if (item.name.toLowerCase() == tempCoin[0].toLowerCase()) {
              coinFullName = item.language.display_en;
              coinName = tempCoin[0].toUpperCase();
            }
          });
        }
      }
      if (
        window.location.href == "https://tope.com" ||
        window.location.href == "https://tope.com/"
      ) {
        let script = document.createElement("script");
        script.type = "application/ld+json";
        script.text = `
        	{
            "@context": "https://schema.org",
            "@graph": [{
                "@type": "Organization",
                "@id": "https://www.tope.com",
                "name": "Topcredit Exchange",
                "alternateName": [
                  "Topcredit交易所",
                  "Topcredit 거래소"
                ],
                "url": "https://www.tope.com",
                "legalName": "EIGHT V TECHNOLOGY LTD.",
                "foundingDate": "2021",
                "sameAs": [
                  "https://twitter.com/TopcreditGlobal",
                  "https://www.facebook.com/Topcredit.Global/",
                  "https://www.instagram.com/Topcredit.global"
                ],
                "logo": {
                  "@type": "ImageObject",
                  "@id": "https://www.tope.com",
                  "url": "https://Topcredit.com/logo_Topcredit.png",
                  "caption": "Topcredit Exchange"
                },
                "image": {
                  "@id": "https://www.tope.com"
                }
              },
              {
                "@type": "WebSite",
                "@id": "https://www.tope.com/",
                "url": "https://www.tope.com/",
                "name": "Topcredit Exchange",
                "publisher": {
                  "@id": "https://www.tope.com/"
                }
              },
              {
                "@type": "ImageObject",
                "@id": " https://www.tope.com/",
                "url": "https://Topcredit.com/logo_Topcredit.png",
                "width": 1024,
                "height": 1024
              },
              {
                "@type": "WebPage",
                "@id": "https://www.tope.com",
                "url": "https://www.tope.com",
                "inLanguage": "en",
                "name": "Topcredit Exchange",
                "description": "Topcredit Exchange - Trade hundreds of crypto coins on the exchange built for the Metaverse",
                "isPartOf": {
                  "@id": "https://www.tope.com"
                },
                "about": {
                  "@id": "https://Topcredit.com/aboutus"
                },
                "primaryImageOfPage": {
                  "@id": "https://www.tope.com/"
                }
              },
              {
                "@type": "Table",
                "about": "Cryptocurrency Market Info"
              }
              ]
            }
        `;
        document.getElementsByTagName("head")[0].appendChild(script);
      }
      return { coinFullName, coinName };
    },
  },
  metaInfo() {
    if (VUE_UNION_STATION != "TopCredit") {
      return {};
    }
    document.getElementsByTagName("html")[0].lang =
      this.language == "zh_CN"
        ? "zh-Hans"
        : this.language == "zh_HK"
        ? "zh-Hant"
        : this.language;
    let pageTitle = "";
    let linkArray = [];
    let metaArray = [];
    let newUrl = "";
    // let urlArray = this.$route.path.split("/");
    let realArray = this.$route.path.split("/");
    let lastIsLangReal = false;
    this.seoList.forEach(ele => {
      if (ele.value == realArray[realArray.length - 1]) {
        lastIsLangReal = true;
      }
    });
    if (lastIsLangReal) {
      realArray[realArray.length - 1] = this.language;
    } else {
      if (!realArray[realArray.length - 1]) {
        realArray[realArray.length - 1] = this.language;
      } else {
        realArray[realArray.length] = this.language;
      }
    }
    let urlArray = JSON.parse(JSON.stringify(realArray));
    this.seoList.forEach(item => {
      //alternate/canonical
      let linkTemp = {};
      let metaTemp = {};
      linkTemp["rel"] = "alternate";
      urlArray[urlArray.length - 1] = item.value;
      newUrl = urlArray.join("/");
      linkTemp["href"] = (
        document.location.protocol +
        "//" +
        window.location.host +
        newUrl
      )
        .replace("en/", "")
        .replace("en", "");
      // linkTemp["hreflang"] =
      //   item.value == this.language
      //     ? "x-default"
      //     : item.value == "zh_CN"
      //     ? "zh-Hans"
      //     : item.value == "zh_HK"
      //     ? "zh-Hant"
      //     : item.value;
      if (item.value == "en") {
        let tempLinkTemp = {};
        tempLinkTemp["rel"] = "alternate";
        tempLinkTemp["href"] =
          document.location.protocol +
          "//" +
          window.location.host +
          newUrl.replace("en/", "").replace("en", "");
        tempLinkTemp["hreflang"] = "x-default";
        linkArray.push(tempLinkTemp);
      }
      linkTemp["hreflang"] =
        item.value == "zh_CN"
          ? "zh-Hans"
          : item.value == "zh_HK"
          ? "zh-Hant"
          : item.value;
      linkArray.push(linkTemp);
      //opengraph
      if (this.language == item.value) {
        let imageUrl =
          document.location.protocol +
          "//" +
          window.location.host +
          `/previewimage${
            item.value == "en"
              ? "EN"
              : item.value == "zh_CN"
              ? "ZH-CN"
              : item.value == "zh_HK"
              ? "ZH-HK"
              : item.value.toUpperCase() + "-" + item.value.toUpperCase()
          }.png`;
        metaArray.push({
          property: "og:image",
          content: imageUrl,
        });
        metaArray.push({
          property: "twitter:image",
          content: imageUrl,
        });
        metaArray.push({
          property: "twitter:image:src",
          content: imageUrl,
        });
        // if (urlArray[1] == "contract-exchange") {
        // }
        metaTemp["property"] = "og:locale";
        metaTemp["content"] = item.valueFull;
        let isEnd = true;
        item.seoMsg &&
          Object.keys(item.seoMsg).every(ele => {
            if (this.$route.path.includes(ele)) {
              //title
              pageTitle = item.seoMsg[ele].title
                .replace("coinFullName", this.coinMsg["coinFullName"])
                .replace("coinName", this.coinMsg["coinName"]);
              //meta name
              Object.keys(item.seoMsg[ele].meta_name).forEach(elem => {
                metaArray.push({
                  name: elem,
                  content: item.seoMsg[ele].meta_name[elem]
                    .replace("coinFullName", this.coinMsg["coinFullName"])
                    .replace("coinName", this.coinMsg["coinName"]),
                });
              });
              //meta property
              Object.keys(item.seoMsg[ele].meta_property).forEach(elep => {
                metaArray.push({
                  property: elep,
                  content: item.seoMsg[ele].meta_property[elep]
                    .replace("coinFullName", this.coinMsg["coinFullName"])
                    .replace("coinName", this.coinMsg["coinName"]),
                });
              });
              isEnd = false;
              return isEnd;
            }
            return true;
          });
        if (isEnd && item.seoMsg) {
          //title
          pageTitle = item.seoMsg["index"].title
            .replace("coinFullName", this.coinMsg["coinFullName"])
            .replace("coinName", this.coinMsg["coinName"]);
          //meta name
          Object.keys(item.seoMsg["index"].meta_name).forEach(elem => {
            metaArray.push({
              name: elem,
              content: item.seoMsg["index"].meta_name[elem]
                .replace("coinFullName", this.coinMsg["coinFullName"])
                .replace("coinName", this.coinMsg["coinName"]),
            });
          });
          //meta property
          Object.keys(item.seoMsg["index"].meta_property).forEach(elep => {
            metaArray.push({
              property: elep,
              content: item.seoMsg["index"].meta_property[elep]
                .replace("coinFullName", this.coinMsg["coinFullName"])
                .replace("coinName", this.coinMsg["coinName"]),
            });
          });
        }
      } else {
        metaTemp["property"] = "og:locale:alternate";
        metaTemp["content"] = item.valueFull;
      }
      metaArray.push(metaTemp);
    });
    metaArray.push({
      property: "og:url",
      content:
        document.location.protocol +
        "//" +
        window.location.host +
        realArray
          .join("/")
          .replace("en/", "")
          .replace("en", ""),
    });
    metaArray.push({ property: "og:type", content: "website" });
    metaArray.push({ name: "og:image:width", content: "1200" });
    metaArray.push({ name: "og:image:height", content: "630" });

    metaArray.push({ name: "Resource-type", content: "Document" });
    metaArray.push({ name: "google", content: "notranslate" });

    metaArray.push({ name: "twitter:site", content: "@TopcreditGlobal" });
    metaArray.push({ name: "twitter:card", content: "summary_large_image" });
    //icon//
    linkArray.push({
      rel: "apple-touch-icon",
      type: "image/png",
      href: "/favicon.png",
    });
    linkArray.push({
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "72x72",
      href: "/favicon.png",
    });
    linkArray.push({
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "114x114",
      href: "/favicon.png",
      // test: this.$route.path.split("/"),
      // test2: JSON.stringify(this.coinMsg),
    });
    linkArray.push({ rel: "icon", type: "image/png", href: "/favicon.png" });
    return {
      title: pageTitle,
      meta: [...metaArray],
      link: [
        ...linkArray,
        // {
        //   rel: "canonical",
        //   href:
        //     document.location.protocol +
        //     "//" +
        //     window.location.host +
        //     this.$route.path,
        // },
        {
          rel: "canonical",
          href:
            document.location.protocol +
            "//" +
            window.location.host +
            realArray
              .join("/")
              .replace("en/", "")
              .replace("en", ""),
        },
      ],
    };
    // } else {
    //   return {};
    // }
  },
  created() {
    this.getCoinInfoMap();
  },
  methods: {
    async getCoinInfoMap() {
      const { status, data } = await apiGetStaticTableInfo(1);
      if (status == 200) {
        this.coinInfoMap = Object.values(data);
      }
    },
  },
};
</script>
