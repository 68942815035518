<template>
  <div class="page-footer-maskex">
    <div class="first-content">
      <div class="page-footer-content">
        <div>
          <ul class="logo-content">
            <li>
              <a href="/">
                <img :src="logImg" alt="" />
              </a>
            </li>
            <li class="sub-title">
              {{ slogan["web_slogan_" + language] }}
            </li>
            <!-- <li class="footer-icons" v-if="!is8V">
              <a :href="tgUrl" target="_blank">
                <i class="iconfont iconf-4"> </i>
              </a>
            </li> -->
          </ul>
        </div>

        <div>
          <ul class="text-content">
            <li class="sub-title">{{ translateTitle("关于") }}</li>
            <a
              :href="routeSplicing(language, `company/aboutus`)"
              target="_blank"
            >
              <li>{{ translateTitle("关于我们") }}</li>
            </a>
            <a
              :href="routeSplicing(language, `company/careers`)"
              target="_blank"
            >
              <li>{{ translateTitle("招贤纳士") }}</li>
            </a>
            <a href="https://blog.maskex.com/" target="_blank">
              <li>{{ translateTitle("博客") }}</li>
            </a>
            <a
              :href="routeSplicing(language, `join-affiliate-program`)"
              target="_self"
            >
              <li>{{ translateTitle("会员计划") }}</li>
            </a>
            <a :href="routeSplicing(language, `broker`)" target="_self">
              <li>{{ translateTitle("经纪人") }}</li>
            </a>

            <a :href="routeSplicing(language, `feedback`)" target="_self">
              <li>{{ translateTitle("反馈") }}</li>
            </a>
          </ul>
        </div>
        <!-- <div>
        <ul class="text-content">
          <li class="sub-title">商务</li>
          <li>API接入</li>
          <li>上币申请</li>
        </ul>
      </div> -->
        <div>
          <ul class="text-content">
            <li class="sub-title">{{ translateTitle("条款") }}</li>
            <a :href="serviceUrl" target="_blank">
              <li>{{ translateTitle("服务协议") }}</li>
            </a>
            <a :href="privacyUrl" target="_blank">
              <li>{{ translateTitle("隐私协议") }}</li>
            </a>
            <!-- <a :href="statementUrl" target="_blank">
              <li>{{ translateTitle("免责声明") }}</li>
            </a> -->
            <a
              :href="routeSplicing(language, `lawEnforcementEnquiry`)"
              target="_blank"
            >
              <li>{{ translateTitle("执法协助") }}</li>
            </a>
          </ul>
        </div>
        <div>
          <ul class="text-content">
            <li class="sub-title">{{ translateTitle("支持") }}</li>
            <a :href="helpUrl" target="_blank">
              <li>{{ translateTitle("帮助中心") }}</li>
            </a>
            <a :href="rateUrl" target="_blank">
              <li>{{ translateTitle("费率标准") }}</li>
            </a>
            <a :href="tipUrl" target="_blank">
              <li>{{ translateTitle("公告中心") }}</li>
            </a>
            <a
              href="https://maskex.zendesk.com/hc/en-us/categories/4407886849809-Official-Announcements"
              target="_blank"
            >
              <li>{{ translateTitle("Announcement center") }}</li>
            </a>
            <a
              href="https://maskex.zendesk.com/hc/en-us/categories/4709365152017-Beginner-s-Guide-"
              target="_blank"
            >
              <li>{{ translateTitle("Beginner's Guide") }}</li>
            </a>
          </ul>
        </div>
        <div class="icon-content">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100092126102843"
                target="_blank"
              >
                <img src="../../assets/img/mask/footer/facebook.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://mobile.twitter.com/MaskexGlobal" target="_blank">
                <img src="../../assets/img/mask/footer/twitter.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/maskexglobal/" target="_blank">
                <img src="../../assets/img/mask/footer/instagram.png" alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UClSeew7MEmHi6i19GBEun9w"
                target="_blank"
              >
                <img src="../../assets/img/mask/footer/youtube.png" alt="" />
              </a>
            </li>
          </ul>
          <ul class="sencond-row">
            <li>
              <a href="https://t.me/maskextg" target="_blank">
                <img src="../../assets/img/mask/footer/telegram.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://vm.tiktok.com/ZSeEMpTqm" target="_blank">
                <img src="../../assets/img/mask/footer/tiktok.png" alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://am.linkedin.com/company/maskex-global"
                target="_blank"
              >
                <img src="../../assets/img/mask/footer/linkedin.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/v658je6T7A" target="_blank">
                <img src="../../assets/img/mask/footer/discord.png" alt="" />
              </a>
            </li>
            <div class="emal-contact">
              <p>{{ translateTitle("Compliance Contact") }}:</p>
              <a href="mailto:compliance@maskex.com">compliance@maskex.com</a>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <div class="bottom-content">
      ©2023, MaskEX All Rights Reserved
      <span style="margin-left: 10px">
        <a
          style="color: rgba(255, 255, 255, 0.46)"
          href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136363034332c227072696d617279536561726368223a7b226f72674e616d65223a224d41534b20474c4f42414c204d41524b455420434f2e2c204c54442e222c2273656172636854797065223a317d7d/"
        >
          FINTRAC Registered
        </a>
      </span>
    </div>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import { getEnv } from "@/assets/js/stationSetting";
export default {
  components: {
    // QrcodeVue,
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  data() {
    return {
      slogan: "让金融更高效 让财富更自由",
      tgUrl: "",
      tipUrl: "",
      qrcodeTitle: "",
      rateUrl: "",
      serviceUrl: "",
      privacyUrl: "",
      statementUrl: "",
      helpUrl: "",
      logImg: "",
      is8V: false,
    };
  },
  created() {
    this.logImg = require("../../assets/img/mask-logo.png");
    this.slogan = JSON.parse(sessionStorage.getItem("solgan"));

    if (!JSON.parse(sessionStorage.getItem("clause"))) {
      getEnv();
    }
    let clauseList = JSON.parse(sessionStorage.getItem("clause"));
    let othersList = JSON.parse(sessionStorage.getItem("others"));
    let noticeList = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    let about = JSON.parse(sessionStorage.getItem("about"));

    this.privacyUrl = clauseList[1].url;
    this.statementUrl = clauseList[2].url;
    this.serviceUrl = clauseList[0].url;
    this.rateUrl = othersList[0].url;
    this.tipUrl = about[0].url;
    this.helpUrl = about[1].url;
    this.tgUrl = qrcode.ori_url;
    this.qrcodeTitle = qrcode.title;
  },
  methods: {
    routeSplicing,
    translateTitle,
    pathJump() {
      this.$router.push({
        path: "/lawEnforcementEnquiry",
      });
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
  },
};
</script>
<style lang="scss">
.page-footer-maskex {
  .emal-contact {
    margin-top: 20px;
    margin-left: 40px;
    p {
      font-size: 14px;
      text-align: left;
      color: #777f87;
      margin-bottom: 5px;
    }
    a {
      font-size: 16px;
      text-align: left;
      display: block;
      color: #c3cccb;
    }
  }
  padding: 75px 0 20px;

  .first-content {
    display: flex;
    justify-content: center;
  }
  .second-content {
    width: 1000px;
    margin: 0px auto;
    padding: 20px 90px;
    border-top: 1px solid #fdac35;
    color: #fdac35;
    p {
      font-size: 14px;
      line-height: 2;
      margin-bottom: 10px;
      vertical-align: middle;
      img {
        width: 14px;
        height: 16px;
        vertical-align: middle;
      }
    }
  }
  background-color: #17232c;
  color: #fff;
  text-align: center;
  user-select: none;

  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  .page-footer-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    // padding: 0px 360px;
    width: 1200px;
    .logo-content img {
      height: 42px;
    }

    .footer-icons {
      margin-top: 30px;
      .iconfont {
        width: 16px;
        height: 12px;
        color: #7e8392;
        margin-right: 16px;
        cursor: pointer;
        &:hover {
          color: #357de1;
          // width: 20px;
          // height: 20px;
          // border-radius: 50%;
          // color: #ffffff;
        }
      }
    }
    .logo-content {
      text-align: left;
      margin-right: 30px;
      img {
        width: 180px;
        height: 35px;
      }
      .sub-title {
        width: 228px;
        font-size: 16px;
        color: #ccced0;
        height: 20px;
        margin-top: 10px;
        font-family: DINPro-Regular;
      }
    }
    .tg-content {
      .qr_code_canvas {
        margin-bottom: 10px;
        canvas {
          border: 3px solid white;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
      li {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    .text-content {
      vertical-align: top;
      text-align: left;
      li {
        font-size: 16px;
        // font-weight: 500;
        margin: 0 0 17px;
        color: #ccced0;
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
      a {
        li {
          font-family: DINPro-Regular;
        }
      }
      .sub-title {
        font-size: 24px;
        font-weight: bold;
        color: #15bfb5;
        margin-bottom: 22px;
        &:hover {
          color: #15bfb5;
        }
      }
    }
    .icon-content {
      width: 240px;
      ul li {
        margin-left: 30px;
        display: inline-block;
        img {
          height: 22px;
        }
      }
      .sencond-row {
        margin-top: 25px;
      }
    }
  }
  .bottom-content {
    margin-top: 50px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.46);
  }
}
</style>
