"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.string.replace.js");

var encryption = function encryption(val) {
  val = val.toString();

  if (val.includes('@')) {
    var arr = val.split("@");
    var star = "";

    if (arr[0].length <= 3) {
      star = "***";
      arr[0] = arr[0].substr(0, arr[0].length - 1) + star;
    } else if (arr[0].length <= 6) {
      star = "***";
      arr[0] = arr[0].substr(0, arr[0].length - 3) + star;
    } else {
      star = "***";
      arr[0] = arr[0].substr(0, arr[0].length - 5) + star;
    }

    return val = arr[0] + "@" + arr[1];
  } else {
    var reg = /(\d{3})\d*(\d{3})/;
    return val.replace(reg, '$1****$2');
  }
};

var _default = encryption;
exports.default = _default;