"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiApiSecretAdd = apiApiSecretAdd;
exports.apiApiSecretDelete = apiApiSecretDelete;
exports.apiApiSecretEdit = apiApiSecretEdit;
exports.apiApiSecretGetList = apiApiSecretGetList;
exports.apiApiSecretSecretInfo = apiApiSecretSecretInfo;
exports.apiGetAssetsLog = apiGetAssetsLog;
exports.apiGetContractPositionInfo = apiGetContractPositionInfo;
exports.apiGetFuturesPositionInfo = apiGetFuturesPositionInfo;
exports.apiGetUserOrderInfo = apiGetUserOrderInfo;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
//获取账单列表-资产记录
function apiGetAssetsLog() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_SOTC + "/SecretOtc/App/getAssetsLog",
    data: data,
    type: "POST"
  });
} //获取aipkey列表


function apiApiSecretGetList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/ApiSecret/getList",
    data: data,
    type: "POST"
  });
} //新增api Key


function apiApiSecretAdd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/ApiSecret/create",
    data: data,
    type: "POST"
  });
} //编辑api Key


function apiApiSecretEdit() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/ApiSecret/updateInfo",
    data: data,
    type: "POST"
  });
} //删除 api Key


function apiApiSecretDelete() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/ApiSecret/delete",
    data: data,
    type: "POST"
  });
} //删除 api Key


function apiApiSecretSecretInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/ApiSecret/getSecretInfo",
    data: data,
    type: "POST"
  });
} //U本位合约仓位详情


function apiGetContractPositionInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/Contract/positionInfo",
    data: data,
    type: "POST"
  });
} //币本位合约仓位详情


function apiGetFuturesPositionInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Futures/Common/positionInfo",
    data: data,
    type: "POST"
  });
} //用户获取订单详情-OTC 充值 提现


function apiGetUserOrderInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_SOTC + "/SecretOtc/App/getUserOrderInfo",
    data: data,
    type: "POST"
  });
}