"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.json.stringify.js");

var _i18n = require("@/assets/js/i18n");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["label", "placeholder", "verificate"],
  data: function data() {
    var message = this.translateTitle(this.label);
    return {
      message: message,
      formData: {
        google_code: ""
      },
      formRules: {
        google_code: [{
          required: true,
          message: message,
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle("\u5FC5\u987B\u4E3A6\u4F4D\u7EAF\u6570\u5B57")
        }]
      }
    };
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    clearCode: function clearCode() {
      this.formData.google_code = "";
    },
    submitForm: function submitForm() {
      // dBindForm
      var statusData;
      var formData = this.formData;
      this.$refs.dBindForm.validate(function (valid) {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });
      return JSON.parse(JSON.stringify(statusData));
    }
  }
};
exports.default = _default;