"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 * @Author: your name
 * @Date: 2021-10-21 21:25:30
 * @LastEditTime: 2021-10-22 11:45:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /topcredit-exchange/src/assets/js/global.js
 */
// global.js 文件
var _default = {
  ws: {
    sub: {}
  },
  setWs: function setWs(newWs) {
    this.ws = newWs;
  },
  ws2: {
    sub: {}
  },
  setWs2: function setWs2(newWs) {
    this.ws2 = newWs;
  },
  //静态表相关
  settingList: {},
  setSl: function setSl(data) {
    this.settingList = data;
  } // isGetSettinFirst: false

};
exports.default = _default;