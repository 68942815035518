import * as Ucenter from "./index";
import store from "../../store/index";

import api from "@/assets/js/api";
import Cookies from "js-cookie";

// const { VUE_APP_UCENTER } = process.env;
import { VUE_APP_UCENTER } from "@/assets/js/stationSetting";

export function apiGetUserInfo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Users/getUserInfo`,
    data,
  });
}

// 忘记密码四合一检测账号
export function forgotPwdCheckAccount(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/universalForget`,
    data,
  });
}
// 忘记密码四合一检测账号-极验
export function forgotPwdCheckAccountGeet(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Forget/universal`,
    data,
  });
}
// 忘记密码四合一检测账号
export function bindEmailFrist(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/FirstSecurity/bindEmail`,
    data,
  });
}

// 忘记密码四合一检测账号
export function bindGoogleAuthApplication(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/FirstSecurity/bindGoogleVerify`,
    data,
  });
}
// 首次绑定获取google令牌
export function getGoogleSecretFirst(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/FirstSecurity/getGoogleSecret`,
    data,
  });
}

// 首次绑定获取google令牌
export function sendEmailCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/FirstSecurity/sendBindEmailCode`,
    data,
  });
}
export function universalLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/universalLogin`,
    data,
  });
}
//登录不需要谷歌码
export function universalAccountV2Login(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/AccountV2/universalLogin`,
    data,
  });
}
// 邮箱登录 (email：邮箱，pwd：密码)
export function emailLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/emailLogin`,
    data,
  });
}

export function mobileLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/mobileLogin`,
    data,
  });
}
// 子账号登录 (child_username：子账号，pwd：密码)
export function childAccountLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/subLogin`,
    data,
  });
}
// // 邮箱注册
// export function emailReg(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/Account/emailSignUp`,
//     data,
//   });
// }
// 邮箱注册
export function emailReg(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Register/email`,
    data,
  });
}
// //手机注册
// export function mobileReg(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/Account/mobileSignUp`,
//     data,
//   });
// }
//手机注册
export function mobileReg(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Register/mobile`,
    data,
  });
}
// //谷歌二次验证
// export function googleVerifyLogin(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/googleVerifyLogin`,
//     data,
//   });
// }
//谷歌二次验证
export function googleVerifyLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/LoginVerify/googleVerify`,
    data,
  });
}
// // 邮箱二次验证
// export function emailVerify(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/emailVerify`,
//     data,
//   });
// }
// 邮箱二次验证
export function emailVerify(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/LoginVerify/emailVerify`,
    data,
  });
}
// // 手机二次验证
// export function smsVerify(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/smsVerify`,
//     data,
//   });
// }
// 手机二次验证
export function smsVerify(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/LoginVerify/mobileVerify`,
    data,
  });
}
//  //谷歌验证码
export function getGoogleSecret(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/getGoogleSecret`,
    data,
  });
}

//  //谷歌无登录验证码
export function getAccountGoogleSecret(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/getGoogleSecret`,
    data,
  });
}
//     //普通账户注册
export function accountSignUp(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/accountSignUp`,
    data,
  });
}
//     //账户注册 无需谷歌码
export function accountV2SignUp(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/accountV2SignUp`,
    data,
  });
}
//     //账户注册 无需谷歌码-极验
export function accountSignUpGeet(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Register/account`,
    data,
  });
}
//普通账号登录
export function accountLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/accountLogin`,
    data,
  });
}
//普通账号登录
export function submitForgetGoogle(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/submitForgetGoogle`,
    data,
  });
}
//获取邀请码实际返佣比率
export function inviteCodeRealRebateRate(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Publics/inviteCodeRealRebateRate`,
    data,
  });
}

//用户登录或者注册请求完成后, 拿到 token uid 直接进入登录状态
//uid token session_id
export const writeUserInfo = (userInfo, isLogin = false) => {
  if (isLogin) {
    Cookies.set("email", userInfo.accountInfo.email || null, { expires: 30 });
    Cookies.set("topcredit_exchange_t", userInfo.tokenInfo.token || null);

    Cookies.set("_token", userInfo.tokenInfo.token || null, {
      path: "",
      domain: ".hxexchge.com",
    });
  }

  //记录保存时间
  userInfo.cacheTime = Date.now();
  sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
};

//获取用户cookie信息
export const getUserCookieInfo = () => {
  return {
    email: Cookies.get("email"),
    u_token: Cookies.get("topcredit_exchange_t"),
  };
};

let whiteList = ["/login"];

// 登出
export const logout = () => {
  store.dispatch("user/logout");
  //清除cookie
  Cookies.remove("email");
  Cookies.remove("topcredit_exchange_t");

  //清除localStorage
  // localStorage.clear();
  localStorage.removeItem("userData");
  //清除session
  sessionStorage.clear();
  if (!whiteList.includes(location.pathname)) {
    location.href = "/login";
  }
};

//获取用户信息
export const getUserInfo = async (params, options = {}) => {
  const { useCache = true } = options;

  //获取已有缓存
  let result = sessionStorage.getItem("userInfo");
  let userInfo;

  //已有缓存
  if (result && result != null && useCache) {
    userInfo = JSON.parse(result);
    if (userInfo) {
      return userInfo;
    }
  } else {
    if (!Cookies.get("topcredit_exchange_t")) return {};
    // 不使用缓存
    // return {};
    let tempObj = {};
    const resultU = await Ucenter.getUserInfo();
    const { data, status } = resultU;
    if (status != 200) {
      return tempObj;
    }
    userInfo = data;

    writeUserInfo(userInfo);
  }
  return userInfo;
};

export const isSubAccount = () => {
  try {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo && userInfo.accountInfo && userInfo.accountInfo.type === 4) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const isChannel = () => {
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  try {
    const { channel = {} } = userInfo.auth || {};
    return Boolean(channel.is_complete);
  } catch (error) {
    return true;
  }
};
export const isMerchant = () => {
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  try {
    const { merchant = {} } = userInfo.auth || {};
    return Boolean(merchant.is_complete);
  } catch (error) {
    return true;
  }
};

// 获取当前功能是否显示
export const getShowHideMenu = ap_id => {
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  try {
    if (userInfo && userInfo.permitInfo && userInfo.permitInfo.user_permit) {
      let userPermit = userInfo.permitInfo.user_permit.permit;
      let initPermit = () => {
        let items = userPermit;
        let arr = [];
        if (items && items.length) {
          items = items.map(Number);
        } else {
          for (let i in items) {
            arr.push(items[i]);
          }
          items = arr;
        }

        return items.map(Number);
      };
      userPermit = initPermit(userPermit);
      if (ap_id === 8 && isChannel()) {
        return false;
      }
      if (userPermit.indexOf(ap_id) > -1) {
        // console.info("进行权限")
        return true;
      } else {
        // console.info("限制")
        return false;
      }
    } else {
      // console.info("无法获取")
      return true;
    }
  } catch (e) {
    return true;
  }
};
