"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiAddOption = apiAddOption;
exports.apiGetCoinInfo = apiGetCoinInfo;
exports.apiGetDeliveryFundingRate = apiGetDeliveryFundingRate;
exports.apiGetFundingRate = apiGetFundingRate;
exports.apiGetOptionList = apiGetOptionList;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER,VUE_APP_APP } = process.env
//获取币种详情
function apiGetCoinInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/App/Product/getCoinInfo"),
    data: data
  });
} //增加行情自选


function apiAddOption() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Common/addOption"),
    data: data
  });
} //自选


function apiGetOptionList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Common/getOptionList"),
    data: data
  });
} //合约获取资金费率


function apiGetFundingRate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Contract/getFundingRate"),
    data: data
  });
} //币本位合约获取资金费率


function apiGetDeliveryFundingRate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Futures/Trace/getFundingRate"),
    data: data
  });
}