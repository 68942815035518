"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAddress = addAddress;
exports.apiCreateFinanceAddress = apiCreateFinanceAddress;
exports.apiDelFinanceAddress = apiDelFinanceAddress;
exports.apiGetFinanceAddressList = apiGetFinanceAddressList;
exports.apiSaveFinanceAddress = apiSaveFinanceAddress;
exports.getAddress = getAddress;
exports.getAddressList = getAddressList;
exports.getAssetsList = getAssetsList;
exports.getCoinList = getCoinList;
exports.getExchangeListV2 = getExchangeListV2;
exports.getINCoinExchangeListV2 = getINCoinExchangeListV2;
exports.getUserExchangeLimit = getUserExchangeLimit;
exports.nickNameCheck = nickNameCheck;
exports.sendEmailCode = sendEmailCode;
exports.sendSmsCode = sendSmsCode;
exports.transfer = transfer;
exports.userLoginLogList = userLoginLogList;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
function getCoinList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/Settings/itemList",
    data: data,
    type: "POST"
  });
}

function getAssetsList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Assets/getAssetsList",
    data: data,
    type: "POST"
  });
}

function getExchangeListV2() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Assets/getCoinExchangeListV2",
    data: data,
    type: "POST"
  });
}

function getINCoinExchangeListV2() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Assets/getCoinExchangeListV2",
    data: data,
    type: "POST"
  });
}

function getUserExchangeLimit() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/getUserExchangeLimit",
    data: data,
    type: "POST"
  });
} //获取充币地址


function getAddress() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/getAddress",
    data: data,
    type: "POST"
  });
} //获取财务充币地址列表


function apiGetFinanceAddressList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/getFinanceAddressList",
    data: data,
    type: "POST"
  });
} //获取财务充币地址列表


function apiCreateFinanceAddress() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/createFinanceAddress",
    data: data,
    type: "POST"
  });
} //获取财务充币地址列表


function apiSaveFinanceAddress() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/saveFinanceAddress",
    data: data,
    type: "POST"
  });
} //获取财务充币地址列表


function apiDelFinanceAddress() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/delFinanceAddress",
    data: data,
    type: "POST"
  });
} //获取财务充币地址列表


function sendEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/sendEmailCode",
    data: data,
    type: "POST"
  });
}

function sendSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/sendSmsCode",
    data: data,
    type: "POST"
  });
}

function getAddressList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/addressList",
    data: data,
    type: "POST"
  });
}

function addAddress() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Address/addAddress",
    data: data,
    type: "POST"
  });
} // 二次验证,提币借口


function transfer() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Assets/transfer",
    data: data,
    type: "POST"
  });
} // 获取用户信息


function userLoginLogList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Auth/userLoginLogList",
    data: data,
    type: "POST"
  });
} //验证子帐号昵称有无重复


function nickNameCheck() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Account/nickNameCheck",
    data: data,
    type: "POST"
  });
}