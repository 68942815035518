"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiMarketTrend = apiMarketTrend;
exports.apiMetaverseExpress = apiMetaverseExpress;
exports.apiMetaverseTrend = apiMetaverseTrend;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

//元宇宙市场趋势
function apiMarketTrend() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Metaverse/marketTrend"),
    data: data
  });
} //元宇宙元宇宙趋势


function apiMetaverseTrend() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Metaverse/trend"),
    data: data
  });
} //元宇宙元宇宙趋势


function apiMetaverseExpress() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/Metaverse/express"),
    data: data
  });
}