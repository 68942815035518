"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateTitle = translateTitle;

function translateTitle(title) {
  if (this.$te("".concat(title))) return this.$t("".concat(title));
  return title;
}