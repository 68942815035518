"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

var _vuex = require("vuex");

var _formatDate = require("@/assets/js/formatDate");

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));

var _index = require("@/model/blog/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {},
  data: function data() {
    return {
      searchInput: "",
      tagId: "",
      activeTag: "ALL",
      bannerList: [],
      blogList: [],
      hotList: [],
      detail: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    userInfo: "user/userInfo",
    language: "user/language",
    environment: "user/environment",
    darkMode: "user/darkMode"
  })),
  created: function created() {
    this.getBlogDetails();
    this.getBlogList();
    this.getHotList();
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    timestamp: _formatDate.timestamp,
    onBtnClick: function onBtnClick() {
      this.$router.push({
        path: this.routeSplicing(this.language, "blog")
      });
    },
    onTagClick: function onTagClick(item) {
      this.activeTag = item;
    },
    getBlogList: function getBlogList() {
      var _this = this;

      var req = {
        search: this.searchInput,
        tag_id: this.tagId
      };
      (0, _index.apiGetBlogList)(req).then(function (res) {
        if (res.status == 200) {
          _this.blogList = res.data.list;
        }
      });
    },
    getHotList: function getHotList() {
      var _this2 = this;

      (0, _index.apiGetHotList)().then(function (res) {
        if (res.status == 200) {
          _this2.hotList = res.data;
        }
      });
    },
    getBlogDetails: function getBlogDetails() {
      var _this3 = this;

      var pid = this.$route.query.pid;
      (0, _index.apiGetBlogDetails)({
        pid: pid
      }).then(function (res) {
        if (res.status == 200) {
          _this3.detail = res.data;
        }
      });
    }
  }
};
exports.default = _default;