<template>
  <li class="user-center-menu el-menu-item nav-menu-item logins">
    <el-submenu
      v-show="userInfoShow"
      style="display: none"
      popper-class="sub-item-wrap select-user-item"
      index="3"
      class="no-arrow"
      :collapse-transition="false"
      :popper-append-to-body="false"
      :show-timeout="0"
      :hide-timeout="0"
    >
      <template slot="title">
        <router-link
          class="uers-ucenter-n"
          active-class="none-active"
          :class="getUserCenterClass"
          :to="routeSplicing(language, 'ucenter/situation')"
        >
          <span @click="goTo(routeSplicing(language, 'ucenter/situation'))">
            <img class="user-icon" src="../../assets/img_n/person.png" alt="" />
            <!-- <span class="user-text">
              {{ translateTitle("用户中心") }}
            </span> -->
          </span>
        </router-link>
      </template>
      <div class="user-msg">
        <p class="welcom">Hi, {{ userInfo && userInfo.userInfo.nick_name }}</p>
        <p class="uid">UID:{{ userInfo && userInfo.userInfo.user_id }}</p>
        <div class="kyc">
          <img src="../../assets/img_n/ok_icon.png" class="kyc-status" />
          <span class="kyc-text">{{ translateTitle("身份认证") }}</span>
        </div>
      </div>
      <el-menu-item
        class="sub-item"
        index="1"
        @click="goTo(routeSplicing(language, 'ucenter/situation'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/situation')"
        >
          <i class="iconfont iconmenu-1"></i>
          {{ translateTitle("个人总览") }}
        </router-link>
      </el-menu-item>
      <!-- <el-menu-item class="sub-item hide-icon" index="3-5">
        <router-link
          active-class="none-active"
          tag="span"
          to="/ucenter/safe-setting"
        >
          <i class="iconfont icongerenzhongxin-5"></i>
          {{ translateTitle("安全设置") }}
        </router-link>
      </el-menu-item> -->
      <el-menu-item
        class="sub-item"
        index="2"
        @click="goTo(routeSplicing(language, 'ucenter/assets'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/assets')"
        >
          <i class="iconfont iconmenu-6 sub-submenu-i"></i>
          {{ translateTitle("资产管理") }}
        </router-link>
      </el-menu-item>
      <el-menu-item
        class="sub-item"
        index="3"
        @click="goTo(routeSplicing(language, 'ucenter/tradeManagment'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/tradeManagment')"
        >
          <i class="iconfont iconswap-1 sub-submenu-i"></i>
          {{ translateTitle("交易管理") }}
        </router-link>
      </el-menu-item>
      <el-menu-item
        v-if="!is8Coin"
        class="sub-item"
        index="4"
        @click="goTo(routeSplicing(language, 'ucenter/finanManagementTemp'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/finanManagementTemp')"
        >
          <i class="iconfont iconmenu-8 sub-submenu-i"></i>
          {{ translateTitle("理财管理") }}
        </router-link>
      </el-menu-item>

      <el-menu-item
        class="sub-item"
        index="5"
        @click="goTo(routeSplicing(language, 'ucenter/spread-center'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/spread-center')"
        >
          <i class="iconfont iconicon-rebate"></i>
          {{ translateTitle("推广中心") }}
        </router-link>
      </el-menu-item>
      <el-menu-item
        class="sub-item"
        index="6"
        @click="goTo(routeSplicing(language, 'ucenter/coupon-center'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/coupon-center')"
        >
          <i class="iconfont iconicon-menu-1"></i>
          {{ translateTitle("卡券中心") }}
        </router-link>
      </el-menu-item>
      <el-menu-item
        class="sub-item"
        index="7"
        @click="goTo(routeSplicing(language, 'ucenter/bill-center'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/bill-center')"
        >
          <i class="iconfont iconmenu-5"></i>
          {{ translateTitle("账单") }}
        </router-link>
      </el-menu-item>
      <el-menu-item
        v-if="isMaskex"
        class="sub-item"
        index="8"
        @click="goTo(routeSplicing(language, 'ucenter/api-setting'))"
      >
        <router-link
          active-class="none-active"
          tag="span"
          :to="routeSplicing(language, 'ucenter/api-setting')"
        >
          <i class="iconfont iconmenu-5"></i>
          {{ translateTitle("API设置") }}
        </router-link>
      </el-menu-item>
      <el-menu-item class="sub-item" index="9" @click="onLogout">
        <span active-class="none-active">
          <i class="iconfont icontuichudenglu"></i>
          {{ translateTitle("退出登录") }}
        </span>
      </el-menu-item>
    </el-submenu>
  </li>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import routeSplicing from "@/assets/js/routeSplicing";
import { apiGetLoginOut } from "@/model/user/index";
import commonMixin from "@/components/common/commonMixin.vue";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  data() {
    return {
      languageName: "简体中文",
      showDownLoadImg: false,
      isB9: false,
      is8Coin: false,
      isMaskex: false,
    };
  },
  created() {
    let lang = localStorage.getItem("language");
    if (VUE_UNION_STATION == "8coin") {
      this.is8Coin = true;
    } else if (VUE_UNION_STATION == "B9") {
      this.isB9 = true;
    }
    if (VUE_UNION_STATION == "MaskEx") {
      this.isMaskex = true;
    }
    // this.handleSelect(lang);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    getUserCenterClass() {
      if (this.$route.path.includes("/ucenter/")) {
        return "none-active";
      } else {
        return "";
      }
    },
  },
  methods: {
    routeSplicing,
    async onLogout() {
      const abc = await apiGetLoginOut();
      this.$store.dispatch("user/logout");
      // 关闭usdt长连接
    },
    goTo(target) {
      this.$router.push(target);
    },
  },
};
</script>
<style lang="scss">
.user-center-menu {
  .uers-ucenter-n {
    opacity: 1 !important;
    .user-icon {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
    }
    .user-text {
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      height: 60px;
      display: flex;
      align-items: center;
    }
  }
}
.user-center-menu.el-menu-item.nav-menu-item.logins {
  .driver {
    right: -14px;
    top: 16px;
  }

  .sub-item-wrap-sub ::v-deep .el-submenu__title:hover {
    background-color: #fff;
    text-align: left;
    color: #12161c;
  }

  .el-menu--horizontal.sub-item-wrap.select-user-item {
    top: 59px !important;
    .el-menu {
      padding: 0 0 10px;
      .user-msg {
        width: 100%;
        height: 93px;
        background-image: url("../../assets/img_n/person_bg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
        .welcom {
          margin-left: 25.4px;
          height: 14px;
          font-size: 14px;
          font-weight: 500;
          color: #202630;
        }
        .uid {
          font-size: 12px;
          font-weight: 500;
          height: 12px;
          margin-top: 8px;
          margin-left: 25.4px;
          color: #202630;
          opacity: 0.5;
        }
        .kyc {
          width: 80px;
          height: 20px;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          background-color: #f9bf32;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          .kyc-status {
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
          .kyc-text {
            font-size: 12px;
            color: #fff;
          }
        }
      }
      .el-menu-item {
        height: 54px;
        line-height: 54px;
        padding: 0 25px;
        min-width: 250px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
        color: #202630 !important;
        &.border-top {
          border-top: 1px solid #f4f4f4;
        }
        i {
          // color: #909399 !important;
          font-size: 21.5px;
          margin-right: 19.4px;
        }

        &:hover {
          i {
            color: #303133 !important;
          }
        }
      }
    }

    .sub-submenu-a {
      line-height: 36px;
      color: #909399;
      &:hover {
        background-color: #fff;
        color: #12161c;
        .sub-submenu-i {
          color: #12161c;
        }
      }
    }

    .sub-item-wrap-sub ::v-deep .el-menu--popup-right-start {
      margin-left: 1px;
      margin-right: 5px;
      width: 50px !important;
    }
  }
  .el-submenu__title a.none-active:after {
    background-color: transparent;
  }
}
</style>
