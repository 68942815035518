"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiChannelPartnerList = apiChannelPartnerList;
exports.apiGetBannerList = apiGetBannerList;
exports.apiGetMainV2 = apiGetMainV2;
exports.apiGetcustermerUrl = apiGetcustermerUrl;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

var detailsUrl = "https://top6688.zendesk.com/api/v2/help_center/zh-cn/sections/360005486458/articles";

function apiGetBannerList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/App/Product/bannerList"),
    data: data
  });
}

function apiGetcustermerUrl() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Publics/getcustermerUrl"),
    data: data
  });
}

function apiGetMainV2() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_SOTC, "/SecretOtc/App/getMainV2"),
    data: data
  });
}

function apiChannelPartnerList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_APP, "/App/ChannelPartner/list"),
    data: data
  });
}