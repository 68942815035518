"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserInfo = exports.getStaticTableList = exports.getStaticTableInfo = exports.editUserInfo = void 0;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _stationSetting = require("@/assets/js/stationSetting");

// 获取用户信息
var getUserInfo = function getUserInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!_jsCookie.default.get("topcredit_exchange_t")) return;
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/getUserInfo",
    data: data,
    type: "POST"
  });
}; // 修改用户信息


exports.getUserInfo = getUserInfo;

var editUserInfo = function editUserInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!_jsCookie.default.get("topcredit_exchange_t")) return;
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/editUserInfo",
    data: data,
    type: "POST"
  });
}; // 获取静态表


exports.editUserInfo = editUserInfo;

var getStaticTableList = function getStaticTableList() {
  return (0, _api.default)(_stationSetting.VUE_APP_UCENTER + "/Settings/getStaticTableList");
}; // 获取静态表详情


exports.getStaticTableList = getStaticTableList;

var getStaticTableInfo = function getStaticTableInfo(data) {
  return (0, _api.default)(_stationSetting.VUE_APP_UCENTER + "/Settings/getStaticTableInfo", data);
};

exports.getStaticTableInfo = getStaticTableInfo;