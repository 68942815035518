<template>
  <div class="maskExCareersDetails" v-loading="loading">
    <div class="top">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{
          translateTitle("首页")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/company/careers/' }">{{
          translateTitle("招贤纳士")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/company/careersDetalis' }">{{
          translateTitle("详情")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="banner">
      <div class="content">
        <div class="left">
          <div class="title">{{ translateTitle(info.title) }}</div>
          <p>
            {{ translateTitle(info.country_name_lang) }} /
            {{ translateTitle(info.job_type_lang) }}
          </p>
          <a
            href="https://maskexglobal.sg.larksuite.com/share/base/form/shrlgsGSJB3ocuFoHvDZa7WBfJg"
            target="view_window"
            >{{ translateTitle("Apply for this job") }}</a
          >
        </div>
        <div
          class="right"
          @click="
            $router.push({
              path: '/company/careers',
            })
          "
        >
          <span class="icon iconfont">&#xe60c;</span>
          {{ translateTitle("Back to All Jobs") }}
        </div>
      </div>
    </div>
    <div class="careers">
      <div class="title">{{ translateTitle("Job Brief Description") }}:</div>
      <!-- <p v-html="translateTitle(contentHtml.description)"></p> -->
      <p v-for="item in description" :key="item">{{ item }}</p>
    </div>
    <div class="careers">
      <div class="title">{{ translateTitle("Responsibilities") }}:</div>
      <p v-for="item in requirements" :key="item">{{ item }}</p>
    </div>
    <div class="careers">
      <div class="title">{{ translateTitle("Requirements") }}:</div>
      <p v-for="item in responsibilities" :key="item">{{ item }}</p>
    </div>
    <div class="careers" v-if="scheduleBoolean">
      <div class="title">{{ translateTitle("Schedule") }}:</div>
      <p v-for="item in schedule" :key="item">{{ item }}</p>
    </div>
    <!-- <div class="careers" v-html="contentHtml"></div> -->
    <div class="non">
      <a
        class="classA"
        target="view_window"
        href="https://maskexglobal.sg.larksuite.com/share/base/form/shrlgsGSJB3ocuFoHvDZa7WBfJg"
        >{{ translateTitle("Apply for this job") }}</a
      >
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import { apiRecruitGetIntroduceInfo } from "@/model/recruitment/recruitment";
export default {
  mixins: [commonMixin],
  data() {
    return {
      description: "",
      schedule: "",
      responsibilities: "",
      scheduleBoolean: false,
      requirements: "",
      loading: false,
      info: null,
    };
  },
  created() {
    this.info = this.$route.query;
    this.apiRecruitGetIntroduceInfoHttp(this.$route.query);
  },
  mounted() {
    // translateTitle;
    // this.autoStart();
  },
  methods: {
    async apiRecruitGetIntroduceInfoHttp(id) {
      // 根据参数查询 招聘详情
      this.contentHtml = {};
      this.loading = true;
      const { status, data } = await apiRecruitGetIntroduceInfo(id);
      this.loading = false;
      if (status == 200) {
        let htmlContent = {};
        data ? (htmlContent = data) : null;
        this.schedule = this.arrDeleteNullRepeat(htmlContent.schedule_lang);
        this.requirements = this.arrDeleteNullRepeat(
          htmlContent.requirements_lang
        );
        this.scheduleBoolean = htmlContent.schedule.trim();
        this.responsibilities = this.arrDeleteNullRepeat(
          htmlContent.responsibilities_lang
        );
        this.description = this.arrDeleteNullRepeat(
          htmlContent.description_lang
        );
      }
    },
    arrDeleteNullRepeat(value) {
      //数组去重复 删除 null undfund 去空格
      if (!value) {
        return "";
      }
      return [...new Set(this.translateTitle(value).split("\n"))].filter(
        i => i && i.trim()
      );
    },
  },
};
</script>
<style lang="scss">
.maskExCareersDetails {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  .top {
    width: 800px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .banner {
    width: 100%;
    background: #f8f8f8;
    height: 230px;
    .content {
      width: 800px;
      margin: 0 auto;
      height: 230px;
      position: relative;
      .title {
        padding-top: 34px;
        font-size: 26px;
        font-weight: bold;
        text-align: left;
        color: #15192a;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        padding-top: 13px;
        text-align: left;
        color: #666;
      }
      a {
        margin-top: 45px;
        display: inline-block;
        border-radius: 3px;
        background-color: #15beb4;
        padding: 8px 25px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #fff;
      }
      .right {
        &:hover {
          color: rgb(1, 112, 42);
        }
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 100px;
        font-size: 18px;
        color: #15beb4;
      }
    }
  }
  .careers {
    width: 800px;
    margin: 0 auto;
    .title {
      padding: 50px 0;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #15192a;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      padding: 5px 0;
      letter-spacing: normal;
      text-align: left;
      color: #15192a;
    }
  }
  .non {
    display: flex;
    align-items: center;
    justify-items: center;
  }
  .classA {
    margin: 45px auto;
    display: inline-block;
    border-radius: 3px;
    background-color: #15beb4;
    padding: 8px 25px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #fff;
  }
}
</style>
