"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.json.stringify.js");

var _accuracy = require("@/assets/js/accuracy");

var _settings = require("@/model/settings");

var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));

var _i18n = require("@/assets/js/i18n");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["data", "assetsList", "exchangeLimit", "chainMap"],
  data: function data() {
    return {
      currChainValue: "ERC20",
      rate_out: "--" // 提币手续费

    };
  },
  created: function created() {
    this.currChainValue = this.data.itemDetail.default_chain.toUpperCase();

    _SocketDispatcher.default.addEventListener("onChainChangeDispatch", this.getStaticChainInfo, this);

    this.getStaticChainInfo(this.currChainValue);
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    getStaticChainInfo: function getStaticChainInfo(val) {
      var _this = this;

      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      this.currChainValue = val.toUpperCase();
      (0, _settings.apiGetStaticTableInfo)(29).then(function (_ref) {
        var data = _ref.data;

        for (var key in data) {
          if (_this.currChainValue.toLowerCase() == data[key].chain_tag && _this.data.item_id == data[key].item_id) {
            _this.rate_out = data[key].rate_out || "--";
          }
        }
      });
    }
  },
  computed: {
    hasParam: function hasParam() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        return true;
      } else {
        return false;
      }
    },
    auditNum: function auditNum() {
      if (this.hasParam) {
        var auditNum = this.exchangeLimit.auditNum;
        return auditNum;
      } else {
        return "--";
      }
    },
    limitNum: function limitNum() {
      if (this.hasParam) {
        var limitNum = this.exchangeLimit.limitNum;
        var coinOutNum = this.exchangeLimit.coinOutNum;
        var remaining = limitNum - coinOutNum;
        return remaining;
      } else {
        return "--";
      }
    },
    coinOutNum: function coinOutNum() {
      if (this.hasParam) {
        var coinOutNum = this.exchangeLimit.coinOutNum;
        return coinOutNum;
      } else {
        return "--";
      }
    },
    usdtAuditNum: function usdtAuditNum() {
      if (this.hasParam) {
        var usdtAuditNum = this.exchangeLimit.usdtAuditNum;
        return usdtAuditNum;
      } else {
        return "--";
      }
    },
    usdtLimitNum: function usdtLimitNum() {
      if (this.hasParam) {
        var usdtLimitNum = this.exchangeLimit.usdtLimitNum;
        return usdtLimitNum;
      } else {
        return "--";
      }
    },
    usdtCoinOutNum: function usdtCoinOutNum() {
      if (this.hasParam) {
        var usdtCoinOutNum = this.exchangeLimit.usdtCoinOutNum;
        return usdtCoinOutNum;
      } else {
        return "--";
      }
    }
  }
};
exports.default = _default;