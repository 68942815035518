"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accuracy = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.array.slice.js");

/* 保留小数精度 (单纯截取，不向上向下取整) */
var accuracy = function accuracy(num, digit) {
  num = num.toString();

  if (num.indexOf('.') === -1) {
    if (digit !== 0) {
      num = num + '.';
      num = loopAddZero(num, digit);
    }
  } else {
    var digitLength = num.split('.')[1].length;

    if (digitLength < digit) {
      num = loopAddZero(num, digit - digitLength);
    } else {
      if (digit === 0) {
        num = num.slice(0, num.indexOf('.') + digit);
      } else {
        num = num.slice(0, num.indexOf('.') + digit + 1);
      }
    }
  }

  return num;
}; // 补零


exports.accuracy = accuracy;

var loopAddZero = function loopAddZero(num, frequency) {
  var zero = '';

  for (var i = 0; i < frequency; i++) {
    zero = zero + '0';
  }

  num = num + zero;
  return num;
};