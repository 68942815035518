<template>
  <div class="page-header-bv-day">
    <div class="left-pages">
      <a :href="homeUrl">
        <img
          :draggable="false"
          class="logo"
          :src="logImg"
          alt="small gold 8v.com logo"
        />
      </a>
      <ul class="pages-routes">
        <!-- 新个人中心菜单 -->
        <a
          @mouseenter="mouseenterUserCenter"
          @mouseleave="mouseleaveUserCenter"
        >
          <li>
            <a class="menu-more-a"
              ><img :src="menu_more" class="menu-more" />
              <i
                class="iconfont icon-top"
                :class="
                  activeUserCenter ? 'iconxiala-copy-copy' : 'iconxiala-copy'
                "
              ></i>
            </a>
          </li>
          <div class="li-sub-menu-user-center" v-if="activeUserCenter">
            <div class="menu-item-group">
              <div
                class="menu-item"
                @click="goTo(routeSplicing(language, 'ucenter/situation'))"
                @mouseenter="mouseenterUserCenterItem('personal')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'personal' || hoverActive == 'personal'
                      ? menu_personal_on
                      : menu_personal
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'personal' ? 'on' : ''"
                  >{{ translateTitle("个人总览") }}</span
                >
              </div>
              <div
                class="menu-item"
                @click="goTo(routeSplicing(language, 'ucenter/assets'))"
                @mouseenter="mouseenterUserCenterItem('asset')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'asset' || hoverActive == 'asset'
                      ? menu_asset_on
                      : menu_asset
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'asset' ? 'on' : ''"
                  >{{ translateTitle("资产管理") }}</span
                >
              </div>
              <div
                class="menu-item"
                @click="goTo(routeSplicing(language, 'ucenter/tradeManagment'))"
                @mouseenter="mouseenterUserCenterItem('trade')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'trade' || hoverActive == 'trade'
                      ? menu_trade_on
                      : menu_trade
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'trade' ? 'on' : ''"
                  >{{ translateTitle("交易管理") }}</span
                >
              </div>
              <div
                class="menu-item"
                @click="
                  goTo(routeSplicing(language, 'ucenter/finanManagementTemp'))
                "
                @mouseenter="mouseenterUserCenterItem('earn')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'earn' || hoverActive == 'earn'
                      ? menu_earn_on
                      : menu_earn
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'earn' ? 'on' : ''"
                  >{{ translateTitle("理财管理") }}</span
                >
              </div>
            </div>
            <div class="menu-item-group">
              <div
                class="menu-item"
                @click="goTo(routeSplicing(language, 'ucenter/spread-center'))"
                @mouseenter="mouseenterUserCenterItem('referrals')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'referrals' || hoverActive == 'referrals'
                      ? menu_referrals_on
                      : menu_referrals
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'referrals' ? 'on' : ''"
                  >{{ translateTitle("推广中心") }}</span
                >
              </div>
              <div
                class="menu-item"
                @click="goTo(routeSplicing(language, 'ucenter/coupon-center'))"
                @mouseenter="mouseenterUserCenterItem('coupon')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'coupon' || hoverActive == 'coupon'
                      ? menu_coupon_on
                      : menu_coupon
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'coupon' ? 'on' : ''"
                  >{{ translateTitle("卡券中心") }}</span
                >
              </div>
              <div
                class="menu-item"
                @click="goTo(routeSplicing(language, 'ucenter/bill-center'))"
                @mouseenter="mouseenterUserCenterItem('transaction')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="
                    ucenterActive == 'transaction' ||
                    hoverActive == 'transaction'
                      ? menu_transaction_on
                      : menu_transaction
                  "
                  class="item-icon"
                />
                <span
                  class="item-text"
                  :class="ucenterActive == 'transaction' ? 'on' : ''"
                  >{{ translateTitle("账单") }}</span
                >
              </div>
              <div
                class="menu-item"
                @click="goLink('help')"
                @mouseenter="mouseenterUserCenterItem('help')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="hoverActive == 'help' ? menu_help_on : menu_help"
                  class="item-icon"
                />
                <span class="item-text">{{ translateTitle("帮助中心") }}</span>
              </div>
            </div>
            <div class="menu-item-group">
              <div
                class="menu-item"
                @click="goLink('annou')"
                @mouseenter="mouseenterUserCenterItem('help')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="hoverActive == 'help' ? menu_annou_on : menu_annou"
                  class="item-icon"
                />
                <span class="item-text">{{ translateTitle("公告中心") }}</span>
              </div>
              <div
                class="menu-item"
                @click="onDownloadTipsDialog"
                @mouseenter="mouseenterUserCenterItem('chat')"
                @mouseleave="mouseleaveUserCenterItem()"
              >
                <img
                  :src="hoverActive == 'chat' ? menu_chat_on : menu_chat"
                  class="item-icon"
                />
                <span class="item-text">{{ translateTitle("聊天室") }}</span>
              </div>
            </div>
          </div>
        </a>
        <!--  -->

        <a :href="routeSplicing(language, `markets`)">
          <li :class="active == 1 ? 'active' : ''">
            <a :href="routeSplicing(language, `markets`)">{{
              translateTitle("行情")
            }}</a>
          </li>
        </a>

        <!-- 新菜单开始 -->
        <a @mouseenter="mouseenterTrade" @mouseleave="mouseleaveTrade">
          <li :class="active == 2 ? 'active' : ''">
            <a
              >{{ translateTitle("交易") }}
              <i
                class="iconfont icon-top"
                :class="activeTrade ? 'iconxiala-copy-copy' : 'iconxiala-copy'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeTrade">
            <a class="it" :href="routeSplicing(language, 'exchange/btc_usdt')">
              <img
                src="../../assets/img/bv/header-icon/xh.png"
                class="i"
              /><span class="t">{{ translateTitle("现货交易") }} </span>
              <i class="iconfont iconright act"></i>
            </a>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img/bv/header-icon/cj.png"
                class="i"
              /><span class="t">{{ translateTitle("杠杆交易") }} </span>
              <i class="iconfont iconright act"></i>
            </div>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img/bv/header-icon/hd.png"
                class="i"
              /><span class="t">{{ translateTitle("汇兑") }} </span>
              <i class="iconfont iconright act"></i>
            </div>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img/bv/header-icon/otc.png"
                class="i"
              /><span class="t">{{ translateTitle("OTC") }} </span>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>

        <a @mouseenter="mouseenterDeri" @mouseleave="mouseleaveDeri">
          <li :class="active == 3 || active == 6 ? 'active' : ''">
            <a
              >{{ translateTitle("衍生品") }}
              <i
                class="iconfont icon-top"
                :class="activeDeri ? 'iconxiala-copy-copy' : 'iconxiala-copy'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeDeri">
            <a
              class="it"
              :href="routeSplicing(language, `contract-exchange/btcusdt`)"
            >
              <img
                src="../../assets/img/bv/header-icon/ubw.png"
                class="i"
              /><span class="t">{{ translateTitle("U本位合约") }} </span>
              <i class="iconfont iconright act"></i>
            </a>

            <a class="it" :href="routeSplicing(language, `delivery/ethusd`)">
              <img
                src="../../assets/img/bv/header-icon/bbw.png"
                class="i"
              /><span class="t">{{ translateTitle("币本位合约") }} </span>
              <i class="iconfont iconright act"></i>
            </a>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img/bv/header-icon/gd.png"
                class="i"
              /><span class="t">{{ translateTitle("跟单") }} </span>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>

        <a @mouseenter="mouseenterEarn" @mouseleave="mouseleaveEarn">
          <li :class="active == 4 ? 'active' : ''">
            <a
              >{{ translateTitle("金融理财") }}
              <i
                class="iconfont icon-top"
                :class="activeEarn ? 'iconxiala-copy-copy' : 'iconxiala-copy'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeEarn">
            <a class="it" :href="routeSplicing(language, `finances`)">
              <img
                src="../../assets/img/bv/header-icon/lc.png"
                class="i"
              /><span class="t">{{ translateTitle("理财") }} </span>
              <i class="iconfont iconright act"></i>
            </a>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img/bv/header-icon/df.png"
                class="i"
              /><span class="t">{{ translateTitle("DeFi") }} </span>
              <i class="iconfont iconright act"></i>
            </div>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img/bv/header-icon/ykj.png"
                class="i"
              /><span class="t">{{ translateTitle("云矿机") }} </span>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>
        <!-- 新菜单结束 -->

        <!-- <a :href="`/metaverse/${language}`"> -->
        <a :href="routeSplicing(language, `metaverse`)">
          <li :class="active == 8 ? 'active' : ''">
            <!-- <a :href="`/metaverse/${language}`">{{ -->
            <a>{{ translateTitle("元宇宙") }}</a>
          </li>
        </a>
      </ul>
    </div>
    <div class="right-menus">
      <span
        class="station-name"
        v-if="app_env == 'test' || app_env == undefined"
      >
        {{ envName }}
      </span>

      <el-menu class="right-menu" mode="horizontal" @select="handleSelect">
        <template>
          <!-- 个人中心 -->
          <UserMenu8v></UserMenu8v>
        </template>
        <template>
          <!-- 注册 -->
          <el-menu-item class="nav-menu-item login" v-show="!userInfoShow">
            <router-link
              active-class="none-active"
              :to="routeSplicing(language, 'login')"
              >{{ translateTitle("登录") }}</router-link
            >
          </el-menu-item>
          <!-- 注册 -->
          <el-menu-item
            class="nav-menu-item register theme-btn"
            v-show="!userInfoShow"
          >
            <router-link
              active-class="none-active"
              :to="routeSplicing(language, 'register')"
              >{{ translateTitle("注册") }}</router-link
            >
            <div class="driver"></div>
          </el-menu-item>
          <!-- 站内信 -->
          <!-- <el-menu-item class="nav-menu-item letter">
            <img
              @click="onDownloadTipsDialog"
              src="../../assets/img/bv/header-icon/letter.png"
              class="letter-img"
            />
          </el-menu-item> -->
        </template>

        <template>
          <li
            class="download-app"
            @mouseenter="onShowDownLoad"
            @mouseleave="onHiddenDownLoad"
          >
            <span class="driver"></span>
            <span class="download-app-text">
              <!-- <i class="iconfont iconicon-hd-3"></i> -->
              <span class="app-text">{{ translateTitle("APP下载") }}</span>
            </span>
            <div v-show="showDownLoadImg" class="down-box">
              <div>
                <p class="down-load-text">
                  {{ translateTitle("扫码下载iOS&Android APP") }}
                </p>
                <span class="img-content">
                  <p class="left-item">
                    <span class="down-load-item-text">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img/apple.png"
                        alt="8v app iOS download icon"
                      />
                      iPhone
                    </span>
                    <span class="down-load-item-text V2">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img/andriod.png"
                        alt="8v app andriod download icon"
                      />
                      Android
                    </span>
                  </p>
                  <img class="down-load-url" :src="appDownLoadImg" alt="" />
                </span>
              </div>
            </div>
          </li>
        </template>
        <!-- 法币 -->

        <!-- <el-submenu
          class="select-fiat"
          popper-class="sub-item-wrap  select-fiat-currency"
          index="6"
          :collapse-transition="false"
          :popper-append-to-body="true"
        > -->
        <el-submenu
          class="select-fiat"
          popper-class="sub-item-wrap  select-fiat-currency"
          index="6"
          :collapse-transition="false"
          :popper-append-to-body="false"
        >
          <template slot="title">
            <router-link class="lang" to="#">
              {{ currency }}
            </router-link>
          </template>
          <el-menu-item
            class="sub-item"
            v-for="(item, index) in currencyMaps"
            :key="index"
            :index="item.logic_name"
            @click="onCurrencyChange(item.logic_name)"
          >
            <span>
              <img :src="item.icon" />
              {{ item.abbreviate }}
            </span>
          </el-menu-item>
        </el-submenu>
        <!-- 语言 -->
        <li
          class="el-submenu sub-item-wrap select-lang-mask-v2"
          @click="onMaskLangDialog"
        >
          <img :src="imgUrl" />
          <span>
            {{ maskLanguageName }}
          </span>
        </li>
        <!-- 白夜版切换 -->
      </el-menu>
    </div>

    <el-dialog
      custom-class="langVisible"
      class="moreLangageWinodws"
      :visible.sync="langVisible"
    >
      <ul>
        <li
          v-for="item in countryList"
          :key="item.value"
          @click="onLangClick(item)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </el-dialog>

    <el-dialog
      custom-class="downloadTipsVisible"
      :visible.sync="downloadTipsVisible"
    >
      <p class="t">{{ translateTitle("APP下载提示") }}</p>
      <p class="c">
        {{
          translateTitle("您可下载") +
            " 8V-APP " +
            translateTitle("使用该功能业务,该WEB功能正在开发中，敬请期待！")
        }}
      </p>
      <div class="d">
        <div class="b">
          <a :href="appstore_url_new">
            <!-- <img src="../../assets/img/bv/header-icon/as-day.png" class="as" /> -->
            <img src="../../assets/img/bv/header-icon/gp-day.png" class="as" />
          </a>
          <a :href="android_url_new">
            <img
              src="../../assets/img/bv/header-icon/apk-day.png"
              class="apk"
            />
          </a>
        </div>
        <div class="q">
          <img :src="appDownLoadImg" class="code" />
          <span class="qt">{{
            translateTitle("扫码下载iOS&Android APP")
          }}</span>
        </div>
      </div>
      <div class="confirm" @click="closeDownloadTipsDialog">
        {{ translateTitle("好的") }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import { apiGetStaticTableInfo } from "@/model/settings";
import QrcodeVue from "qrcode.vue";
import UserMenu8v from "./user-menu-8v";
import zhCN from "../../assets/img/lang/f-cn.png";
import zhHK from "../../assets/img/lang/f-hk.png";
import enUS from "../../assets/img/lang/f-en.png";
import Ja from "../../assets/img/lang/f-ja.png";
import Ko from "../../assets/img/lang/f-ko.png";

import ar from "../../assets/img/lang/f-ar.png";
import fr from "../../assets/img/lang/f-fr.png";
import es from "../../assets/img/lang/f-es.png";
import ru from "../../assets/img/lang/f-ru.png";
import de from "../../assets/img/lang/f-de.png";
import pt from "../../assets/img/lang/f-pt.png";
import hi from "../../assets/img/lang/f-hi.png";
import vi from "../../assets/img/lang/f-vi.png";
import tl from "../../assets/img/lang/f-tl.png";
import th from "../../assets/img/lang/f-th.png";
import tr from "../../assets/img/lang/f-tr.png";
import it from "../../assets/img/lang/f-it.png";
import id from "../../assets/img/lang/f-id.png";
import logImg from "../../assets/img/logo_8V.png";

import menu_more from "../../assets/img/bv/hearder-menu-icon/menu-more.png";
import menu_annou_on from "../../assets/img/bv/hearder-menu-icon/menu-annou-on.png";
import menu_annou from "../../assets/img/bv/hearder-menu-icon/menu-annou.png";
import menu_asset_on from "../../assets/img/bv/hearder-menu-icon/menu-asset-on.png";
import menu_asset from "../../assets/img/bv/hearder-menu-icon/menu-asset.png";
import menu_chat_on from "../../assets/img/bv/hearder-menu-icon/menu-chat-on.png";
import menu_chat from "../../assets/img/bv/hearder-menu-icon/menu-chat.png";
import menu_coupon_on from "../../assets/img/bv/hearder-menu-icon/menu-coupon-on.png";
import menu_coupon from "../../assets/img/bv/hearder-menu-icon/menu-coupon.png";
import menu_earn_on from "../../assets/img/bv/hearder-menu-icon/menu-earn-on.png";
import menu_earn from "../../assets/img/bv/hearder-menu-icon/menu-earn.png";
import menu_help_on from "../../assets/img/bv/hearder-menu-icon/menu-help-on.png";
import menu_help from "../../assets/img/bv/hearder-menu-icon/menu-help.png";
import menu_personal_on from "../../assets/img/bv/hearder-menu-icon/menu-personal-on.png";
import menu_personal from "../../assets/img/bv/hearder-menu-icon/menu-personal.png";
import menu_referrals_on from "../../assets/img/bv/hearder-menu-icon/menu-referrals-on.png";
import menu_referrals from "../../assets/img/bv/hearder-menu-icon/menu-referrals.png";
import menu_trade_on from "../../assets/img/bv/hearder-menu-icon/menu-trade-on.png";
import menu_trade from "../../assets/img/bv/hearder-menu-icon/menu-trade.png";
import menu_transaction_on from "../../assets/img/bv/hearder-menu-icon/menu-transaction-on.png";
import menu_transaction from "../../assets/img/bv/hearder-menu-icon/menu-transaction.png";

import { VUE_APP_ENV } from "@/assets/js/stationSetting";
import { getLangPackage } from "@/assets/js/lang";
import { apiWsUrl } from "@/model/exchange/index";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    UserMenu8v,
    // QrcodeVue,
  },
  data() {
    return {
      homeUrl: "",
      app_env: "",
      languageName: "简体中文",
      maskLanguageName: "English",
      currency: "CNY",
      appDownLoadImg: "",
      appstore_url: "",
      tipUrl: "",
      google_play_url: "",
      langVisible: false,
      downloadTipsVisible: false,
      showDownLoadImg: false,
      currencyMaps: {},
      logImg,
      imgUrl: require("../../assets/img/en.png"),
      countryList: [
        {
          name: "English",
          value: "en",
          img: enUS,
        },
        {
          name: "日本語",
          value: "ja",
          img: Ja,
        },
        {
          name: "한국인",
          value: "ko",
          img: Ko,
        },

        {
          name: "Français",
          value: "fr",
          img: fr,
        },
        {
          name: "Español",
          value: "es",
          img: es,
        },
        {
          name: "Pусский",
          value: "ru",
          img: ru,
        },
        {
          name: "Deutsch",
          value: "de",
          img: de,
        },
        {
          name: "Português",
          value: "pt",
          img: pt,
        },
        {
          name: "हिंदी",
          value: "hi",
          img: hi,
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          img: vi,
        },
        {
          name: "Pilipino",
          value: "tl",
          img: tl,
        },
        {
          name: "ภาษาไทย",
          value: "th",
          img: th,
        },
        {
          name: "Türk",
          value: "tr",
          img: tr,
        },
        {
          name: "Italiano",
          value: "it",
          img: it,
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          img: id,
        },
        {
          name: "عربي",
          value: "ar",
          img: ar,
        },
        {
          name: "中文简体",
          value: "zh_CN",
          img: zhCN,
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          img: zhHK,
        },
      ],
      activeUserCenter: false,
      activeTrade: false,
      activeDeri: false,
      activeEarn: false,
      activeMore: false,
      android_url_new: "",
      appstore_url_new: "",

      menu_more,
      menu_annou_on,
      menu_annou,
      menu_asset_on,
      menu_asset,
      menu_chat_on,
      menu_chat,
      menu_coupon_on,
      menu_coupon,
      menu_earn_on,
      menu_earn,
      menu_help_on,
      menu_help,
      menu_personal_on,
      menu_personal,
      menu_referrals_on,
      menu_referrals,
      menu_trade_on,
      menu_trade,
      menu_transaction_on,
      menu_transaction,
      hoverActive: "",
    };
  },
  created() {
    this.homeUrl = this.language == "en" ? `/` : `/${this.language}`;
    this.app_env = VUE_APP_ENV;

    this.initURL();
    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    this.appDownLoadImg = tempUrl.url;
    this.appstore_url = tempUrl.appstore_url;
    this.google_play_url = tempUrl.google_play_url;

    let currency = localStorage.getItem("currency") || "CNY";
    let tempLang = localStorage.getItem("language");
    this.countryList.forEach(ele => {
      if (ele.value == tempLang) {
        this.imgUrl = require("../../assets/img/lang/f-" + ele.value + ".png");
        this.maskLanguageName = ele.name;
      }
    });

    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
    }

    this.handleSelect(currency);
  },
  mounted() {
    // let tempList = JSON.parse(localStorage.getItem("settingList"));
    // if (tempList) {
    //   this.currencyMaps = tempList[7];
    // }
    this.getCurrencyMap();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),
    envName() {
      let tempVal = localStorage.getItem("environment");
      return tempVal;
    },
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    active() {
      let val = 0;
      if (this.$route.path.includes("/markets")) {
        val = 1;
      } else if (this.$route.path.includes("/exchange")) {
        val = 2;
      } else if (this.$route.path.includes("/contract-exchange")) {
        val = 3;
      } else if (this.$route.path.includes("/finances")) {
        val = 4;
      } else if (this.$route.path.includes("/mining-pool")) {
        val = 5;
      } else if (this.$route.path.includes("/delivery")) {
        val = 6;
      } else if (this.$route.path.includes("/quicklycoins")) {
        val = 7;
      } else if (this.$route.path.includes("/metaverse")) {
        val = 8;
      } else {
        val = 0;
      }
      return val;
    },
    //个人中心菜单激活
    ucenterActive() {
      let target = "";
      const ucenterPath = [
        { path: "/situation", target: "personal" },
        { path: "/assets", target: "asset" },
        { path: "/tradeManagment", target: "trade" },
        { path: "/finanManagementTemp", target: "earn" },
        { path: "/spread-center", target: "referrals" },
        { path: "/coupon-center", target: "coupon" },
        { path: "/bill-center", target: "transaction" },
      ];
      ucenterPath.forEach(item => {
        if (this.$route.path.includes(item.path)) {
          target = item.target;
        }
      });
      return target;
    },
  },
  methods: {
    routeSplicing,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    goTo(target) {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
        return;
      }
      this.$router.push(target);
    },
    mouseenterUserCenterItem(flag) {
      this.hoverActive = flag;
    },
    mouseleaveUserCenterItem() {
      this.hoverActive = "";
    },
    goLink(flag) {
      let url = "";
      if (flag == "help") {
        switch (this.language) {
          case "zh_CN":
            url = "https://info.8v.com/hc/zh-cn";
            break;
          case "zh_HK":
            url = "https://info.8v.com/hc/zh-tw";
            break;
          case "en":
            url = "https://info.8v.com/hc/en-001";
            break;

          default:
            url = "https://info.8v.com/hc/en-001";
            break;
        }
      }
      if (flag == "annou") {
        switch (this.language) {
          case "zh_CN":
            url = "https://info.8v.com/hc/zh-cn/categories/4413705023129";
            break;
          case "zh_HK":
            url =
              "https://info.8v.com/hc/zh-tw/categories/4413705023129-官方公告";
            break;
          case "en":
            url =
              "https://info.8v.com/hc/en-001/categories/4413705023129-Official-Announcements";
            break;

          default:
            url =
              "https://info.8v.com/hc/en-001/categories/4413705023129-Official-Announcements";
            break;
        }
      }
      window.open(url);
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        // this.wsUrl = data.ws_url;
        this.android_url_new = data.android_url;
        this.appstore_url_new = data.appstore_url;
      }
    },
    mouseenterUserCenter() {
      this.activeUserCenter = true;
    },
    mouseleaveUserCenter() {
      this.activeUserCenter = false;
    },
    mouseenterTrade() {
      this.activeTrade = true;
    },
    mouseleaveTrade() {
      this.activeTrade = false;
    },
    mouseenterDeri() {
      this.activeDeri = true;
    },
    mouseleaveDeri() {
      this.activeDeri = false;
    },
    mouseenterEarn() {
      this.activeEarn = true;
    },
    mouseleaveEarn() {
      this.activeEarn = false;
    },
    mouseenterMore() {
      this.activeMore = true;
    },
    mouseleaveMore() {
      this.activeMore = false;
    },
    onChangeStyle() {
      if (this.darkMode == 1) {
        this.changeDarkMode(2);
      } else {
        this.changeDarkMode(1);
      }
    },
    async getCurrencyMap() {
      const { status, data } = await apiGetStaticTableInfo(7);
      if (status == 200) {
        this.currencyMaps = JSON.parse(JSON.stringify(data));
      }
    },
    onCustomerServiceClick() {
      this.$emit("onApiClick");
    },
    onCurrencyChange(key) {
      this.currency = key.toUpperCase();
      this.changeCurrency(key);
    },
    handleSelect(key) {
      let command = "zh_CN";
      if (key == "zh_CN") {
        command = "zh_CN";
        this.languageName = "简体中文";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "zh_HK") {
        command = "zh_HK";
        this.languageName = "繁體中文";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "en") {
        command = "en";
        this.languageName = "English";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ja") {
        command = "ja";
        this.languageName = "日本語";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ko") {
        command = "ko";
        this.languageName = "한국어";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "cny") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "hkd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "aed") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "jpy") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "krw") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "usd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "try") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "mmk") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "php") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "sgd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "thb") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "vnd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "brl") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      }
    },
    onMaskLangDialog() {
      this.langVisible = true;
    },
    onDownloadTipsDialog() {
      this.downloadTipsVisible = true;
    },
    closeDownloadTipsDialog() {
      this.downloadTipsVisible = false;
    },
    onLangClick(item) {
      this.imgUrl = require("../../assets/img/lang/f-" + item.value + ".png");
      const langPackage = getLangPackage([item.value]);
      this.maskLanguageName = item.name;
      // this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
      this.changeLanguage(item.value);
      this.$i18n.locale = item.value;

      this.langVisible = false;
      let urlPathArray = this.$route.path.split("/");
      let lastIsLang = false;
      this.countryList.forEach(ele => {
        if (ele.value == urlPathArray[urlPathArray.length - 1]) {
          lastIsLang = true;
        }
      });
      if (!lastIsLang) {
        // let newUrl = urlPathArray.join("/");
        // window.location.href = `${window.location.protocol}//${window.location.host}${newUrl}`;
        // window.location.reload();
        // this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
        let newUrl = urlPathArray.join("/");
        newUrl.endsWith("/")
          ? (newUrl = newUrl + item.value)
          : (newUrl = newUrl + "/" + item.value);
        window.location.href = `${window.location.protocol}//${window.location.host}${newUrl}`;
      } else {
        if (item.value == "en") {
          urlPathArray[urlPathArray.length - 1] = "";
          this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
        } else {
          urlPathArray[urlPathArray.length - 1] = item.value;
        }
        let newUrl = urlPathArray.join("/");
        window.location.href = `${window.location.protocol}//${window.location.host}${newUrl}`;
      }
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
  },
};
</script>
<style lang="scss">
.el-submenu.sub-item-wrap.select-lang-mask-v2 {
  min-width: 162px;
}
.moreLangageWinodws {
  min-width: 780px;
}
.zh_CN {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 120px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.zh_HK {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 120px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.hi {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 145px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.it {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 183px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.de {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 182px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.vi {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 193px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.tr {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 141px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.tl {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.ru {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.th {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.pt {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-bv-day .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.en {
  .page-header-bv-day {
    min-width: 1468px;
    .right-menus .el-menu .download-app {
      width: 170px;
    }
  }
  .page-header-bv-day .right-menus .el-menu .download-app .down-box {
    width: 290px;
  }
  .page-header-bv-day
    .right-menus
    .el-menu
    .el-submenu
    .el-menu--horizontal
    .el-menu {
    width: 195px !important;
  }
}
.fr {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 221px;
    }
  }
}
.es {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 247px;
    }
  }
}
.ko {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 144px;
    }
  }
}
.ja {
  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 202px;
    }
  }
  .page-header-bv-day .right-menus .el-menu .download-app .down-box {
    width: 280px;
    height: 166px;
  }
  .page-header-bv-day
    .right-menus
    .el-menu
    .el-submenu
    .el-menu--horizontal
    .el-menu {
    width: 180px !important;
  }
}
.ar {
  .page-header-bv-day {
    direction: ltr !important;
  }

  .page-header-bv-day {
    .right-menus .el-menu .download-app {
      width: 130px;
    }
  }
  .user-center-menu.el-menu-item.nav-menu-item.login
    .el-menu--horizontal.sub-item-wrap.select-user-item
    .el-menu
    .el-menu-item {
    text-align: right;
  }
}
</style>
<style lang="scss">
/* yuan language innerBody */
//修改Lang宽度
.select-lang-mask-v2 {
  height: 60px;
  line-height: 57px;
  // line-height: 60px;
  opacity: 1;
  cursor: pointer;
  color: #acb9db;
  &:hover {
    opacity: 1;
  }
  img {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  span {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
.el-menu--horizontal.sub-item-wrap.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
  &.V2 {
    margin-right: 20px;
  }
  top: 59px !important;
  &.merchant-item {
    width: 135px;
  }
  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    width: 130px !important;
    min-width: 130px !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;
      span {
        margin-left: 0px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

//修改货币宽度
.el-menu--horizontal.sub-item-wrap.select-fiat-currency {
  &.merchant-item {
    width: 135px;
  }
  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;
      &:hover {
        background-color: #f3f7fc;
      }
      span {
        margin-left: 0px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}
.select-fiat-currency {
  top: 60px !important;
  // margin-top: 59px !important;
  // top: 0px !important;
  &.el-menu--horizontal {
    width: 140px !important;
  }
  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}

/* yuan language innerBody */
.page-header-bv-day {
  user-select: none;
  height: 60px;
  min-width: 1355px !important;
  // background-color: #112132;
  background-color: #112132;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .right-menu {
    display: flex;
    margin-block-start: 0.8em;
    margin-block-end: 1em;
    height: 56px;
  }
  .logo {
    width: 180px;
    height: auto;
    display: block;
  }
  .downloadTipsVisible {
    z-index: 999;
    width: 400px;
    min-height: 400px;
    padding: 30px;
    display: flex;
    color: #112132;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    .t {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .c {
      font-size: 14px;
      margin-bottom: 35px;
    }
    .d {
      display: flex;
      justify-content: space-between;
      height: 126px;
      .b {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-shrink: 0;
        .as {
          width: 150px;
          height: 50px;
        }
        .apk {
          width: 150px;
          height: 50px;
        }
      }
      .q {
        margin-left: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        .code {
          width: 96px;
          height: 96px;
        }
        .qt {
          font-size: 14px;
          line-height: 1.43;
          word-break: break-word;
        }
      }
    }
    .confirm {
      cursor: pointer;
      margin-top: 40px;
      width: 200px;
      height: 40px;
      border-radius: 3px;
      background-image: linear-gradient(77deg, #ebac3d 21%, #f9bf32 82%);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .langVisible {
    min-width: 700px;
    z-index: 999;
    ul {
      min-width: 700px;
      margin-left: 20px;
    }

    li {
      cursor: pointer;
      display: inline-block;
      width: 25%;
      padding: 10px 10px;
      &:hover {
        color: black;
      }
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .left-pages,
  .right-menus,
  .el-submenu__title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  // 左侧
  .left-pages {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .pages-routes {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-left: 45px;
      position: relative;
      li {
        color: #fff;
        height: 60px;
        font-size: 14px;
        font-weight: 400;
        line-height: 60px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        color: #acb9db;

        a {
          color: #fff;
          // opacity: 0.6;
          font-size: 14px;
          text-decoration: none;
          display: inline-block;
          height: 100%;
        }

        &.none-active {
          width: 100%;
          border-bottom: 3px solid #f7b239;
          color: #fff;
          font-weight: 500;
        }
        &:hover {
          // font-weight: 500;
          color: #fff;
          a {
            color: #fff;
            opacity: 1;
          }
        }
        &.active {
          border-bottom: 0px !important;
          color: #f7b239;
          a {
            opacity: 1;
            // color: #fff;
            color: #f7b239;
          }
        }
      }

      .normal-link {
        padding: 0px;

        a {
          padding: 0 20px;
        }
      }
      .menu-more-a {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    .li-sub-menu {
      position: absolute;
      z-index: 666;
      padding: 10px 0;
      // background-color: #112132;
      background-color: rgba(17, 33, 50, 0.6);
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      border-radius: 4px;
      .it {
        cursor: pointer;
        min-width: 190px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        .i {
          width: 25px;
          height: 25px;
        }
        .t {
          color: #fff;
          margin-left: 10px;
          margin-right: 20px;
          font-size: 14px;
        }
        .act {
          display: none;
          font-size: 10px;
          margin-left: auto;
          color: #f7b239;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          .act {
            display: inherit;
          }
        }
      }
    }
    .li-sub-menu-user-center {
      position: absolute;
      margin-left: -50px;
      width: 735px;
      padding: 12px 30px;
      border-radius: 4px;
      background-color: rgba(17, 33, 50, 0.6);
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      display: flex;
      z-index: 2500;
      .menu-item-group {
        display: flex;
        flex-direction: column;
        margin-right: 62px;
        .menu-item {
          margin-bottom: 19px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            .item-text {
              color: #f7b239;
            }
          }
          .item-icon {
            width: 25px;
            height: 25px;
            object-fit: contain;
          }
          .item-text {
            margin-left: 12px;
            font-size: 12px;
            letter-spacing: 0.88px;
            color: #fff;
            &.on {
              color: #f7b239;
            }
          }
        }
      }
    }

    .menu-more {
      width: 22px;
      height: 20px;
      object-fit: contain;
      margin-right: 5px;
    }
    .icon-top {
      display: inline-block;
      margin-top: -3px;
    }
    .mirrorRotateLevel {
      display: inline-block;
      transform: rotate(180deg); /* 垂直镜像翻转 */
    }
  }

  .app-link {
    font-size: 14px;
  }

  // 右侧
  .right-menus {
    margin-right: 23px;
    height: 60px;
    .customer-service {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
    .station-name {
      color: red;
      font-size: 24px;
      margin-right: 10px;
    }
    .el-submenu__icon-arrow {
      font-size: 16px;
      color: #acb9db;
    }

    .el-menu {
      // background-color: #112132;
      background-color: #112132;
      text-align: left;
      .letter-center {
        float: left;
        width: 40px;
        line-height: 22px;
        margin-top: 20px;
        position: relative;
        img {
          width: 22px;
          height: 22px;
          top: 0px;
          cursor: pointer;
        }
        .driver {
          position: absolute;
          background-color: rgb(172, 185, 219, 0.3);
          right: 0px;
          top: -2px;
          width: 1px;
          height: 30px;
        }
      }
      .download-app {
        float: left;
        position: relative;
        width: fit-content;
        height: 60px;
        outline: none;
        border-radius: 4px;
        .download-app-text {
          display: block;
          height: 100%;
          font-size: 14px;
          color: #ffffff;
          // opacity: 0.6;
          // line-height: 60px;
          padding: 0 20px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            color: #ffffff;
            opacity: 1;
          }
          .app-text {
            // width: 126px;
            // height: 36px;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 1.6px;
            border: solid 0.4px #f7b239;
            color: #f7b239;
          }
        }
        .down-box {
          box-sizing: border-box;
          width: 265px;
          height: 150px;
          padding: 18px 30px 18px 19px;
          border-radius: 4px;
          box-shadow: 0 2px 10px 0 rgba(53, 64, 82, 0.1);
          background-color: #ffffff;
          display: block;
          position: relative;
          z-index: 3000;
          .down-load-text {
            color: #9aa1b1;
            font-size: 12px;
            font-weight: 500;
            .icon-title {
              width: 16px;
              height: 16px;
              vertical-align: text-top;
            }
          }
          .down-load-content {
            margin-top: 10px;
            display: flex;
            justify-content: space-around;
            text-align: center;
            .text-title {
              margin-bottom: 10px;
            }
            img {
              width: 70px;
              height: 70px;
            }
          }
          .img-content {
            display: flex;
            margin-top: 24px;
            .qr_code_canvas {
              margin-top: -10px;
            }
            .down-load-url {
              margin-top: -20px;
              height: 80px;
              width: 80px;
            }
            .down-load-item-text {
              display: inline-block;
              height: 20px;
              font-size: 14px;
              color: #354052;
              .icon-title {
                width: 19px;
                height: 21px;
                vertical-align: middle;
                margin-right: 10px;
              }
              &.V2 {
                margin-top: 17px;
              }
            }
            .down-load-img {
              width: 59px;
              height: 59px;
            }
          }
        }
      }
      .select-fiat {
        margin-right: 20px;
        .el-submenu__title {
          .el-icon-arrow-down {
            margin-top: -2px;
            margin-left: 5px;
            &:before {
              content: url("../../assets/img/bv/arrow-down.png");
            }
          }
        }
      }
      &.el-menu--horizontal {
        border-bottom: none;
      }

      .sub-item-child {
        color: red;
        #sub-item-asset & {
          &:hover {
            a {
              color: #000;
            }
          }
        }
        &.el-submenu {
          &:hover {
            a {
              color: #000;
            }
          }
        }
        .el-submenu__title {
          display: inline-block;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding-left: 10px;
          .sub-item-child-jump:hover {
            color: #000;
          }
          a:hover {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            line-height: 14px;
            display: inline-block;
          }
        }
      }
      .el-submenu {
        &:hover {
          a {
            color: #ffffff;
          }
          i {
            color: #ffffff;
          }
        }
        a {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          // opacity: 0.6;
          line-height: 14px;
          &:hover {
            opacity: 1;
          }
        }
        .el-menu--horizontal {
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              &:hover {
                background-color: #f3f7fc;
              }
              &.hide-icon {
                i {
                  visibility: hidden;
                }
              }
              i {
                margin-right: 10px;
                position: relative;
                top: -1px;
                color: inherit;
              }

              &.is-active {
                background-color: #fff !important;
              }
            }
          }
        }
        //修改Lang宽度

        .el-menu--horizontal.sub-item-wrap.select-lang {
          top: 59px !important;
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            width: 130px !important;
            min-width: 130px !important;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;
              span {
                margin-left: 0px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        //修改货币宽度
        .el-menu--horizontal.sub-item-wrap.select-fiat-currency {
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;
              &:hover {
                background-color: #f3f7fc;
              }
              span {
                margin-left: 0px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
      .no-arrow {
        .el-submenu__icon-arrow {
          display: none;
        }
      }
      .el-submenu__title {
        border-bottom: 0;
        padding: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 60px;
          box-sizing: border-box;
          &.none-active {
            color: #fff;
            background-color: #112132;
            &:after {
              content: "";
              height: 3px;
              width: 50px;
              background-color: #f7b239;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          &:hover {
            color: #fff;
            background-color: #112132;
          }
        }
      }
      .el-submenu__title:hover {
        background-color: #112132;
        text-align: left;
        color: #ffffff;
        a {
          text-decoration: none;
        }
      }

      .nav-menu-item {
        color: #fff;
        padding: 0;
        border-bottom: none;
        .letter-img {
          user-select: none;
          cursor: pointer;
          width: 24px;
          height: 24px;
          line-height: 30px;
          display: inline-block;
        }
        &.letter {
          margin-left: 20px;
          line-height: 58px;
          &:focus {
            background-color: transparent;
          }
        }
        &.login {
          a {
            color: #ffffff;
            // opacity: 0.6;
            &.none-active {
              color: #fff;
              opacity: 1;
              background-color: #112132;
            }
            &:hover {
              color: #fff;
              opacity: 1;
            }
          }
        }
        .driver {
          position: absolute;
          right: -40px;
          top: 0px;
          width: 1px;
          height: 30px;
          margin: 0 15px 0 22px;
          background-color: rgb(255, 255, 255, 0.5);
        }

        &.register {
          position: relative;
          margin-right: 25px;
          background-color: #f7b239 !important;
          background: #f7b239 !important;
          height: 30px;
          margin-top: 15px;
          line-height: 30px;
          border-radius: 2px !important;
          border-right: 1px;
          border-right: 1px soild #acb9db;
          .none-active {
            border-radius: 2px;
          }

          &:hover {
            background: rgba(198, 27, 61, 0.8);
            color: #f7b239;
          }
          a {
            line-height: 30px;
            // color: white;
            color: #000;
            border-radius: 4px;
            &.none-active {
              color: #fff;
              background-color: #f7b239;
            }
          }
        }
        &:hover {
          background-color: #112132;
          color: #f7b239;
        }
        a {
          display: block;
          width: 100%;
          // height: 100%;
          // height: 60px;
          line-height: 60px;
          padding: 0 20px;
          box-sizing: border-box;
          text-decoration: none;
          color: #acb9db;
          &.none-active {
            color: #fff;
            background-color: rgba(40, 41, 45, 1);
          }
        }
      }
    }

    .lang {
      img {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 10px;
        filter: brightness(200);
        -webkit-filter: brightness(150%);
      }
    }
  }

  .divider {
    width: 1px;
    height: 24px;
    background: rgba(216, 216, 216, 1);
    opacity: 0.18;
    position: relative;
    top: 20px;
  }
}
.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
  &.V2 {
    margin-right: 20px;
  }
}
.select-fiat-currency {
  // top: 59px !important;
  &.el-menu--horizontal {
    width: 140px !important;
  }
  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}
</style>
