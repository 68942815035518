"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurDayDate = getCurDayDate;
exports.getDayDate = getDayDate;
exports.getDaySoonEnd = getDaySoonEnd;
exports.getNowDate = getNowDate;
exports.getNowDateTime = getNowDateTime;
exports.getNowTime = getNowTime;
exports.getPrevWeek = getPrevWeek;
exports.getPrevWeekDate = getPrevWeekDate;
exports.getSomeDay = getSomeDay;
exports.getValueTime = getValueTime;
exports.reformatting = reformatting;
exports.shortDatetime = shortDatetime;
exports.splicing = splicing;

require("core-js/modules/es.error.cause.js");

// import moment from 'moment'
// 获取当前日期时间 xxxx-xx-xx xx:xx:xx
function getNowDateTime() {
  var nowDate = new Date();
  var time = nowDate.getFullYear() + '-' + splicing(nowDate.getMonth() + 1) + '-' + splicing(nowDate.getDate()) + ' ' + splicing(nowDate.getHours()) + ':' + splicing(nowDate.getMinutes()) + ':' + splicing(nowDate.getSeconds());
  return time;
} // 获取当前日期 xxx-xx-xx


function getNowDate() {
  var nowDate = new Date();
  var time = nowDate.getFullYear() + '-' + splicing(nowDate.getMonth() + 1) + '-' + splicing(nowDate.getDate());
  return time;
} // 获取当前时间 xx:xx:xx


function getNowTime() {
  var nowDate = new Date();
  var time = splicing(nowDate.getHours()) + ':' + splicing(nowDate.getMinutes()) + ':' + splicing(nowDate.getSeconds());
  return time;
} // 时间格式化 xx:xx:xx


function getValueTime(value) {
  var nowDate = new Date(value);
  var time = splicing(nowDate.getHours()) + ':' + splicing(nowDate.getMinutes()) + ':' + splicing(nowDate.getSeconds());
  return time;
} // 获取上一周日期时间 xxxx-xx-xx xx:xx:xx


function getPrevWeek() {
  var prevWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  var time = prevWeek.getFullYear() + '-' + splicing(prevWeek.getMonth() + 1) + '-' + splicing(prevWeek.getDate()) + ' ' + splicing(prevWeek.getHours()) + ':' + splicing(prevWeek.getMinutes()) + ':' + splicing(prevWeek.getSeconds());
  return time;
} // 获取上一周日期 xxxx-xx-xx


function getPrevWeekDate() {
  var prevWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  var time = prevWeek.getFullYear() + '-' + splicing(prevWeek.getMonth() + 1) + '-' + splicing(prevWeek.getDate());
  return time;
} // 获取某天凌晨-下一天凌晨


function getDayDate(date) {
  var newDate = new Date(date);
  var dateTime = newDate.getFullYear() + '-' + splicing(newDate.getMonth() + 1) + '-' + splicing(newDate.getDate()) + ' 00:00:00';
  var time = new Date(newDate).getTime() + 1 * 24 * 60 * 60 * 1000;
  var nextDate = new Date(time);
  var nextDateTime = nextDate.getFullYear() + '-' + splicing(nextDate.getMonth() + 1) + '-' + splicing(nextDate.getDate()) + ' ' + '00:00:00';
  return [dateTime, nextDateTime];
} // 获取某天凌晨-当天23：59：59


function getCurDayDate(date) {
  var newDate = new Date(date);
  var dateTime = newDate.getFullYear() + '-' + splicing(newDate.getMonth() + 1) + '-' + splicing(newDate.getDate()) + ' 00:00:00';
  var time = new Date(newDate).getTime();
  var nextDate = new Date(time);
  var nextDateTime = nextDate.getFullYear() + '-' + splicing(nextDate.getMonth() + 1) + '-' + splicing(nextDate.getDate()) + ' ' + '23:59:59';
  return [dateTime, nextDateTime];
} // 获取 当天时间-以前某一天凌晨


function getSomeDay() {
  var day = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var today = new Date();
  var todayTime = today.getFullYear() + '-' + splicing(today.getMonth() + 1) + '-' + splicing(today.getDate());
  var someTime = new Date(today).getTime() - day * 24 * 60 * 60 * 1000;
  var someDay = new Date(someTime);
  var someDayTime = someDay.getFullYear() + '-' + splicing(someDay.getMonth() + 1) + '-' + splicing(someDay.getDate());

  if (time) {
    todayTime = todayTime + ' ' + splicing(today.getHours()) + ':' + splicing(today.getMinutes()) + ':' + splicing(today.getSeconds());
    someDayTime = someDayTime + ' 00:00:00';
  }

  return [someDayTime, todayTime];
} // 获取 某天最后时刻 xxxx-xx-xx 23:59:59


function getDaySoonEnd(date) {
  var newDate = new Date(date);
  var dateTime = newDate.getFullYear() + '-' + splicing(newDate.getMonth() + 1) + '-' + splicing(newDate.getDate()) + ' 23:59:59';
  return dateTime;
} // function getSomeDayByMoment(day = 0) {
//   return [moment().subtract(day, 'days').unix(), moment().unix()]
// }
// 转换格式 获取 年-月-日 时:分:秒/ bool = true 获取 年-月-日


function reformatting(date) {
  var bool = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var newDate = new Date(date);
  var time = newDate.getFullYear() + '-' + splicing(newDate.getMonth() + 1) + '-' + splicing(newDate.getDate());

  if (bool) {
    return time;
  } else {
    time = time + ' ' + splicing(newDate.getHours()) + ':' + splicing(newDate.getMinutes()) + ':' + splicing(newDate.getSeconds());
  }

  return time;
} // 转换格式 获取 月-日 时:分


function shortDatetime(date) {
  var newDate = new Date(date);
  var time = splicing(newDate.getMonth() + 1) + '-' + splicing(newDate.getDate()) + ' ' + splicing(newDate.getHours()) + ':' + splicing(newDate.getMinutes());
  return time;
} // 小于 10 的，前面拼接 0


function splicing(num) {
  if (typeof num !== 'number') {
    throw new Error('Splicing incoming values must be number type!');
  }

  var str = num + '';
  str = str[1] ? str : '0' + str;
  return str;
}