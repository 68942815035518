"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _layout = _interopRequireDefault(require("./layout"));

var _SideMenu = _interopRequireDefault(require("./components/SideMenu"));

var _Content = _interopRequireDefault(require("./components/Content"));

var _i18n = require("@/assets/js/i18n");

//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      voucherInterval: null
    };
  },
  destroyed: function destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  components: {
    Layout: _layout.default,
    SideMenu: _SideMenu.default,
    RContent: _Content.default
  },
  methods: {
    translateTitle: _i18n.translateTitle
  }
};
exports.default = _default;