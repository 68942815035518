"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetMessageList = apiGetMessageList;
exports.apiReadMessage = apiReadMessage;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
//读取消息
function apiReadMessage() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Common/readMessage",
    data: data,
    type: "POST"
  });
} //获取站内行列表


function apiGetMessageList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Common/getMessageList",
    data: data,
    type: "POST"
  });
}