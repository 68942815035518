"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.join.js");

var _message = require("@/components/dialog/message");

var _i18n = require("@/assets/js/i18n");

var _billCenter = require("@/model/ucenter/billCenter");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "add-edit-api-key",
  props: {
    currRow: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    currType: {
      type: [Number]
    }
  },
  data: function data() {
    return {
      form: {
        name: "",
        ip_list: "",
        status: 1
      },
      rules: {
        google_code: [{
          required: true,
          trigger: "blur",
          message: this.translateTitle("谷歌码不能为空")
        }],
        status: [{
          required: true,
          trigger: "blur",
          message: this.translateTitle("状态不能为空")
        }],
        name: [{
          required: true,
          trigger: "blur",
          message: this.translateTitle("名称不能为空")
        }]
      }
    };
  },
  created: function created() {
    if (this.currType != 1) {
      this.form.name = this.currRow.name;
      this.form.ip_list = this.currRow.ip_list.join(",");
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    onCancel: function onCancel() {
      this.$emit("update-cancel");
    },
    onAdd: function onAdd() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (!valid) {
          return;
        }

        var req = (0, _objectSpread2.default)({}, _this.form);

        if (_this.currType == 1) {
          (0, _billCenter.apiApiSecretAdd)(req).then(function (rs) {
            if (rs.status == 200) {
              //   this.$message("添加成功", "success");
              (0, _message.showMessage)({
                message: _this.translateTitle("\u6DFB\u52A0\u6210\u529F"),
                type: "success"
              });

              _this.$emit("update-success");
            }
          });
        } else {
          req.id = _this.currRow.id;
          (0, _billCenter.apiApiSecretEdit)(req).then(function (rs) {
            if (rs.status == 200) {
              (0, _message.showMessage)({
                message: _this.translateTitle("\u7F16\u8F91\u6210\u529F"),
                type: "success"
              });

              _this.$emit("update-success");
            }
          });
        }
      });
    }
  }
};
exports.default = _default2;