"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

var _homeContent = _interopRequireDefault(require("./home-content.vue"));

var _maskHomeContent = _interopRequireDefault(require("./mask-home-content.vue"));

var _bvHomeContent = _interopRequireDefault(require("./bv-home-content.vue"));

var _vuex = require("vuex");

var _bitdaHomeContent = _interopRequireDefault(require("./bitda-home-content.vue"));

var _hxHomeContent = _interopRequireDefault(require("./hx-home-content.vue"));

var _stationSetting = require("@/assets/js/stationSetting");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import BitdaHomeContent from "./bitda-home-content.vue";
// import BvHomeContent from "./bv-home-content.vue";
var _default = {
  components: {
    HomeContent: _homeContent.default,
    MaskHomeContent: _maskHomeContent.default,
    BvHomeContent: _bvHomeContent.default,
    HxHomeContent: _hxHomeContent.default,
    BitdaHomeContent: _bitdaHomeContent.default
  },
  data: function data() {
    return {
      VUE_APP_ID: _stationSetting.VUE_APP_ID,
      isMask: false,
      is8v: false,
      isbitda: false,
      isHx: false,
      isDefault: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    darkMode: "user/darkMode"
  })),
  created: function created() {
    if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.isMask = true;
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.isbitda = true;
    } else if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.is8v = true;
    } else if (_stationSetting.VUE_UNION_STATION == "TopCredit") {
      this.isHx = true;
    } else {
      this.isDefault = true;
    }
  }
};
exports.default = _default;