"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

require("core-js/modules/es.array.concat.js");

var _stationSetting = require("@/assets/js/stationSetting");

function _default(language, path) {
  if (language == "en" && _stationSetting.VUE_UNION_STATION == "8V") {
    return "/".concat(path);
  } else {
    return "/".concat(path, "/").concat(language);
  }
}