"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ceilNumber = ceilNumber;
exports.filterNumber = filterNumber;
exports.floorNumber = floorNumber;
exports.formatThousandth = formatThousandth;
exports.getDecimalLength = getDecimalLength;
exports.getUuid = getUuid;
exports.paddingNumberRight = paddingNumberRight;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.array.find-index.js");

require("core-js/modules/es.array.slice.js");

var _bignumber = _interopRequireDefault(require("bignumber.js"));

// import { debug } from "util";

/**
 * 向下取小数，截断
 * 如3.1415926
 * 1. 保留4位小数 => 3.1415
 * 2. 保留2位小数 => 3.14
 * @param  {Number|String} num      原始数字
 * @param  {Number} presicion 	精度
 * @return {[type]}           [description]
 */
function floorNumber(num, presicion) {
  if (!num) {
    num = 0;
  }

  var str = String(num);
  var intAndDecimal = str.split(".");
  var intPart = intAndDecimal[0] || "";
  var decimalPart = intAndDecimal[1] || "";

  if (presicion <= decimalPart.length) {
    decimalPart = decimalPart.substr(0, presicion);
  } else {
    var len = presicion - decimalPart.length || 0;
    var zero = Array(len + 1).join(0);
    decimalPart += zero;
  }

  if (decimalPart) {
    return intPart + "." + decimalPart;
  }

  return intPart;
}
/**
 向上取小数
	如3.1415926 
	1. 保留4位小数 => 3.1416
	2. 保留2位小数 => 3.15
	实现原理
	然这个数，安全的放大presicion 倍，然后Math.ceil
	1.220002
	1.220002*100 = 122.0002
	Math.ceil 122.0002 = 123
	123 /100 = 1.23
 * @param  {Number|String} num       原始数字
 * @param  {Number} presicion  	精度
 * @return {[type]}           [description]
 */


function ceilNumber(tempValue, presicion) {
  if (!tempValue) {
    tempValue = 0;
  }

  if (tempValue < 0) {
    var tempValueABS = Math.abs(tempValue);
    tempValue = Math.ceil(tempValueABS * Math.pow(10, presicion));
    tempValue = tempValue * 1.0 / Math.pow(10, presicion) * -1;
  } else {
    tempValue = Math.ceil(tempValue * Math.pow(10, presicion));
    tempValue = tempValue * 1.0 / Math.pow(10, presicion);
  }

  return tempValue;
} // 补全小数位


function paddingNumberRight(num, n) {
  var arr = num.toString().split(".");
  var dotLeft = arr[0];
  var dotRight = arr[1] || "";
  var str = "";
  var len = dotRight.length;
  dotRight = dotRight.substring(0, n);

  while (len < n) {
    str += "0";
    len++;
  }

  return dotLeft + "." + dotRight + str;
}
/**
 * 整数部分添加千分撇
 *
 * @param  {[type]} num [description]
 * @param  {[type]} presicion [小数位精度]
 * @return {[type]}     [description]
 */


function formatThousandth(num, presicion) {
  num = num + "";
  var priceDecimalArr = num.split(".");
  var intPart = priceDecimalArr[0] || "";
  var decimalPart = priceDecimalArr[1] || "";
  var parts = [];

  if (intPart) {
    var i = intPart.length;

    while (i > 0) {
      i = i - 3;
      var item = "";

      if (i <= 0) {
        item = intPart.substr(0, i + 3);
      } else {
        item = intPart.substr(i, 3);
      }

      parts.unshift(item);
    }
  }

  var intResult = parts.join(",");

  if (decimalPart) {
    if (presicion) {
      decimalPart = decimalPart.substr(0, presicion);
    }

    return intResult + "." + decimalPart;
  } else {
    return intResult;
  }
}
/**
 * 获取小数位长度
 * @param  {[type]} num [description]
 * @return {[type]}     [description]
 */


function getDecimalLength(num) {
  num = num + "";
  var priceDecimalArr = num.split(".");
  var decimalPart = priceDecimalArr[1] || "";
  return decimalPart.length;
}

function filterNumber(newValue, precision) {
  newValue = String(newValue); // 。转换成.

  newValue = newValue.replace(/[。，,]/, "."); // 对多个.判断

  if (newValue.indexOf(".") != newValue.lastIndexOf(".")) {
    var _index = newValue.lastIndexOf(".");

    var tempList = newValue.split("");
    tempList.splice(_index, 1);
    return tempList.join("");
  }

  var pattern2 = /\d|\./g;
  var arr = []; // 对非数字小数点判断

  newValue.replace(pattern2, function (match) {
    arr.push(match);
  }); // 精度截取

  var index = arr.findIndex(function (val) {
    if (val == ".") {
      return true;
    }
  });

  if (index != -1) {
    arr = arr.slice(0, index + precision + 1);
  }

  return arr.join("");
}

function getUuid() {
  var s = [];
  var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }

  s[14] = "4";
  s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";
  var uuid = s.join("");
  return uuid;
}