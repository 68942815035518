"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _i18n = require("@/assets/js/i18n");

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      checked: false
    };
  },
  mixins: [_commonMixin.default],
  methods: {
    pathJump: function pathJump() {
      window.open("https://forms.gle/kBrehoX1Y2wiuRpC8");
    }
  }
};
exports.default = _default;