"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.json.stringify.js");

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  data: function data() {
    return {
      formData: {
        old_password: "",
        password: "",
        confirmNewPwd: ""
      },
      formRules: {
        old_password: [{
          required: true,
          message: this.translateTitle("\u8BF7\u8F93\u5165\u539F\u767B\u5F55\u5BC6\u7801"),
          trigger: "blur"
        }, {
          min: 6,
          max: 20,
          message: this.translateTitle("\u5BC6\u7801\u957F\u5EA6\u4E3A 6-20 \u4F4D\u5B57\u7B26"),
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: this.translateTitle("\u8BF7\u8F93\u5165\u65B0\u767B\u5F55\u5BC6\u7801"),
          trigger: "blur"
        }, {
          min: 6,
          max: 20,
          message: this.translateTitle("\u5BC6\u7801\u957F\u5EA6\u4E3A 6-20 \u4F4D\u5B57\u7B26"),
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/,
          message: this.translateTitle("\u5BC6\u7801\u5FC5\u987B\u5305\u542B\u82F1\u6587\u548C\u6570\u5B57\uFF0C\u4E0D\u80FD\u5305\u542B\u7A7A\u683C")
        }, {
          validator: this.confirmOldNew,
          trigger: "blur"
        }],
        confirmNewPwd: [{
          validator: this.confirmBlur,
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    clearCode: function clearCode() {
      this.formData = {
        old_password: "",
        password: "",
        confirmNewPwd: ""
      };
    },
    // 重复新/旧密码校验
    confirmOldNew: function confirmOldNew(rule, value, callback) {
      var old_password = this.formData.old_password;

      if (value === "" || !value) {
        callback(new Error(this.translateTitle("请输入新登录密码")));
      } else if (value === old_password) {
        callback(new Error(this.translateTitle("新密码不能与原密码相同")));
      } else {
        callback();
      }
    },
    // 重复密码校验
    confirmBlur: function confirmBlur(rule, value, callback) {
      var password = this.formData.password;

      if (value === "" | !value) {
        callback(new Error(this.translateTitle("请输入确认密码")));
      } else if (value !== password) {
        callback(new Error(this.translateTitle("两次输入密码不一致!")));
      } else {
        callback();
      }
    },
    // 提交表单方法
    submitForm: function submitForm() {
      var statusData;
      var formData = this.formData;
      this.$refs.changePwdForm.validate(function (valid) {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });
      return JSON.parse(JSON.stringify(statusData));
    }
  }
};
exports.default = _default;