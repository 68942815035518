"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onSelectBlur = onSelectBlur;
exports.onSelectFilter = onSelectFilter;

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

function onSelectBlur(me, use, copy) {
  setTimeout(function () {
    me[use] = me[copy];
  }, 100);
}

function onSelectFilter(val, me, use, copy, value, label) {
  me[use] = me[copy].filter(function (item) {
    if (!val) {
      return true;
    }

    if (String(item[value]).indexOf(val) != -1 || String(item[label]).toUpperCase().indexOf(val.toUpperCase()) != -1) {
      return true;
    } else {
      return false;
    }
  });
}