"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    time: {
      type: Number,
      default: function _default() {
        return 60;
      }
    },
    step: {
      type: Number,
      default: function _default() {
        return 1;
      }
    },
    switch: {
      type: Boolean,
      default: function _default() {
        return null;
      }
    },
    format: {
      type: String
    }
  },
  data: function data() {
    return {
      timeDiff: 0,
      timer: null
    };
  },
  watch: {
    time: {
      handler: "onTimeChange",
      immediate: true
    },
    switch: {
      handler: "onSwitchChange"
    }
  },
  methods: {
    computedStep: function computedStep() {
      return this.step * 1000;
    },
    onTimeChange: function onTimeChange(val) {
      this.timeDiff = val;
      var isSwitch = typeof this.switch === "boolean";

      if (val && !this.timer) {
        if (isSwitch) {
          if (this.switch) {
            this.triggerTimer();
          } else {
            this.clearTimer();
          }
        } else {
          this.triggerTimer();
        }
      } else {
        this.clearTimer();
      }
    },
    onSwitchChange: function onSwitchChange(val) {
      if (val) {
        this.triggerTimer();
      } else {
        this.clearTimer();
      }
    },
    beforeDestroy: function beforeDestroy() {
      this.clearTimer();
    },
    // 清除定时器
    clearTimer: function clearTimer() {// if (this.timer) clearTimeout(this.timer);
    },
    // 触发倒计时函数
    triggerTimer: function triggerTimer() {
      var _this = this;

      this.timer = setTimeout(function () {
        _this.timeDiff--;

        if (_this.timeDiff <= 0) {
          _this.$emit("on-end");
        } else {
          _this.$emit("on-countdown", _this.timeDiff);

          _this.triggerTimer();
        }
      }, this.computedStep());
    },
    // 格式化时间戳
    formatCountdown: function formatCountdown(timeDiff) {
      // 获取还剩多少小时
      var hour = parseInt((Number(timeDiff) / 60 / 60).toString()); // 获取还剩多少分钟

      var minute;

      if (this.format.includes("hh") || this.format.includes("HH")) {
        minute = parseInt((Number(timeDiff) / 60 % 60).toString());
      } else {
        minute = parseInt((Number(timeDiff) / 60).toString());
      } // 获取还剩多少秒


      var second;

      if (this.format.includes("mm") || this.format.includes("mm")) {
        second = Number(timeDiff) % 60;
      } else {
        second = timeDiff;
      }

      var result = this.format;
      result = result.replace(/(hh|HH)/g, this.paddingZero(hour));
      result = result.replace(/(mm|MM)/g, this.paddingZero(minute));
      result = result.replace(/(ss|ss)/g, this.paddingZero(second));
      return result;
    },
    // 补零
    paddingZero: function paddingZero(val) {
      if (val <= 0) {
        return "00";
      } else if (val < 10) {
        return "0".concat(val);
      } else {
        return val.toString();
      }
    },
    // 重新倒计时
    reCountdown: function reCountdown() {
      this.timeDiff = this.time;
    }
  }
};
exports.default = _default2;