<template>
  <el-scrollbar id="app" :class="getLanguageClass">
    <header-hx v-if="isHx" @onApiClick="onApiClick"></header-hx>
    <header-eight-coin
      v-else-if="is8Coin"
      @onApiClick="onApiClick"
    ></header-eight-coin>

    <HeaderBitda v-else-if="isBitda"></HeaderBitda>
    <Header8v
      v-else-if="is8V"
      @onApiClick="onApiClick"
      ref="header8v"
    ></Header8v>
    <HeaderMask v-else-if="isMaskex" @onApiClick="onApiClick"></HeaderMask>
    <Header v-else @onApiClick="onApiClick"></Header>
    <div class="app-main">
      <router-view v-if="$route.meta.title == '首页'"></router-view>
      <router-view
        v-else
        v-wechat-title="
          translateTitle($route.meta.title) + ' | ' + translateTitle(title)
        "
      ></router-view>
    </div>
    <div class="top-icon" @click="onTopClick" v-show="gotop">
      <img src="./assets/img/mask/top-icon.png" alt="" />
    </div>
    <!-- <ChatWedget
      v-if="!isMaskex && !is8V && !is8Coin && !isHx"
      ref="ChatWedget" 
    />-->
    <FooterMaskex v-if="!hideFooter && isMaskex"></FooterMaskex>
    <Footer8v
      v-if="!hideFooter && is8V"
      @tipsDownload="tipsDownload"
      @chatOpen="onApiClick"
    ></Footer8v>
    <Footer
      v-if="!hideFooter && !isMaskex && !is8V"
      @onApiClick="onApiClick"
    ></Footer>
    <!-- visibility ="hidden" -->
    <div>
      <LiveChatWidget
        v-if="!hideCustomer && isB9"
        :visibility="hiddenCustomer"
        license="18258933"
        :group="groupId"
        :chatBetweenGroups="false"
        :sessionVariables="sessionVariables"
        v-on:new-event="handleNewEvent"
      />
    </div>
    <v8KYCDialog v-if="v8show" />
  </el-scrollbar>
</template>
<script>
import Header from "@/components/common/header.vue";
import HeaderHx from "@/components/common/header-hx.vue";
import HeaderEightCoin from "@/components/common/header-8coin";
import HeaderMask from "@/components/common/header-mask.vue";
import HeaderBitda from "@/components/common/header-bitda.vue";
import Header8v from "@/components/common/header-8v.vue";
import v8KYCDialog from "@/components/common/8v-KYC-dialog.vue";
import Footer from "@/components/common/footer.vue";
import Footer8v from "@/components/common/footer-bv.vue";
import FooterMaskex from "@/components/common/footer-maskex.vue";
import { translateTitle } from "@/assets/js/i18n";
// import ChatWedget from "./pages/customer-service/ChatWedget.vue";
import { mapActions, mapGetters } from "vuex";
import { VUE_CHANNEL_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { LiveChatWidget } from "@livechat/widget-vue/v2";
import { getLangPackage } from "@/assets/js/lang";
import seoMixin from "@/pages/seo/seoMixin.vue";
import seoMixinHx from "@/pages/seo/seoMixinHx.vue";
export default {
  name: "App",
  mixins: [seoMixin, seoMixinHx],
  components: {
    Header,
    HeaderMask,
    HeaderBitda,
    HeaderEightCoin,
    Header8v,
    Footer,
    Footer8v,
    FooterMaskex,
    // ChatWedget,
    LiveChatWidget,
    v8KYCDialog,
    HeaderHx,
  },
  data() {
    return {
      title: "数字货币银行",
      groupId: "5",
      hiddenCustomer: "",
      hideFooter: false,
      gotop: false,
      isMaskex: false,
      is8V: false,
      isS6: false,
      isB9: false,
      isUEEx: false,
      is5A: false,
      isBitda: false,
      is8Coin: false,
      isHx: false,
      isRouterAlive: true,
      hideCustomer: true,
      sessionVariables: {
        uid: "",
        email: "",
        google: "",
        mobile: "",
        page: "",
        kyc_level: "",
      },
      countryList: [
        {
          id: "English",
          value: "en",
          valueFull: "en_US",
          id: "6",
        },
        {
          name: "日本語",
          value: "ja",
          valueFull: "ja_JP",
          id: "6",
        },
        {
          name: "한국인",
          value: "ko",
          valueFull: "ko_KR",
          id: "6",
        },

        {
          name: "Français",
          value: "fr",
          valueFull: "fr_FR",
          id: "6",
        },
        {
          name: "Español",
          value: "es",
          valueFull: "es_ES",
          id: "6",
        },
        {
          name: "Pусский",
          value: "ru",
          valueFull: "ru_RU",
          id: "6",
        },
        {
          name: "Deutsch",
          value: "de",
          valueFull: "de_DE",
          id: "6",
        },
        {
          name: "Português",
          value: "pt",
          valueFull: "pt_BR",
          id: "6",
        },
        {
          name: "हिंदी",
          value: "hi",
          valueFull: "hi_IN",
          id: "6",
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          valueFull: "vi_VN",
          id: "6",
        },
        {
          name: "Pilipino",
          value: "tl",
          valueFull: "tl_PH",
          id: "6",
        },
        {
          name: "ภาษาไทย",
          value: "th",
          valueFull: "th_TH",
          id: "6",
        },
        {
          name: "Türk",
          value: "tr",
          valueFull: "tr_TR",
          id: "6",
        },
        {
          name: "Italiano",
          value: "it",
          valueFull: "it_IT",
          id: "6",
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          valueFull: "id_ID",
          id: "6",
        },
        {
          name: "عربي",
          value: "ar",
          valueFull: "ar_AR",
          id: "6",
        },
        {
          name: "中文简体",
          value: "zh_CN",
          valueFull: "zh_CN",
          id: "5",
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          valueFull: "zh_HK",
          id: "5",
        },
      ],
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
        this.sessionVariables.email = this.userInfo.authInfo.open_email
          ? "yes"
          : "no";
        this.sessionVariables.google =
          this.userInfo.authInfo.open_google == 1 ? "yes" : "no";
        this.sessionVariables.mobile =
          this.userInfo.authInfo.open_mobile == 1 ? "yes" : "no";
        let DOMAIL = document.location.pathname;
        if (DOMAIL.indexOf("/login") >= 0) {
          this.sessionVariables.page = "log";
        } else if (DOMAIL.indexOf("/register") >= 0) {
          this.sessionVariables.page = "reg";
        } else if (DOMAIL.indexOf("/quicklycoins") >= 0) {
          this.sessionVariables.page = "qui";
        } else if (DOMAIL.indexOf("/markets") >= 0) {
          this.sessionVariables.page = "mar";
        } else if (DOMAIL.indexOf("/contract-exchange") >= 0) {
          this.sessionVariables.page = "fut";
        } else if (DOMAIL.indexOf("/exchange") >= 0) {
          this.sessionVariables.page = "spo";
        } else if (DOMAIL.indexOf("/delivery") >= 0) {
          this.sessionVariables.page = "fcm";
        } else if (DOMAIL.indexOf("/finances") >= 0) {
          this.sessionVariables.page = "fim";
        } else if (DOMAIL.indexOf("/aboutUs") >= 0) {
          this.sessionVariables.page = "abo";
        } else if (DOMAIL.indexOf("/ucenter") >= 0) {
          this.sessionVariables.page = "uct";
        }
        this.sessionVariables.kyc_level =
          this.userInfo &&
          this.userInfo.kycInfo &&
          this.userInfo.kycInfo.kyc_level;
      }
    },
  },
  created() {
    this.setTheme();
    const DOMAIL = document.location.pathname;
    if (this.userInfo) {
      this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
      this.sessionVariables.email = this.userInfo.authInfo.open_email
        ? "yes"
        : "no";
      this.sessionVariables.google =
        this.userInfo.authInfo.open_google == 1 ? "yes" : "no";
      this.sessionVariables.mobile =
        this.userInfo.authInfo.open_mobile == 1 ? "yes" : "no";
    }

    if (DOMAIL.indexOf("/login") >= 0) {
      this.sessionVariables.page = "log";
    } else if (DOMAIL.indexOf("/register") >= 0) {
      this.sessionVariables.page = "reg";
    } else if (DOMAIL.indexOf("/quicklycoins") >= 0) {
      this.sessionVariables.page = "qui";
    } else if (DOMAIL.indexOf("/markets") >= 0) {
      this.sessionVariables.page = "mar";
    } else if (DOMAIL.indexOf("/contract-exchange") >= 0) {
      this.sessionVariables.page = "fut";
    } else if (DOMAIL.indexOf("/exchange") >= 0) {
      this.sessionVariables.page = "spo";
    } else if (DOMAIL.indexOf("/delivery") >= 0) {
      this.sessionVariables.page = "fcm";
    } else if (DOMAIL.indexOf("/finances") >= 0) {
      this.sessionVariables.page = "fim";
    } else if (DOMAIL.indexOf("/aboutUs") >= 0) {
      this.sessionVariables.page = "abo";
    } else if (DOMAIL.indexOf("/ucenter") >= 0) {
      this.sessionVariables.page = "uct";
    }
    this.sessionVariables.kyc_level =
      this.userInfo && this.userInfo.kycInfo && this.userInfo.kycInfo.kyc_level;
    this.$router.afterEach(() => {
      this.isHasFooter();
    });
    this.$router.beforeEach((to, from, next) => {
      this.isHasFooter();
      next();
    });
    this.isHasFooter();
    setInterval(() => {
      window.location.reload();
    }, 6 * 60 * 60 * 1000);

    this.countryList.forEach((ele) => {
      if (ele.value == this.language) {
        this.groupId = ele.id;
      }
    });
    this.chooseLanguage();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      v8show: "user/v8show",
      language: "user/language",
      darkMode: "user/darkMode",
    }),

    getLanguageClass() {
      let tempClass = "";
      tempClass = this.language;
      if (tempClass == "" || tempClass == null || tempClass == undefined) {
        if (this.is8V || this.isMaskex || this.is5A || this.isHx) {
          tempClass = "en";
        } else if (this.isS6 || this.isB9 || this.isBitda) {
          let jsSrc = (
            navigator.language || navigator.browserLanguage
          ).toLowerCase();
          if (jsSrc == "zh-hk" || jsSrc == "zh-tw") {
            tempClass = "zh_HK";
          } else if (jsSrc.indexOf("zh") >= 0) {
            // 假如浏览器语言是中文
            tempClass = "zh_CN";
          } else {
            // 假如浏览器语言是英文
            tempClass = "en";
          }
        } else {
          tempClass = "zh_CN";
        }
      }
      // window.location.search = `/lang=${tempClass}`;
      return tempClass;
    },
  },

  methods: {
    translateTitle,
    handleNewEvent(event) {
      // console.log("LiveChatWidget.onNewEvent", event);
    },
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    tipsDownload() {
      this.$refs.header8v.onDownloadTipsDialog();
    },
    onApiClick() {
      this.$refs.ChatWedget.onChatClick();
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    onTopClick() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    setTheme() {
      if (VUE_UNION_STATION == "B9") {
        this.isB9 = true;
        this.title = "数字理财平台";
        document.getElementsByTagName("body")[0].className = `vab-theme-bijiu`;
      } else if (VUE_UNION_STATION == "EverTrust") {
        this.title = "数字货币银行";
        document.getElementsByTagName("body")[0].className = `vab-theme-bijiu`;
      } else if (VUE_UNION_STATION == "BullxCow") {
        this.title = "数字货币银行";
        document.getElementsByTagName("body")[0].className = `vab-theme-purple`;
      } else if (VUE_UNION_STATION == "MaskEx") {
        this.isMaskex = true;
        this.title = "Cryptocurrency Exchange";
        document.getElementsByTagName("body")[0].className = "vab-theme-mask";
      } else if (VUE_UNION_STATION == "100Ex") {
        this.title = "数字货币银行";
        document.getElementsByTagName("body")[0].className = "vab-theme-100Ex";
      } else if (VUE_UNION_STATION == "8V") {
        this.is8V = true;
        // this.changeDarkMode(1);
        // this.is8V = false; LinkLanguage
        this.title = "8V.com - 全球首家元宇宙生态数字资产交易所";
        document.getElementsByTagName("body")[0].className = "vab-theme-8V";
        // let linkzh = document.createElement("link");
        // linkzh.setAttribute("rel", "canonical");
        // linkzh.setAttribute(
        //   "href",
        //   "https://" + window.location.host + "/" + this.language
        // );
        // document.head.appendChild(linkzh);
      } else if (VUE_UNION_STATION == "UEEx") {
        this.isUEEx = true;
        this.title = "UEEx | Cryptocurrency Exchange";
        document.getElementsByTagName("body")[0].className = "vab-theme-UEEx";
      } else if (VUE_UNION_STATION == "TopCredit") {
        this.isHx = true;
        this.title = "数字货币银行";
        document.getElementsByTagName("body")[0].className = "vab";
      } else if (VUE_UNION_STATION == "Ahex") {
        this.title = "Alpha Cryptocurrency Exchange";
        document.getElementsByTagName("body")[0].className = "vab-theme-ahex";
      } else if (VUE_UNION_STATION == "5A") {
        this.is5A = true;
        this.title = "Crypto Asset Exchange";
        //根据多语言变更meta
        let metaTag = document.getElementById("description");
        metaTag.name = "description";
        metaTag.content = this.translateTitle(
          "5A加密货币交易所 - 我们经营着全球交易量领先的比特币BTC交易平台和altcoin交易平台"
        );
        let metaTag2 = document.getElementById("keywords");
        metaTag2.name = "keywords";
        metaTag2.content = this.translateTitle(
          "5A交易所是全球首家用户友好型数字资产服务商，提供比特币BTC、莱特币LTC及以太坊ETH等多币种实时价格行情，拥有上百种数字资产交易及投资信息，买比特币就上5A交易所"
        );
        document.getElementsByTagName("body")[0].className = "vab-theme-5A";
      } else if (VUE_UNION_STATION == "bitda") {
        this.title = "Bitda Cryptocurrency Exchange 币达加密货币交易所";
        this.isBitda = true;
        document.getElementsByTagName("body")[0].className = "vab-theme-bitda";
      } else if (VUE_UNION_STATION == "S6") {
        this.isS6 = true;
        this.title = "数字货币私人银行";
        document.getElementsByTagName("body")[0].className = "vab-theme-S6";
      } else if (VUE_UNION_STATION == "easy") {
        this.title = "数字货币私人银行";
        document.getElementsByTagName("body")[0].className = "vab";
      } else if (VUE_UNION_STATION == "spex") {
        this.title = "数字货币私人银行";
        document.getElementsByTagName("body")[0].className = "vab-theme-spex";
      } else if (VUE_UNION_STATION == "8coin") {
        this.title = "数字资产交易所";
        this.is8Coin = true;
        document.getElementsByTagName("body")[0].className = `vab-theme-8coin`;
      } else if (VUE_CHANNEL_ID > 2000) {
        this.title = "数字资产交易所";
        document.getElementsByTagName("body")[0].className = `vab-theme-doge`;
      } else {
        this.title = "数字资产交易所";
        document.getElementsByTagName("body")[0].className = `vab-theme-purple`;
      }
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    showMaskexCustomerService() {
      const index1 = location.pathname.indexOf("/landingpage/register");
      if (index1 == -1) {
        this.showMaskexCustomer = true;
      } else {
        this.showMaskexCustomer = false;
      }
    },
    isHasFooter() {
      const index = location.pathname.indexOf("/login");
      const index1 = location.pathname.indexOf("/register");
      const index2 = location.pathname.indexOf("/assets");
      const index3 = location.pathname.indexOf("/forgetPwd");
      const index4 = location.pathname.indexOf("/LoginSecurity");
      const index5 = location.pathname.indexOf("/landingpage/register");
      if (
        index != -1 ||
        index1 != -1 ||
        index3 != -1 ||
        index4 != -1 ||
        index5 != -1
      ) {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
      if (index5 != -1) {
        this.hideCustomer = true;
        this.hiddenCustomer = "hidden";
      } else {
        this.hiddenCustomer = "";
        this.hideCustomer = false;
      }
    },

    chooseLanguage() {
      let lastIsLang = false;
      let lastpath = window.location.href.split("/");
      this.countryList.forEach((item) => {
        if (item.value == lastpath[lastpath.length - 1]) {
          lastIsLang = true;
        }
      });
      if (
        (VUE_UNION_STATION == "8V" || VUE_UNION_STATION == "TopCredit") &&
        !lastIsLang
      ) {
        const langPackage = getLangPackage(["en"]);
        this.$i18n.setLocaleMessage("en", langPackage["en"]);
        this.changeLanguage("en");
        this.$i18n.locale = "en";
        return;
      }
      if (
        VUE_UNION_STATION == "8V" &&
        (window.location.href.endsWith("en") ||
          window.location.href.endsWith("en/"))
      ) {
        this.changeLanguage("");
        window.location.href = window.location.href
          .replace("en/", "")
          .replace("en", "");
        return;
      }
      if (
        (VUE_UNION_STATION == "8V" || VUE_UNION_STATION == "TopCredit") &&
        lastIsLang
      ) {
        const langPackage = getLangPackage([lastpath[lastpath.length - 1]]);
        this.$i18n.setLocaleMessage(
          lastpath[lastpath.length - 1],
          langPackage[lastpath[lastpath.length - 1]]
        );
        this.changeLanguage(lastpath[lastpath.length - 1]);
        this.$i18n.locale = lastpath[lastpath.length - 1];
        return;
      }
      if (!(this.$route.params && this.$route.params.lang)) return;
      const langPackage = getLangPackage([this.$route.params.lang]);
      this.$i18n.setLocaleMessage(
        this.$route.params.lang,
        langPackage[this.$route.params.lang]
      );
      this.changeLanguage(this.$route.params.lang);
      this.$i18n.locale = this.$route.params.lang;
    },
  },
};
</script>

<style lang="scss">
#app {
  &.fr {
    .app-main .side-menu {
      width: 266px;
    }
  }
  &.es {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ru {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.de {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.vi {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.it {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.pt {
    .app-main .side-menu {
      width: 270px;
    }
  }
  &.tl {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ar {
    .app-main {
      .home-page {
        // direction: rtl;
      }
    }
  }
}
#app {
  display: flex;
  font-family: PingFang SC, robotoregular, Hiragino Sans GB, Heiti SC,
    Microsoft YaHei, WenQuanYi Micro Hei, Helvetica, Arial, monospace, serif !important;
  flex-direction: column;
  font-style: normal;
  // min-width: 1440px;
  height: 100%;
  min-width: 1180px;
  background: #ecedf1;
  &.ar {
    .el-scrollbar__wrap {
      margin-right: 0;
      margin-left: -15px;
    }
  }
  .el-scrollbar__wrap {
    height: 102%;
  }
  // .el-scrollbar__view {
  //   height: 100%;
  // }
  .app-main {
    flex: 1;
    min-height: calc(100% - 281px);
    .side-menu {
      padding: 0px 0;
      background: rgba(255, 255, 255, 1);
      // border-radius: 4px;
      overflow: hidden;
      width: 206px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      z-index: 999;
      height: auto;
      float: left;

      .nav {
        li {
          text-align: left;
          justify-content: left;
          padding-left: 30px;
          box-sizing: border-box;
          height: 40px; // jason要求改为40

          i {
            margin-right: 15px;
          }
        }
        li.active {
          color: #fff;
          background: linear-gradient(
            315deg,
            rgba(39, 198, 131, 1) 0%,
            rgba(39, 198, 131, 1) 100%
          );
        }
      }
    }
    .el-form-item {
      text-align: left !important;
    }
  }
  .top-icon {
    position: fixed;
    right: 40px;
    bottom: 14%;
    cursor: pointer;
    z-index: 99999;
  }
  .el-checkbox {
    &:hover {
      .el-checkbox__input {
        .el-checkbox__inner {
          border-color: #409eff;
        }
      }
    }
  }
}
</style>
