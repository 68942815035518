"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _i18n = require("@/assets/js/i18n");

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  methods: {
    translateTitle: _i18n.translateTitle
  }
};
exports.default = _default;