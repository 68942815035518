"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiBrokersProgram = apiBrokersProgram;
exports.apiFormSubmitAdvice = apiFormSubmitAdvice;
exports.apiRecruitGetCountryList = apiRecruitGetCountryList;
exports.apiRecruitGetIntroduceInfo = apiRecruitGetIntroduceInfo;
exports.apiRecruitGetIntroduceList = apiRecruitGetIntroduceList;
exports.apiRecruitGetJobList = apiRecruitGetJobList;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

var detailsUrl = "https://top6688.zendesk.com/api/v2/help_center/zh-cn/sections/360005486458/articles"; // 获取全部国家

function apiRecruitGetCountryList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Recruit/getCountryList"),
    data: data
  });
} // 获取全部职位


function apiRecruitGetJobList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Recruit/getJobList"),
    data: data
  });
} // 获取岗位


function apiRecruitGetIntroduceList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Recruit/getIntroduceList"),
    data: data
  });
} // 获取岗位详情


function apiRecruitGetIntroduceInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Recruit/getIntroduceInfo"),
    data: data
  });
} // 用户建议


function apiFormSubmitAdvice() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Channel/FormSubmit/advice"),
    data: data
  });
} // 用户建议


function apiBrokersProgram() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Channel/FormSubmit/brokersProgram"),
    data: data
  });
}