"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAlphabets = isAlphabets;
exports.isArray = isArray;
exports.isBlank = isBlank;
exports.isChina = isChina;
exports.isEmail = isEmail;
exports.isExternal = isExternal;
exports.isIP = isIP;
exports.isIdCard = isIdCard;
exports.isJson = isJson;
exports.isLatitude = isLatitude;
exports.isLongitude = isLongitude;
exports.isLowerCase = isLowerCase;
exports.isName = isName;
exports.isNum = isNum;
exports.isNumber = isNumber;
exports.isPassword = isPassword;
exports.isPhone = isPhone;
exports.isPort = isPort;
exports.isRTSP = isRTSP;
exports.isString = isString;
exports.isTel = isTel;
exports.isUpperCase = isUpperCase;
exports.isUrl = isUrl;

var _typeof2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/typeof.js"));

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.test.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.trim.js");

/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @description 校验密码是否小于6位
 * @param value
 * @returns {boolean}
 */


function isPassword(value) {
  return value.length >= 6;
}
/**
 * @description 判断是否为数字
 * @param value
 * @returns {boolean}
 */


function isNumber(value) {
  var reg = /^[0-9]*$/;
  return reg.test(value);
}
/**
 * @description 判断是否是名称
 * @param value
 * @returns {boolean}
 */


function isName(value) {
  var reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/;
  return reg.test(value);
}
/**
 * @description 判断是否为IP
 * @param ip
 * @returns {boolean}
 */


function isIP(ip) {
  var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  return reg.test(ip);
}
/**
 * @description 判断是否是传统网站
 * @param url
 * @returns {boolean}
 */


function isUrl(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
/**
 * @description 判断是否是小写字母
 * @param value
 * @returns {boolean}
 */


function isLowerCase(value) {
  var reg = /^[a-z]+$/;
  return reg.test(value);
}
/**
 * @description 判断是否是大写字母
 * @param value
 * @returns {boolean}
 */


function isUpperCase(value) {
  var reg = /^[A-Z]+$/;
  return reg.test(value);
}
/**
 * @description 判断是否是大写字母开头
 * @param value
 * @returns {boolean}
 */


function isAlphabets(value) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(value);
}
/**
 * @description 判断是否是字符串
 * @param value
 * @returns {boolean}
 */


function isString(value) {
  return typeof value === 'string' || value instanceof String;
}
/**
 * @description 判断是否是数组
 * @param arg
 */


function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
}
/**
 * @description 判断是否是端口号
 * @param value
 * @returns {boolean}
 */


function isPort(value) {
  var reg = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
  return reg.test(value);
}
/**
 * @description 判断是否是手机号
 * @param value
 * @returns {boolean}
 */


function isPhone(value) {
  var reg = /^1\d{10}$/;
  return reg.test(value);
}
/**
 * @description 判断是否是身份证号(第二代)
 * @param value
 * @returns {boolean}
 */


function isIdCard(value) {
  var reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  return reg.test(value);
}
/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */


function isEmail(value) {
  var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return reg.test(value);
}
/**
 * @description 判断是否中文
 * @param value
 * @returns {boolean}
 */


function isChina(value) {
  var reg = /^[\u4E00-\u9FA5]{2,4}$/;
  return reg.test(value);
}
/**
 * @description 判断是否为空
 * @param value
 * @returns {boolean}
 */


function isBlank(value) {
  return value == null || false || value === '' || value.trim() === '' || value.toLocaleLowerCase().trim() === 'null';
}
/**
 * @description 判断是否为固话
 * @param value
 * @returns {boolean}
 */


function isTel(value) {
  var reg = /^(400|800)([0-9\\-]{7,10})|(([0-9]{4}|[0-9]{3})([- ])?)?([0-9]{7,8})(([- 转])*([0-9]{1,4}))?$/;
  return reg.test(value);
}
/**
 * @description 判断是否为数字且最多两位小数
 * @param value
 * @returns {boolean}
 */


function isNum(value) {
  var reg = /^\d+(\.\d{1,2})?$/;
  return reg.test(value);
}
/**
 * @description 判断经度 -180.0～+180.0(整数部分为0～180，必须输入1到5位小数)
 * @param value
 * @returns {boolean}
 */


function isLongitude(value) {
  var reg = /^[-|+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d\.\d{1,5}|180\.0{1,5})$/;
  return reg.test(value);
}
/**
 * @description 判断纬度 -90.0～+90.0(整数部分为0～90，必须输入1到5位小数)
 * @param value
 * @returns {boolean}
 */


function isLatitude(value) {
  var reg = /^[-|+]?([0-8]?\d\.\d{1,5}|90\.0{1,5})$/;
  return reg.test(value);
}
/**
 * @description rtsp校验，只要有rtsp://
 * @param value
 * @returns {boolean}
 */


function isRTSP(value) {
  var reg = /^rtsp:\/\/([a-z]{0,10}:.{0,10}@)?(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  var reg1 = /^rtsp:\/\/([a-z]{0,10}:.{0,10}@)?(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]):[0-9]{1,5}/;
  var reg2 = /^rtsp:\/\/([a-z]{0,10}:.{0,10}@)?(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\//;
  return reg.test(value) || reg1.test(value) || reg2.test(value);
}
/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */


function isJson(value) {
  if (typeof value == 'string') {
    try {
      var obj = JSON.parse(value);
      return !!((0, _typeof2.default)(obj) == 'object' && obj);
    } catch (e) {
      return false;
    }
  }
}