"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var _i18n = require("@/assets/js/i18n");

//
//
//
var _default = {
  data: function data() {
    return {
      isHideLever: false
    };
  },
  created: function created() {
    if (document.domain.split(".").slice(-2).join(".") === "bitda.tw") {
      // if (
      //   document.domain
      //     .split(".")
      //     .slice(-2)
      //     .join(".") === "localhost"
      // ) {
      this.isHideLever = true;
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle
  }
};
exports.default = _default;