"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.systemCurrencyMaps = exports.rateTypeMaps = exports.quantTagMaps = exports.languageList = exports.financialStatusMapsTwo = exports.financialStatusMaps = exports.financialHoldStatusMaps = exports.closePositionTypeMaps = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/defineProperty.js"));

// .common-start {
//   color: #3a7dff !important;
//  }
//  .common-end {
//   color: #7f8490 !important;
//  }
//  .common-system {
//   color: #c61b3d !important;
//  }
//  // 标准绿色，在线
//  .common-green {
//    color: #03ad79 !important;
//  }
//  .common-loss {
//    color: #f04a5a !important;
//  }
//  .common-progress {
//    color: #f78700 !important;
//  }
var systemCurrencyMaps = {
  aed: "يوان",
  cny: "￥",
  cnye: "￥",
  hkd: "$",
  jpy: "￥",
  krw: "₩",
  usd: "$",
  vnd: "₫",
  thb: "฿",
  sgd: "S$",
  php: "₱",
  try: "₺",
  mmk: "k",
  brl: "R$"
};
exports.systemCurrencyMaps = systemCurrencyMaps;
var closePositionTypeMaps = {
  cls: {
    1: "common-start",
    2: "common-end",
    3: "common-system",
    4: "common-green",
    5: "common-loss",
    6: "common-progress"
  },
  label: {
    1: "未平仓",
    2: "用户平仓",
    3: "强制平仓",
    4: "止盈平仓",
    5: "止损平仓",
    6: "自动减仓"
  },
  options: [// { label: "DAY", value: "1" },
    // { label: "GTC", value: "2" },
    // { label: "IOC", value: "3" },
    // { label: "GTD", value: "4" },
  ]
};
exports.closePositionTypeMaps = closePositionTypeMaps;
var rateTypeMaps = {
  cls: {
    1: "common-start",
    2: "common-end"
  },
  label: {
    1: "固定收益",
    2: "浮动收益"
  }
};
exports.rateTypeMaps = rateTypeMaps;
var quantTagMaps = {
  cls: {
    1: "common-start",
    2: "common-end",
    3: "common-end"
  },
  label: {
    1: "谨慎型",
    2: "稳健型",
    3: "进取型"
  }
};
exports.quantTagMaps = quantTagMaps;
var financialStatusMaps = {
  label: {
    1: "募集预热中",
    2: "募集开始",
    3: "募集中",
    4: "募集结束",
    5: "计息中",
    6: "本息发放中",
    7: "本息已到账",
    8: "募集失败"
  }
};
exports.financialStatusMaps = financialStatusMaps;
var financialStatusMapsTwo = {
  label: {
    1: "募集预热中",
    2: "募集开始",
    3: "募集中",
    4: "募集结束",
    5: "交付中",
    6: "本息发放中",
    7: "交付完成",
    8: "募集失败"
  }
};
exports.financialStatusMapsTwo = financialStatusMapsTwo;
var financialHoldStatusMaps = {
  label: {
    1: "存入",
    2: "存入",
    3: "转出",
    4: "转出",
    5: "收益发放",
    6: "收益发放",
    7: "代币购买",
    8: "体验券存入",
    9: "体验券权益到期"
  }
};
exports.financialHoldStatusMaps = financialHoldStatusMaps;
var languageList = [(0, _defineProperty2.default)({
  id: "English",
  value: "en"
}, "id", "22"), {
  name: "日本語",
  value: "ja",
  id: "24"
}, {
  name: "한국인",
  value: "ko",
  id: "23"
}, {
  name: "Français",
  value: "fr",
  id: "26"
}, {
  name: "Español",
  value: "es",
  id: "27"
}, {
  name: "Pусский",
  value: "ru",
  id: "28"
}, {
  name: "Deutsch",
  value: "de",
  id: "29"
}, {
  name: "Português",
  value: "pt",
  id: "30"
}, {
  name: "हिंदी",
  value: "hi"
}, {
  name: "Tiếng Việt",
  value: "vi",
  id: "32"
}, {
  name: "Pilipino",
  value: "tl",
  id: "33"
}, {
  name: "ภาษาไทย",
  value: "th",
  id: "34"
}, {
  name: "Türk",
  value: "tr",
  id: "35"
}, {
  name: "Italiano",
  value: "it",
  id: "36"
}, {
  name: "bahasa Indonesia",
  value: "id"
}, {
  name: "عربي",
  value: "ar",
  id: "25"
}, {
  name: "中文简体",
  value: "zh_CN",
  id: "20"
}, {
  name: "中文繁體",
  value: "zh_HK",
  id: "21"
}];
exports.languageList = languageList;