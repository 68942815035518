"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SETTING_SV_ID = void 0;
exports.apiApplyKyc = apiApplyKyc;
exports.apiKycInfo = apiKycInfo;
exports.fileUpload = void 0;
exports.getRandNo = getRandNo;
exports.kycFileUploadVideo = exports.kycFileUpload = void 0;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var http = _interopRequireWildcard(require("@/assets/js/http"));

var _stationSetting = require("@/assets/js/stationSetting");

//静态表设置
var SETTING_SV_ID = {
  static_coin: 1,
  static_sms_country: 2,
  otc_static_coin: 3,
  payment: 4,
  static_item: 13,
  distribute_type: 16
}; //获取所有静态表
//type
//file

exports.SETTING_SV_ID = SETTING_SV_ID;

var fileUpload = function fileUpload(params) {
  return http.fileUpload("".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Third/pushPicture"), params);
};

exports.fileUpload = fileUpload;

var kycFileUpload = function kycFileUpload(params) {
  return http.fileUpload("".concat(_stationSetting.VUE_APP_APP, "/UCenter/Identity/pushIdPhoto"), params);
};

exports.kycFileUpload = kycFileUpload;

var kycFileUploadVideo = function kycFileUploadVideo(params) {
  return http.fileUpload("".concat(_stationSetting.VUE_APP_APP, "/UCenter/Identity/pushVideo"), params);
}; // export const kycFileUpload = (params) => {
//     return http.fileUpload(`${VUE_APP_APP}/UCenter/Identity/pushIdPhoto`, params);
//   };


exports.kycFileUploadVideo = kycFileUploadVideo;

function getRandNo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Identity/getIDRandNo"),
    data: data
  });
}

function apiApplyKyc() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Identity/applyKyc"),
    data: data
  });
}

function apiKycInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Identity/kycInfo"),
    data: data
  });
}