"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.sort.js");

var _i18n = require("@/assets/js/i18n");

var _bignumber = _interopRequireDefault(require("bignumber.js"));

var _stationSetting = require("@/assets/js/stationSetting");

//
//
//
// import { apiGetStaticTableInfo } from "@/model/settings";
// const { VUE_APP_ID } = process.env;
var _default = {
  data: function data() {
    return {
      projectName: "汇信",
      projectFullName: "汇信国际",
      projectEnglishName: "Topcredit",
      projectTitle: "全球领先的数字资产服务平台"
    };
  },
  created: function created() {
    if (_stationSetting.VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin" || _stationSetting.VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin") {
      this.projectEnglishName = "Bullxcow";
      this.projectFullName = "Bullxcow";
      this.projectTitle = "全球领先的数字资产服务平台";
    } else if (_stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectEnglishName = "B9";
      this.projectFullName = "币久";
      this.projectTitle = "全球领先的数字理财平台";
    } else if (_stationSetting.VUE_UNION_STATION == "EverTrust") {
      this.projectEnglishName = "EverTrust";
      this.projectFullName = "恒信";
      this.projectTitle = "全球领先的数字理财平台";
    } else if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.projectEnglishName = "MaskEX";
      this.projectFullName = "MaskEX";
      this.projectTitle = "全球领先的数字理财平台";
    } else if (_stationSetting.VUE_UNION_STATION == "100Ex") {
      this.projectEnglishName = "100Ex";
      this.projectFullName = "100Ex";
      this.projectTitle = "全球领先的数字理财平台";
    } else if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.projectEnglishName = "8V";
      this.projectFullName = "8V";
      this.projectTitle = "全球首家元宇宙生态数字资产交易所";
    } else if (_stationSetting.VUE_UNION_STATION == "UEEx") {
      this.projectEnglishName = "UEEx";
      this.projectFullName = "UEEx";
      this.projectTitle = "全球领先的数字理财平台";
    } else if (_stationSetting.VUE_UNION_STATION == "Ahex") {
      this.projectEnglishName = "AHEX";
      this.projectFullName = "AHEX";
      this.projectTitle = "全球领先的数字理财平台";
    } else if (_stationSetting.VUE_UNION_STATION == "5A") {
      this.projectEnglishName = "5A";
      this.projectFullName = "5A";
      this.projectTitle = "数字资产交易所";
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.projectEnglishName = "bitda";
      this.projectFullName = "币达";
      this.projectTitle = "加密货币交易所";
    } else if (_stationSetting.VUE_UNION_STATION == "S6") {
      this.projectEnglishName = "S6";
      this.projectFullName = "S6";
      this.projectTitle = "全球领先数字资产及衍生品交易平台";
    } else if (_stationSetting.VUE_UNION_STATION == "easy") {
      this.projectEnglishName = "ezex";
      this.projectFullName = "ezex";
      this.projectTitle = "全球领先数字资产及衍生品交易平台";
    } else if (_stationSetting.VUE_UNION_STATION == "spex") {
      this.projectEnglishName = "SPEX";
      this.projectFullName = "SPEX";
      this.projectTitle = "全球领先数字资产及衍生品交易平台";
    } else if (_stationSetting.VUE_UNION_STATION == "8coin") {
      this.projectEnglishName = "8coin";
      this.projectFullName = "8coin";
      this.projectTitle = "全球领先数字资产及衍生品交易平台";
    } else {
      this.projectEnglishName = "Topcredit";
      this.projectFullName = "汇信国际";
      this.projectTitle = "全球领先的数字资产服务平台";
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    // 向上取整
    ceilNumber: function ceilNumber(num, presicion) {
      if (!num) {
        num = 0;
      }

      var times = Math.pow(10, presicion);
      var nStr = new _bignumber.default(num); // 放大倍数

      var mulNum = nStr.times(times);
      mulNum = mulNum.toString();
      mulNum = parseFloat(mulNum);
      var ceilNum = Math.ceil(mulNum);
      var divNum = new _bignumber.default(ceilNum);
      divNum = divNum.div(times); // console.log(divNum);

      var rs = divNum.toFixed(presicion);
      return rs;
    },
    timestamp: function timestamp(created) {
      var formate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "yyyy-MM-dd HH:mm";
      var stamp = "".concat(created, "000");
      var date = new Date(Number(stamp));
      return window.DateFormat.format.date(date, formate);
    },
    // 千分符
    formatNumberRgx: function formatNumberRgx(num) {
      var parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    // 切换分页
    onCurrentPageChange: function onCurrentPageChange(val) {
      this.pageInfo.pageCurrent = val;
      this.initList();
    },
    // 分页数量
    onPagesizeChange: function onPagesizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initList();
    },
    // 是否展开全部
    onToggle: function onToggle() {
      var _this = this;

      if (this.expandList.length == this.list.length) {
        this.expandList = [];
      } else {
        this.list.forEach(function (item) {
          _this.expandList.push(item.user_id);
        });
      }
    },
    // 排序
    onSortChange: function onSortChange(val) {
      this.queryForm.sort = val.prop;

      if (val.order == "ascending") {
        this.queryForm.order = "asc";
      } else if (val.order == "descending") {
        this.queryForm.order = "desc";
      } else {
        this.queryForm.order = "";
      }
    },
    // 搜索框展示折叠
    onToogleAdvanced: function onToogleAdvanced() {
      var _this2 = this;

      this.advanced = !this.advanced; // if (this.advanced) {
      //   this.height = this.$baseTableHeight(343)
      // } else {
      //   this.height = this.$baseTableHeight(300)
      // }

      this.$nextTick(function () {
        _this2.handleHeight();
      });
    } // onSelectBlur(me, use, copy) {
    //   setTimeout(() => {
    //     me[use] = me[copy];
    //   }, 300);
    // },
    // onSelectFilter(val, me, use, copy, value, label) {
    //   me[use] = me[copy].filter((item) => {
    //     if (!val) {
    //       return true;
    //     }
    //     if (
    //       String(item[value]).indexOf(val) !== -1 ||
    //       String(item[label])
    //         .toUpperCase()
    //         .indexOf(val.toUpperCase()) !== -1
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // },

  }
};
exports.default = _default;